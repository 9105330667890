import classNames from "classnames";

const ExpandButton = ({ level, index, isOpen, handleCollapse }) => (
  <button className="btn btn-blank p-0 border-0 text-dark" onClick={() => handleCollapse(level, index)}>
    <i className={`bi ${isOpen ? "bi-dash-square-fill" : "bi-plus-square-fill"}`}></i>
  </button>
);

const AddMore = ({ level, index, handleAddMore, className, addChild }) => (
  <div className={classNames("add-more-wrap", className, addChild ? "add-more-wrap-child" : "")}>
    <button className="btn btn-blank p-0 border-0" onClick={() => handleAddMore(level, `${index}`)}>
      <i className="bi bi-plus-circle-fill text-success f-s-16" />
    </button>
  </div>
);

const DeleteButton = ({ level, index, handleDelete }) => (
  <button className="btn btn-blank p-0 border-0" onClick={() => handleDelete(level, index)}>
    <i className="bi bi-trash text-danger f-s-20" />
  </button>
);

export { ExpandButton, AddMore, DeleteButton };
