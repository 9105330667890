import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useTranslation } from "react-i18next";
import { getThrustCalculation } from "src/services/thrust-calculation";
import { PAGINATION_DEFAULT, PROCESS_STATUS } from "src/constant/common";
import { handleScrollToTop, mergeArrays } from "src/utils/common";
import { useQuery } from "src/Hooks/useReactQuery";
import TableCommon from "src/components/Common/Table";

const BulkThrustCalculation = ({ pageSize, toggle, setToggle, handleBulkData, rocketId, stage }) => {
  const { t } = useTranslation(["thrust-calculation", "common", "engine-component"]);

  const [pageCurrent, setPageCurrent] = useState(1);
  const [isLoadingSimulator, setIsLoadingSimulator] = useState(true);
  const [selectIds, setSelectIds] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);

  const { data: dataThrustCalculation, refetch } = useQuery(
    [`getBulkThrustCalculation`],
    () =>
      getThrustCalculation({
        page: pageCurrent,
        limit: pageSize || PAGINATION_DEFAULT.pageSize,
        status: PROCESS_STATUS.SUCCEEDED,
        exclude_rocket_model_id: rocketId,
        number_of_stage: stage,
      }),
    {
      onSettled: () => {
        setIsLoadingSimulator(false);
      },
      refetchInterval: false,
    }
  );

  const columnsCollapse = [
    {
      dataField: "name",
      text: t("list.table.name"),
      headerClass: "width-by-percent-13",
    },
    {
      dataField: "stage",
      text: t("list.table.stage"),
      headerClass: "width-by-percent-5",
    },
    {
      dataField: "mode",
      text: t("list.table.mode"),
      headerClass: "width-by-percent-13",
      formatter: (mode) => <span className="text-capitalize">{mode}</span>,
    },
    {
      dataField: "propellant",
      text: t("list.table.propellant"),
      headerClass: "width-by-percent-13",
      formatter: (_, row) => <>{row.propellant?.value ? `${row.propellant?.value} (${row.propellant_percentage?.value}%)` : "-"}</>,
    },
    {
      dataField: "",
      text: t("list.table.total_isp"),
      headerClass: "width-by-percent-11",
      formatter: (_, row) => <>{row?.total_isp?.value ?? "-"}</>,
    },
    {
      dataField: "",
      text: t("list.table.nozzle_area"),
      headerClass: "width-by-px-200",
      formatter: (_, row) => <>{row?.nozzle_area?.value ?? "-"}</>,
    },
    {
      dataField: "",
      text: t("list.table.burning_time"),
      headerClass: "width-by-percent-11",
      formatter: (_, row) => <>{row?.burning_time?.value ?? "-"}</>,
    },
    {
      dataField: "engine_component",
      text: t("list.table.engine_component"),
      headerClass: "width-by-px-250",
      formatter: (_, row) => (
        <>
          {row.engine_components?.map((component, index) => (
            <div key={index}>
              <p className="mb-0">{component.component.name}</p>
            </div>
          ))}
        </>
      ),
    },
    {
      dataField: "number_of_units",
      text: t("list.table.number_of_units"),
      headerClass: "",
      formatter: (_, row) => (
        <>
          {row.engine_components?.map((component, index) => (
            <span key={index}>
              {component.number_of_unit}
              <br />
            </span>
          ))}
        </>
      ),
    },
  ];

  const columns = [
    {
      dataField: "",
      text: "",
      headerClass: "width-by-percent-5",
      formatter: (_, row) => (
        <input
          className="form-check-input"
          type="checkbox"
          checked={!!selectIds?.find((i) => i === row?.id)}
          name={`checkbox_${row?.id}`}
          onChange={(e) => {
            if (e.target.checked) {
              setSelectIds([...selectIds, row.id]);
              return;
            }
            const newList = selectIds?.filter((i) => i !== row?.id);
            setSelectIds(newList);
          }}
        />
      ),
    },
    {
      dataField: "id",
      text: t("list.table.id"),
      headerClass: "width-by-percent-7",
    },
    {
      dataField: "name",
      text: t("list.table.name"),
      headerClass: "width-by-percent-30",
    },
    {
      dataField: "data",
      text: t("list.table.data"),
      headerClass: "width-by-percent-39",
      formatter: (_, __, { isCollapse, toggleCollapse }) => (
        <Button outline size="sm" className="px-1 btn-collapse height-by-px-36" onClick={() => toggleCollapse()}>
          <i className={`bi bi-chevron-${isCollapse ? "down" : "up"} f-s-14 fw-bold text-dark`} />
        </Button>
      ),
      collapseRow: (data) => (
        <div>
          <TableCommon
            columns={columnsCollapse}
            data={mergeArrays(data?.input_data?.modes, data?.output_data?.data ?? data?.output_data) || []}
            tableClassName="table-collapse mb-0"
            customClassName="overflow-auto simulation-table table-detail"
            classTableHead="table-secondary table-header-nowrap"
            hasPagination={false}
          />
        </div>
      ),
    },
    {
      dataField: "",
      text: "",
      headerClass: "width-by-percent-3",
    },
    {
      dataField: "",
      text: "",
      headerClass: "width-by-percent-3",
    },
  ];

  useEffect(() => {
    setIsLoadingSimulator(true);
    refetch();
  }, [pageCurrent]);

  const onChangePage = async (value) => {
    setPageCurrent(value);
    handleScrollToTop("card");
    return;
  };

  const handleSubmitBulk = (e) => {
    e.preventDefault();
    setIsSubmit(true);
    handleBulkData({ thrust_calculation_ids: selectIds }, () => {
      setIsSubmit(false);
    });
    return;
  };

  return (
    <Modal
      centered
      isOpen={toggle}
      toggle={() => setToggle(!toggle)}
      size="xl"
      backdrop="static"
      contentClassName="max-vh-85 scroll-y"
      style={{ maxWidth: "1600px", width: "80%" }}
    >
      <ModalHeader toggle={() => setToggle(false)}>{t("new.title")}</ModalHeader>
      <ModalBody className="p-b-0 scroll-y">
        <Row>
          <Col md={12}>
            <Card>
              <CardBody className="bg-white p-1" id="card">
                <TableCommon
                  loading={isLoadingSimulator}
                  columns={columns}
                  data={dataThrustCalculation?.items || []}
                  onChangePage={onChangePage}
                  tableFixed
                  tableClassName="striped table-collapse"
                  customClassName="overflow-auto"
                  pageCurrent={pageCurrent - 1}
                  pageSize={pageSize}
                  pageCount={dataThrustCalculation?.pagination?.total_page}
                  totalItem={dataThrustCalculation?.pagination?.total}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter className="justify-content-center p-t-10 p-b-20">
        <button type="button" className="btn btn-sm btn-secondary m-x-10 width-by-px-120" onClick={() => setToggle(false)}>
          {t("new.cancel")}
        </button>
        <button
          type="button"
          className="btn btn-sm btn-primary m-x-10 width-by-px-120"
          onClick={handleSubmitBulk}
          disabled={isSubmit || !!!(selectIds?.length > 0)}
        >
          {t("new.select")}
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default BulkThrustCalculation;
