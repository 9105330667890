import React, { useState, useEffect } from "react";
import { 
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner 
} from "reactstrap";
import { useTranslation } from "react-i18next";
import FormInput from "../Form/FormInput";
import FormSelect from "../Form/FormSelect";
import { useForm } from "react-hook-form";
import {
  getTestAnalysisProcess,
  createTestAnalysis,
} from "src/services/test-analysis";
import { useMutation } from "src/Hooks/useReactQuery";
import { transformProcessDataToOptions, getConfigFromId } from "src/utils/common";
import { useSetRecoilState } from 'recoil';
import { alertState } from 'src/recoil/atoms/global';
import { PATTERN_NUMBER } from 'src/constant/common';

const FORM_NAME = {
  NAME: "name",
  PROCESS_ID: "process_id",
  representative_surface_areas: "representative_surface_areas",
  representative_length: "representative_length",
  mach_classification_unit: "mach_classification_unit",
};

const ModelAddTestAnalysis = ({onSuccess}) => {
  const { t } = useTranslation("test-analysis");
  const [toggle, setToggle] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [config, setConfig] = useState([]);
  const showAlert = useSetRecoilState(alertState);

  const { data: options, mutate: getOptionsMutate } = useMutation({
    mutationFn: () => getTestAnalysisProcess(),
    onSettled: () => setIsLoading(false),
  });

  const createTestAnalysisMutate = useMutation({
    mutationFn: ({ data }) => createTestAnalysis({ data }),
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
    // setValue,
    watch,
    // setError,
    // clearErrors,
  } = useForm({});

  const processId = watch(FORM_NAME.PROCESS_ID);

  const handleSubmitForm = (data) => {
    const inputData = {
      name: data.name.trim(),
      process_id: data.process_id,
      config: {}
    };

    for (const item of config) {
      inputData.config[item.name] = (item.type === 'number') ? parseFloat(data[item.name]) : data[item.name];
    }

    setIsSubmit(true);
    createTestAnalysisMutate.mutate(
      { data: inputData },
      {
        onSuccess: () => {
          setToggle(false);
          onSuccess();
          showAlert({
            status: true,   
            message: t('new.create_success'), 
            type: 'success',
          });
        },
        onSettled: () => {
          setIsSubmit(false);
        },
      }
    );    
  };

  useEffect(() => {
    if (processId) {
      setConfig(getConfigFromId(processId, options, 'test_analysis'));
    }
  }, [processId]);

  useEffect(() => {
    getOptionsMutate();
  }, []);
  
  return (
    <>
      <button
        onClick={() => {
          reset();
          setConfig([]);
          setToggle(true);
        }}
        className="btn btn-primary text-capitalize"
      >
        {t("list.redirect_new")}
      </button>
      <Modal centered isOpen={toggle} toggle={() => setToggle(false)} size="lg" backdrop="static">
        <ModalHeader>{t("new.title")}</ModalHeader>
        <ModalBody className="p-b-0">
          <Form className="p-x-15">
            <div className="row">
              <div className="col-md-12">
                <FormInput
                  register={register}
                  label={t("new.name")}
                  name={FORM_NAME.NAME}
                  placeholder={t("new.name_placeholder")}
                  errors={errors?.[FORM_NAME.NAME]?.message}
                  requiredMessage={t("new.name_required")}
                  required
                  maxLength={255}
                  maxLengthMessage={t("new.name_maxlength")}
                />
                {isLoading ? (
                  <Spinner size="sm" />
                ) : (
                  <FormSelect
                    label={t("new.process")}
                    name={FORM_NAME.PROCESS_ID}
                    placeholder={t("new.process_placeholder")}
                    control={control}
                    errors={errors?.[FORM_NAME.PROCESS_ID]?.message}
                    required
                    options={transformProcessDataToOptions(options || [])}
                    requiredMessage={t("new.process_required")}
                  />
                )}

                {config.map((item, index) => {
                  if (FORM_NAME?.[item.name]) {
                    return (
                      <FormInput
                        key={index}
                        register={register}
                        type={item.type}
                        pattern={item.type === 'number' ? {
                          value: PATTERN_NUMBER,
                          message: t(`new.${item.name}_validate_${item.type}`),
                        } : null}
                        label={t(`new.${item.name}`)}
                        name={FORM_NAME[item.name]}
                        placeholder={t(`new.${item.name}_placeholder`)}
                        errors={errors?.[FORM_NAME[item.name]]?.message}
                        requiredMessage={t(`new.${item.name}_required`)}
                        min={item.type === 'number' ? '0' : null}
                        minMessage={t('new.min_number_value')}
                        required
                      />
                    );
                  }
                  return '';
                })}
              </div>
            </div>
          </Form>
        </ModalBody>
        <ModalFooter className="justify-content-center p-t-10 p-b-20">
          <button
            className="btn btn-sm btn-secondary m-x-10 width-by-px-120"
            onClick={() => {
              setToggle(false);
              document.body.click();
            }}
          >
            {t("new.cancel")}
          </button>
          <button
            className="btn btn-sm btn-primary m-x-10 width-by-px-120"
            disabled={isSubmit}
            onClick={handleSubmit((data) => handleSubmitForm(data))}
          >
            {t("new.register")}
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ModelAddTestAnalysis;
