import React, { useState } from "react";
import { Form, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import FormInput from "src/components/Common/Form/FormInput";
import FormSelect from "src/components/Common/Form/FormSelect";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "src/Hooks/useReactQuery";
import { getEngineComponent } from "src/services/engine-component";
import { STAGE_MODES_OPTIONS, STAGE_OPTIONS } from "src/constant/common";
import Stage from "./Stages";

export const FORM_NAME = {
  NAME: "name",
  NUMBER_OF_STATE: "number_of_stage",
  STAGE: "stage",
  MODES: "modes",
  MODE: "mode",
  TOTAL_PROPELLANT_MASS: "total_propellant_mass",
  PROPELLANT_PERCENTAGE: "propellant_percentage",
  ENGINE_COMPONENT: "engine_components",
  COMPONENT_ID: "component_id",
  NUMBER_OF_UNITS: "number_of_unit",
  UNIT: "unit",
  NAME_MODE: "name",
};

export const COMPONENT_MODE = { [FORM_NAME.COMPONENT_ID]: "", [FORM_NAME.NUMBER_OF_UNITS]: "" };

export const DEFAULT_MODE = {
  [FORM_NAME.NAME_MODE]: "",
  [FORM_NAME.PROPELLANT_PERCENTAGE]: "",
  [FORM_NAME.MODE]: STAGE_MODES_OPTIONS[0].value,
  [FORM_NAME.ENGINE_COMPONENT]: [COMPONENT_MODE],
};

export const DEFAULT_STAGE = {
  [FORM_NAME.TOTAL_PROPELLANT_MASS]: "",
  [FORM_NAME.UNIT]: "kg",
  [FORM_NAME.MODES]: [DEFAULT_MODE],
};

const ModalThrustCalculation = ({ onSubmit, toggle, setToggle, defaultValues, stage, rocketId }) => {
  const { t } = useTranslation(["thrust-calculation"]);
  const [isSubmit, setIsSubmit] = useState(false);
  const [options, setOptions] = useState([]);
  const [currentStage, setCurrentStage] = useState(defaultValues?.number_of_stage ?? stage ?? 2);

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValues
      ? { ...defaultValues }
      : {
          [FORM_NAME.NAME]: "",
          [FORM_NAME.NUMBER_OF_STATE]: stage || 2,
          [FORM_NAME.STAGE]: Array.from({ length: stage ?? 2 }, () => DEFAULT_STAGE),
        },
  });

  const numberOfStage = watch(FORM_NAME.NUMBER_OF_STATE);

  const {
    fields: stages,
    insert,
    remove,
  } = useFieldArray({
    control,
    name: FORM_NAME.STAGE,
  });

  useQuery(
    ["getEngineComponent"],
    () =>
      getEngineComponent({
        page: 1,
        limit: 100,
      }),
    {
      onSuccess: (data) => setOptions(data?.items?.map((i) => ({ label: i.name, value: i.id }))),
    }
  );

  const handleSubmitForm = (data) => {
    setIsSubmit(true);
    onSubmit(data, () => setIsSubmit(false));
  };

  const handleChangeStage = (value) => {
    const newValue = parseInt(value, 10);
    const currentStageValue = parseInt(currentStage, 10);

    if (newValue === currentStageValue) return;

    const count = Math.abs(newValue - currentStageValue);

    if (newValue > currentStageValue) {
      for (let i = 0; i < count; i++) {
        insert(0, DEFAULT_STAGE);
      }
    } else {
      for (let i = 0; i < count; i++) {
        remove(0);
      }
    }

    return setCurrentStage(value);
  };

  return (
    <Modal
      centered
      isOpen={toggle}
      toggle={() => setToggle(!toggle)}
      size="xl"
      backdrop="static"
      contentClassName="height-by-px-670 scroll-hidden"
      modalClassName="scroll-hidden"
      style={{ maxWidth: "1300px", width: "85%" }}
    >
      <ModalHeader toggle={() => setToggle(false)}>{t("new.title")}</ModalHeader>
      <ModalBody className="p-y-0 scroll-y">
        <Form className="p-x-15">
          <div className="row">
            <div className="col-4">
              <FormInput
                register={register}
                label={t("new.name")}
                name={FORM_NAME.NAME}
                placeholder={t("new.name_placeholder")}
                errors={errors?.[FORM_NAME.NAME]?.message}
                requiredMessage={t("new.name_required")}
                maxLength={255}
                maxLengthMessage={t("new.name_maxlength")}
                required
                classInput="min-height-by-px-38"
                classGroup="mb-0"
              />
            </div>
            <div className="col-7">
              <div className="row">
                <div className="col-6">
                  <FormSelect
                    label={t("new.stage")}
                    name={FORM_NAME.NUMBER_OF_STATE}
                    placeholder={t("new.stage_placeholder")}
                    control={control}
                    errors={errors?.[FORM_NAME.NUMBER_OF_STATE]?.message}
                    options={STAGE_OPTIONS}
                    required
                    onChangeExtra={({ value }) => handleChangeStage(value)}
                    indexMenu={1051}
                    classGroup="mb-0"
                    disabled={rocketId}
                  />
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12 border-bottom"></div>
            </div>

            <Stage options={options} stages={stages} errors={errors} register={register} control={control} numberOfStage={numberOfStage} />
          </div>
        </Form>
      </ModalBody>
      <ModalFooter className="justify-content-center p-t-10 p-b-20">
        <button type="button" className="btn btn-sm btn-secondary m-x-10 width-by-px-120" onClick={() => setToggle(false)}>
          {t("new.cancel")}
        </button>
        <button
          type="button"
          className="btn btn-sm btn-primary m-x-10 width-by-px-120"
          disabled={isSubmit}
          onClick={handleSubmit(handleSubmitForm)}
        >
          {t("new.register")}
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalThrustCalculation;
