import React from "react";
import { useNavigate } from "react-router-dom";

import { Container, Row, Col } from "reactstrap";

import imgNotFound from "../../assets/images/not-found.png";
import APP_ROUTES from "src/routes/routes";
import { ACCESS_TOKEN, removeLocalStorage } from "src/utils/storage";
import { useSetRecoilState } from "recoil";
import { authState } from "src/recoil/atoms/auth";

const AccessDenied = () => {
  const setAuth = useSetRecoilState(authState);
  const navigate = useNavigate();

  const handleToLogin = () => {
    removeLocalStorage(ACCESS_TOKEN);
    setAuth({});
    setTimeout(() => {
      navigate(APP_ROUTES.LOGIN);
    }, 0);
  };

  return (
    <React.Fragment>
      <div className="account-pages my-5">
        <Container>
          <Row className="justify-content-center">
            <Col xl={4} lg={5}>
              <div className="text-center">
                <div>
                  <img src={imgNotFound} alt="" className="img-fluid" />
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col className="text-center">
              <h3 className="mt-3">Access Denied.</h3>
              <p className="text-muted">
                This action cannot be completed as the email account used for
                login is unauthorized.
                <br />
                Please use a valid email account to continue.
              </p>

              <span
                onClick={handleToLogin}
                className="btn btn-lg btn-primary mt-4"
              >
                Take Me back to Login
              </span>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AccessDenied;
