import React from "react";
import { useTranslation } from "react-i18next";
import { useFieldArray } from "react-hook-form";
import { DEFAULT_MODE, FORM_NAME } from ".";
import FormInput from "src/components/Common/Form/FormInput";
import FormSelect from "src/components/Common/Form/FormSelect";
import { PATTERN_NEGATIVE_NUMBER, STAGE_MODES_OPTIONS } from "src/constant/common";
import Engine from "./Engine";

const Mode = ({ control, options, register, stageIndex, errors }) => {
  const { t } = useTranslation(["thrust-calculation"]);
  const { fields, append, remove } = useFieldArray({
    control,
    name: `${FORM_NAME.STAGE}[${stageIndex}].${FORM_NAME.MODES}`,
  });

  return (
    <>
      {fields?.map((mode, modeIndex) => (
        <div className="row position-relative" key={mode.id}>
          <div className="col-4">
            <FormInput
              register={register}
              label={t("new.name")}
              name={`${FORM_NAME.STAGE}[${stageIndex}].${FORM_NAME.MODES}.[${modeIndex}].${FORM_NAME.NAME_MODE}`}
              errors={errors?.[FORM_NAME.STAGE]?.[stageIndex]?.[FORM_NAME.MODES]?.[modeIndex]?.[FORM_NAME.NAME_MODE]?.message}
              placeholder={t("new.name_mode_placeholder")}
              maxLength={255}
              maxLengthMessage={t("new.name_maxlength")}
              required
              requiredMessage={t("new.name_required")}
              classInput="min-height-by-px-38"
            />
            <FormInput
              register={register}
              label={t("new.propelant")}
              name={`${FORM_NAME.STAGE}[${stageIndex}].${FORM_NAME.MODES}.[${modeIndex}].${FORM_NAME.PROPELLANT_PERCENTAGE}`}
              placeholder={t("new.propelant_placeholder")}
              errors={errors?.[FORM_NAME.STAGE]?.[stageIndex]?.[FORM_NAME.MODES]?.[modeIndex]?.[FORM_NAME.PROPELLANT_PERCENTAGE]?.message}
              required
              requiredMessage={t("new.propelant_required")}
              range={{ from: 1, to: 100 }}
              rangeMessage={t("new.range", { from: 1, to: 100 })}
              classInput="min-height-by-px-38"
              pattern={{
                value: PATTERN_NEGATIVE_NUMBER,
                message: t(`new.field_number_validate`, { field: t("new.propelant_not_unit") }),
              }}
            />
            <FormSelect
              label={t("new.mode")}
              name={`${FORM_NAME.STAGE}[${stageIndex}].${FORM_NAME.MODES}.[${modeIndex}].${FORM_NAME.MODE}`}
              errors={errors?.[FORM_NAME.STAGE]?.[stageIndex]?.[FORM_NAME.MODES]?.[modeIndex]?.[FORM_NAME.MODE]?.message}
              placeholder={t("new.mode_placeholder")}
              control={control}
              options={STAGE_MODES_OPTIONS}
              required
              indexMenu={1051}
            />
          </div>
          <div className="col-7">
            <Engine control={control} register={register} options={options} stageIndex={stageIndex} modeIndex={modeIndex} errors={errors} />
          </div>

          {fields?.length > 1 && (
            <span className="position-absolute r-30 position-top-center w-auto" onClick={() => remove(modeIndex)} role="button">
              <i className="bi bi-trash3" style={{ fontSize: "24px", color: "red" }}></i>
            </span>
          )}
        </div>
      ))}
      <div className="">
        <div className="col-12">
          <button type="button" className="btn btn-secondary" style={{ marginBottom: "20px" }} onClick={() => append(DEFAULT_MODE)}>
            {t("new.add_mode")}
          </button>
        </div>
      </div>
    </>
  );
};
export default Mode;
