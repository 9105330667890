import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Chart, registerables } from "chart.js";
import { Line } from "react-chartjs-2";
import annotationPlugin from "chartjs-plugin-annotation";
import { getClusterCenters, roundToTwoDecimals } from "src/utils/common";
import { CHART_CONTAINER_WIDTH, CHART_LIMIT_TICKS } from "src/constant/common";

// Register ChartJS and annotationPlugin components
Chart.register(...registerables, annotationPlugin);

const MassChart = ({ data = [], labels = [] }) => {
  const { t } = useTranslation(["rocket-model", "common", "test-analysis"]);
  const chartContainerRef = useRef(null);
  const [maxTicksLimit, setMaxTicksLimit] = useState(10);

  const uniqueLabels = labels.map((label, index) => ({ x: label, y: data[index] }));

  const stepData = {
    labels: Array.from(new Set(labels)),
    datasets: [
      {
        label: ` ${t("detail.mass_distribution")}`,
        data: uniqueLabels,
        fill: false,
        borderColor: "magenta",
        borderWidth: 2,
        stepped: "before",
      },
    ],
  };

  const options = {
    scales: {
      x: {
        title: {
          display: true,
          text: t("detail.l_axis"),
        },
        position: "bottom",
        type: "linear",
        afterBuildTicks: (scale) => {
          const xValues = scale.chart.data.datasets[0].data.map((d) => d.x).sort((a, b) => a - b);
          const max = Math.max(...xValues);
          const list = getClusterCenters(
            xValues.map((value) => ({ value })),
            max / 10
          );
          scale.ticks = list;
          scale.min = 0;
          scale.max = max;
        },
        ticks: {
          callback: (value) => roundToTwoDecimals(value),
          maxTicksLimit: maxTicksLimit,
        },
      },
      y: {
        title: {
          display: true,
          text: t("detail.mass_distribution"),
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const dataset = context.dataset;
            const value = context.raw !== undefined ? context.raw : context.parsed.y;
            return dataset.label + ": " + value?.y?.toString();
          },
          title: (context) => roundToTwoDecimals(context?.[0]?.label),
        },
      },
    },
    maintainAspectRatio: false,
  };

  // Handle resizing for maxTicksLimit
  useEffect(() => {
    const getMaxTicksForContainer = (containerWidth) => {
      if (containerWidth < CHART_CONTAINER_WIDTH.SM) {
        return CHART_LIMIT_TICKS.SMALL; // Small container
      } else if (containerWidth < CHART_CONTAINER_WIDTH.MD) {
        return CHART_LIMIT_TICKS.MEDIUM; // Medium container
      } else {
        return CHART_LIMIT_TICKS.BIG; // Large container
      }
    };

    const handleResize = () => {
      if (chartContainerRef.current) {
        const containerWidth = chartContainerRef.current.clientWidth;
        setMaxTicksLimit(getMaxTicksForContainer(containerWidth));
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial call to set the ticks limit based on initial container size

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="w-100 h-100 d-flex justify-content-center align-items-center" ref={chartContainerRef}>
      <Line data={stepData} options={options} />
    </div>
  );
};

export default MassChart;
