import { HISTORIES_ACTION_NAME, PROCESS_STATUS, SORT_METHODS } from "src/constant/common";

function humanFileSize(bytes, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = si ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"] : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return bytes.toFixed(dp) + " " + units[u];
}

const transformDataVersionsToOptions = (array) => {
  const result = [];

  array.map((item) =>
    result.push({
      label: item.name,
      value: item.name,
      execute_files: transformDataExecutionFileToOptions(item.execute_files),
    })
  );

  return result;
};

const transformDataExecutionFileToOptions = (array) => {
  const result = [];

  array.map((item) => result.push({ label: item.name, value: item.name }));

  return result;
};

const getFileName = (path) => {
  if (!path) return "";
  const regex = /\/?([^/]+\.json|[^/]+\.csv)(?:\?|$)/;
  const match = path.match(regex);
  const fileName = match ? match[1] : "";
  return fileName;
};
const getFileType = (path) => {
  if (!path) return "";
  return path.split(".").pop();
};

const uniqArray = (arr) => {
  const seen = new Set();
  const uniqueByDataField = arr.filter((item) => {
    if (!seen.has(item.dataField)) {
      seen.add(item.dataField);
      return true;
    }
    return false;
  });
  return uniqueByDataField;
};

const handleScrollTo = (top, left, id) => {
  setTimeout(() => {
    let leftPosition = left;
    if (id) {
      const element = document.getElementById(id);
      leftPosition = parseInt(element?.getBoundingClientRect().top - left);
    }
    window.scrollTo(top, leftPosition - 85);
  }, 500);
};

const handleScrollToTop = (elementId = null, behavior = "instant") => {
  const element = document.getElementById(elementId);
  if (element) {
    element.scrollIntoView({ behavior, block: "start" });
  } else {
    window.scrollTo({ top: 0, behavior });
  }
};

const sortData = (data, key, methods = SORT_METHODS.ASC) => {
  return data.sort(function (a, b) {
    let firstChar = a;
    let secondChar = b;
    if (methods === SORT_METHODS.DESC) {
      firstChar = b;
      secondChar = a;
    }
    return firstChar[key].localeCompare(secondChar[key]);
  });
};

const uuid = () => {
  const timestamp = Date.now();
  const randomSegment = Math.floor(Math.random() * 10000);
  const uniqueId = timestamp * 10000 + randomSegment;
  return uniqueId;
};

const extractIDsFromString = (str) => {
  if (!str) return ["", ""];
  const regex = /([^/]+)\/([^/]+)\.json/;
  const match = str.match(regex);
  if (match) {
    return [match[1], match[2]];
  } else {
    return ["", ""];
  }
};

const transformProcessDataToOptions = (array) => {
  const result = [];

  array.map((item) =>
    result.push({
      label: item.name,
      value: item.id,
    })
  );

  return result;
};

const getConfigFromId = (id, array, key) => {
  const result = array.filter((item) => item.id === id);
  if (!result.length || !Object.keys(result[0]?.config).length) return {};
  const config = result[0].config?.[key] || {};

  return Object.keys(config)
    .sort()
    .reverse()
    .map((key) => {
      return { name: key, type: config[key] };
    });
};

const getConfigFileName = (config) => {
  const outputPath = [];
  if (config?.no_wind_path) {
    outputPath.push(getFileName(config.no_wind_path));
  }
  if (config?.blow_path) {
    outputPath.push(getFileName(config.blow_path));
  }
  return outputPath.join(", ");
};

const transformThrustCalculation = (data) => {
  return {
    name: data.name,
    number_of_stage: parseInt(data.number_of_stage, 10),
    input_data: {
      number_of_stage: parseInt(data.number_of_stage, 10),
      stages: data.stage.map((s, index) => ({
        stage: data.stage?.length - index,
        total_propellant_mass: {
          value: parseFloat(s.total_propellant_mass, 10),
          unit: s.unit,
        },
      })),
      modes: [].concat(
        ...data.stage.map((s, index) =>
          s.modes.map((m) => ({
            stage: data.stage?.length - index,
            name: m.name,
            engine_components: m.engine_components.map((c) => ({
              component_id: c.component_id,
              number_of_unit: parseInt(c.number_of_unit, 10),
            })),
            propellant: {
              value: parseFloat(s.total_propellant_mass, 10) * (parseFloat(m.propellant_percentage, 10) / 100),
              unit: s.unit,
            },
            propellant_percentage: {
              value: parseFloat(m.propellant_percentage, 10),
              unit: "%",
            },
            mode: m.mode,
          }))
        )
      ),
    },
  };
};

const reverseTransformThrustCalculation = (data) => {
  // Helper function to find modes for a particular stage
  function getModesForStage(stageNumber, modes) {
    return modes
      .filter((mode) => mode.stage === stageNumber)
      .map((mode) => ({
        name: mode.name,
        propellant_percentage: mode.propellant_percentage.value.toString(),
        mode: mode.mode,
        engine_components: mode.engine_components.map((comp) => ({
          component_id: comp.component_id,
          number_of_unit: comp.number_of_unit.toString(),
        })),
      }));
  }

  // Helper function to calculate total propellant mass based on modes and to get unit
  function calculateTotalPropellantMassAndUnit(stageNumber, modes) {
    let totalMass = 0;
    let unit = "";
    modes
      .filter((mode) => mode.stage === stageNumber)
      .forEach((mode) => {
        totalMass += mode.propellant.value;
        unit = mode.propellant.unit; // Assuming all propellant units are the same within a stage
      });
    return { totalMass, unit };
  }

  return {
    name: `Copy of ${data.name}`,
    number_of_stage: data.number_of_stage,
    stage: data.input_data.stages.map((s) => {
      const { totalMass, unit } = calculateTotalPropellantMassAndUnit(s.stage, data.input_data.modes);
      return {
        total_propellant_mass: s?.total_propellant_mass?.value || totalMass.toString(),
        unit: s?.total_propellant_mass?.unit || unit,
        modes: getModesForStage(s.stage, data.input_data.modes),
      };
    }),
  };
};

const roundToTwoDecimals = (inputNum, force) => {
  let num = Number(inputNum);
  if (isNaN(num) || (Number.isInteger(num) && !force)) return inputNum;
  return num.toFixed(2);
};

const mergeArrays = (array1, array2) => {
  if (!array2 || !Array.isArray(array2)) return array1;
  return array1.map((item, index) => {
    return { ...item, ...array2[index] };
  });
};

const mergeUniqueObjects = (arr1, arr2) => {
  const mergedArray = [...arr1, ...arr2];
  const seenItems = new Set();

  const serialize = (obj) => JSON.stringify(obj);
  const uniqueItems = mergedArray.reduce((uniqueList, item) => {
    const itemString = serialize(item);

    if (!seenItems.has(itemString)) {
      uniqueList.push(item);
      seenItems.add(itemString);
    }

    return uniqueList;
  }, []);

  return uniqueItems;
};

const getChartData = (mainList, listOfPoints = []) => {
  if (!Array.isArray(mainList)) return { data: [], labels: [] };

  const labels = [];
  const data = [];
  const pointRadius = [];

  let combinedList = [...mainList];

  if (listOfPoints?.length > 0) {
    const uniqueList = mergeUniqueObjects(mainList, listOfPoints);
    const formattedPoints = uniqueList.map((point) => {
      if (listOfPoints?.find((l) => l[0] === point[0] && l[1] === point?.[1])) {
        return { ...point, isPoint: true };
      }

      return point;
    });

    combinedList = [...formattedPoints];
  }

  combinedList
    .filter((item) => typeof item[0] === "number")
    .sort((a, b) => a[0] - b[0])
    .forEach((item) => {
      labels.push(item[0]);
      data.push(item[1]);
      pointRadius.push(item.isPoint ? 3 : 0);
    });

  const uniqueListPoints = Array.from(new Set(listOfPoints.map((point) => point[0])));

  return {
    labels,
    data,
    pointRadius,
    listPoint: uniqueListPoints,
  };
};

const uniqueArray = (array, key) => {
  const uniqueArray = [];
  const ids = new Set();

  array.forEach((item) => {
    if (!ids.has(item[key])) {
      ids.add(item[key]);
      uniqueArray.push(item);
    }
  });

  return uniqueArray;
};

const isCSVContent = (content) => {
  if (typeof content !== "string") {
    return false;
  }
  const csvPattern = /^,?(?:[^,\n]+,)*[^,\n]+(?:\n|$)/;
  return csvPattern.test(content);
};

const MAPPING_CONTENT_CHANGE_TO_TEXT = (t, _, id, new_value) => ({
  [HISTORIES_ACTION_NAME.UPDATE_ROCKET_MODEL]: () => t("logs.model"),
  [HISTORIES_ACTION_NAME.UPDATE_ENGINE_COMPONENT]: () => t("logs.engined"),
  [HISTORIES_ACTION_NAME.UPDATE_COMPONENT_DETAIL]: () => t("logs.components"),
  [HISTORIES_ACTION_NAME.ADD_NEW_CALCULATION]: () => {
    return t("logs.add_new_calculation", {
      unit: Array.isArray(new_value) ? (new_value?.length > 1 ? "s" : "") : "",
      ids: Array.isArray(new_value) ? new_value?.map((i) => i?.id)?.join(", ") : "",
    });
  },
  [HISTORIES_ACTION_NAME.RUN_SIMULATION]: () => {
    if (new_value?.simulator_status === PROCESS_STATUS.SUCCEEDED) {
      return t("logs.run_simulation_success", { status: PROCESS_STATUS.SUCCEEDED });
    }

    return t("logs.run_simulation_fail", { status: PROCESS_STATUS.FAILED });
  },
  [HISTORIES_ACTION_NAME.CALCULATE_THRUST]: () => {
    if (new_value?.status === PROCESS_STATUS.SUCCEEDED) {
      return t("logs.thrust_success", { status: PROCESS_STATUS.SUCCEEDED, id });
    }

    return t("logs.thrust_fail", { status: PROCESS_STATUS.FAILED, id });
  },
  [HISTORIES_ACTION_NAME.SAVE_UNPROCESSED_COMPONENT_DETAILS]: () => t("logs.save_unprocessed_component_details"),
});

const sumArrayWithKey = (data, key) => {
  if (data?.length === 0) return;

  return data.reduce((accumulator, currentValue) => {
    if (!currentValue[key]) return accumulator;

    return preciseAdd(accumulator, parseFloat(currentValue[key]));
  }, 0);
};

const mergeChildren = (arr) => {
  let result = [];

  const flatten = (array) => {
    for (let obj of array) {
      if (obj.children) {
        result = result.concat(obj.children);
        flatten(obj.children); // Recursively flatten the children
      }
    }
  };

  flatten(arr?.children);
  return result;
};

const findMaxMin = (data, key) => {
  // Create an array of objects with parsed values alongside their original values
  const parsedData = data
    .map((item) => {
      const value = item[key];
      // Determine if the value can be parsed to a number
      const parsedValue = typeof value === "string" && !isNaN(value) ? parseFloat(value) : value;
      return { original: value, parsed: parsedValue };
    })
    .filter((item) => typeof item.parsed === "number" && (Boolean(item.parsed) || item.parsed === 0)); // Ensure 0 is included

  // Extract original values for max and min by finding max and min parsed values
  const maxParsedValue = parsedData.length ? Math.max(...parsedData.map((item) => item.parsed)) : undefined;
  const minParsedValue = parsedData.length ? Math.min(...parsedData.map((item) => item.parsed)) : undefined;

  const maxOriginalValue = parsedData.find((item) => item.parsed === maxParsedValue)?.original;
  const minOriginalValue = parsedData.find((item) => item.parsed === minParsedValue)?.original;

  return { max: maxOriginalValue, min: minOriginalValue };
};

const calculateProperties = (nodes) => {
  // Recursive function to process each node
  const processNode = (node) => {
    if (node.type === "component") {
      // If the node is a component, return its properties
      return [{ length: node.length, l_axis_position: node.l_axis_position || 0 }];
    } else if (node.children && node.children.length > 0) {
      // If the node has children, process each child
      let childResults = [];
      node.children.forEach((child) => {
        childResults = childResults.concat(processNode(child));
      });
      // Calculate the sum using the calculateSum function
      const calculatedSum = calculateRocketLength(childResults);
      const newNode = { totalLength: calculatedSum };
      // Return the aggregated results to parent nodes
      return [newNode];
    }
    return [];
  };
  // Process each top-level node
  nodes.forEach((node) => {
    node.totalLength = processNode(node)[0].totalLength;
  });
  return nodes;
};

const calculateRocketLength = (items) => {
  if (!items || items.length === 0) return 0;

  // Reduce items to a map of l_axis_position with maximum lengths
  const axisLengths = items.reduce((map, { length, l_axis_position }) => {
    if (!map[l_axis_position] || map[l_axis_position] < length) {
      map[l_axis_position] = length;
    }
    return map;
  }, {});

  // Convert the map to an array and sort by l_axis_position
  const sortedAxisLengths = Object.entries(axisLengths).map(([l_axis_position, length]) => ({
    total: preciseAdd(l_axis_position, length),
    l_axis_position,
  }));

  return findMaxTotalAndMinLengthDifference(sortedAxisLengths) || 0;
};

const findMaxTotalAndMinLengthDifference = (arr) => {
  const { maxTotal, minLength } = arr.reduce(
    (acc, item) => {
      const total = parseFloat(item.total);
      const length = parseFloat(item.l_axis_position);
      return {
        maxTotal: total > acc.maxTotal ? total : acc.maxTotal,
        minLength: length < acc.minLength ? length : acc.minLength,
      };
    },
    { maxTotal: -Infinity, minLength: Infinity }
  );

  return preciseSubtract(maxTotal, minLength);
};

const preciseAdd = (num1, num2) => {
  const factor = Math.pow(10, Math.max(countDecimalPlaces(num1), countDecimalPlaces(num2)));
  return (Math.round(num1 * factor) + Math.round(num2 * factor)) / factor;
};

const preciseSubtract = (num1, num2) => {
  // Helper function to count decimal places
  const countDecimalPlaces = (num) => {
    if (Math.floor(num) === num) return 0;
    return num.toString().split(".")[1].length || 0;
  };

  // Determine the maximum number of decimal places
  const factor = Math.pow(10, Math.max(countDecimalPlaces(num1), countDecimalPlaces(num2)));

  // Perform subtraction with the factor to handle precision
  return (Math.round(num1 * factor) - Math.round(num2 * factor)) / factor;
};

const countDecimalPlaces = (num) => {
  if (!num) return 0;
  if (Math.floor(num) === num) return 0;
  return num.toString().split?.(".")?.[1]?.length || 0;
};

const calculateCenterOfGravity = (items) => {
  if (!items || items?.length === 0) return 0;
  let sumMass = 0;
  let sumGravityX = 0;
  let sumGravityY = 0;
  let sumGravityZ = 0;

  items?.forEach((item) => {
    sumMass = preciseAdd(sumMass, item?.mass);
    sumGravityX = preciseAdd(sumGravityX, parseFloat(item?.center_of_gravity_x) * parseFloat(item?.mass));
    sumGravityY = preciseAdd(sumGravityY, parseFloat(item?.center_of_gravity_y) * parseFloat(item?.mass));
    sumGravityZ = preciseAdd(sumGravityZ, parseFloat(item?.center_of_gravity_z) * parseFloat(item?.mass));
  });

  return {
    center_of_gravity_x: sumGravityX / sumMass,
    center_of_gravity_y: sumGravityY / sumMass,
    center_of_gravity_z: sumGravityZ / sumMass,
  };
};

const formatStringToNumber = (s) => {
  // Trim the input to remove any leading/trailing whitespace
  s = s.trim();

  // Regular expression to check if it's numeric or in "0.x", "-0.x" or ".x", "-.x" format
  if (!/^-?\d*(\.\d+)?$/.test(s)) {
    // Regular expression to extract digits and a single decimal point
    const extracted = s.match(/[\d.]/g);

    if (extracted) {
      // Join the matched parts
      let result = extracted.join("");

      // Ensure only one decimal point is present
      const decimalIdx = result.indexOf(".");
      if (decimalIdx !== -1) {
        result = result.slice(0, decimalIdx + 1) + result.slice(decimalIdx + 1).replace(/\./g, "");
      }

      return result;
    }
    return 0;
  }

  // Special case for zero, handle multiple leading zeros
  if (/^0+(\.0+)?$/.test(s)) {
    return "0";
  }

  // Handle cases like '.0099' and '-.0099'
  if (s.startsWith(".")) {
    return "0" + s;
  }

  if (s.startsWith("-.")) {
    return "-0" + s.slice(1);
  }

  // Special cases for '0', '-0', '0.x', and '-0.x'
  if (s === "0" || s === "-0" || /^-?0\.\d+$/.test(s)) {
    return s;
  }

  // Handle leading zeros for negative numbers
  if (s.startsWith("-")) {
    return "-" + s.slice(1).replace(/^0+/, "");
  }

  // Handle leading zeros for positive numbers
  return s.replace(/^0+/, "");
};

const deepEqual = (x, y) => {
  const isObject = (obj) => obj && typeof obj === "object";

  if (isObject(x) && isObject(y)) {
    if (Object.keys(x).length !== Object.keys(y).length) {
      return false;
    }

    return Object.keys(x).every((key) => deepEqual(x[key], y[key]));
  } else {
    // eslint-disable-next-line
    return x == y;
  }
};

const getClusterCenters = (arr, gap) => {
  arr.sort((a, b) => a.value - b.value);

  const clusters = [];
  let currentCluster = [];

  arr.forEach(({ value }) => {
    if (currentCluster.length === 0) {
      currentCluster.push(value);
      return;
    }

    // For the first cluster, use the first value for gap comparison
    if (clusters.length === 0) {
      const firstValue = currentCluster[0];
      if (value - firstValue <= gap) {
        currentCluster.push(value);
        return;
      }
    } else {
      // For other clusters, use the middle value for gap comparison
      const middleIndex = Math.floor(currentCluster.length / 2);
      const middleValue = currentCluster[middleIndex];
      if (value - middleValue <= gap) {
        currentCluster.push(value);
        return;
      }
    }

    clusters.push([...currentCluster]);
    currentCluster = [value];
  });

  if (currentCluster.length > 0) {
    clusters.push(currentCluster);
  }

  return clusters.map((cluster, index) => {
    if (index === 0) {
      // For the first cluster, return the first item
      return { value: cluster[0] };
    }
    if (index === clusters.length - 1) {
      // For the last cluster, return the maximum value
      const maxValue = Math.max(...cluster);
      return { value: maxValue };
    }

    // For other clusters, return the middle value
    const middleIndex = Math.floor(cluster.length / 2);
    return { value: cluster[middleIndex] };
  });
};

export {
  humanFileSize,
  transformDataVersionsToOptions,
  getFileName,
  getFileType,
  uniqArray,
  handleScrollTo,
  sortData,
  uuid,
  extractIDsFromString,
  transformProcessDataToOptions,
  getConfigFromId,
  getConfigFileName,
  isCSVContent,
  handleScrollToTop,
  transformThrustCalculation,
  reverseTransformThrustCalculation,
  roundToTwoDecimals,
  mergeArrays,
  getChartData,
  uniqueArray,
  MAPPING_CONTENT_CHANGE_TO_TEXT,
  sumArrayWithKey,
  mergeChildren,
  findMaxMin,
  calculateRocketLength,
  calculateProperties,
  calculateCenterOfGravity,
  formatStringToNumber,
  deepEqual,
  getClusterCenters,
};
