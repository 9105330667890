import React from "react";
import routeGuard from "src/HOC/routeGuard";
import FlightSimulatorList from "src/components/FlightSimulator/list";

const FlightSimulator = () => {
  return (
    <FlightSimulatorList />
  );
};

export default routeGuard(FlightSimulator);
