import { useMutation, useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";
import { alertState } from "src/recoil/atoms/global";


const handleError = (err, t, showAlert) => {
  if(err?.message === 'No Internet') return;
  let errMsg = err?.response?.data?.error?.message || t("network_error");
  const errDetail = err?.response?.data?.error?.detail || {};
  const errField = Object.keys(errDetail);
  if (errField.length > 0) {
    errMsg = t(`new.${errField[0]}_${errDetail[errField[0]]}`, {ns: 'test-analysis'});
  }
  return showAlert({
    status: true,
    message: errMsg,
    type: "danger",
  });
}

const useMutationHooks = (options, mutationError = false) => {
  const { t } = useTranslation(["common","test-analysis"]);
  const showAlert = useSetRecoilState(alertState);
  return useMutation({
    retry: 0,
    networkMode: "always",
    ...options,
    onError: (err) => {
      if (mutationError) {
        return err;
      }

      if (!options?.onError) {
        return handleError(err, t, showAlert);
      }

      return options?.onError(err);
    },
  });
};

const useQueryHooks = (queryKey, func, options, mutationError = false) => {
  const { t } = useTranslation(["common","test-analysis"]);
  const showAlert = useSetRecoilState(alertState);
  return useQuery(queryKey, func, {
    retry: 0,
    networkMode: "always",
    ...options,
    onError: (err) => {
      if (mutationError) {
        return err;
      }

      if (!options?.onError) {
        return handleError(err, t, showAlert);
      }

      return options?.onError(err);
    },
  });
};

export { useMutationHooks as useMutation, useQueryHooks as useQuery };
