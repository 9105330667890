import React, { useCallback } from "react";
import { Row, Col, Card, Container } from "reactstrap";
import { withTranslation } from "react-i18next";

import Logo from "src/assets/images/logo.png";

import Google from "src/assets/images/google.svg";
import {
  REACT_APP_API_END_POINT,
  REACT_APP_GOOGLE_CLIENT_ID,
} from "src/constant/environment";
import { GET_URL_SIGN_IN } from "src/constant/endpoint";

const Login = ({ t }) => {
  const openGoogleLoginPage = useCallback(() => {
    const googleAuthUrl = "https://accounts.google.com/o/oauth2/v2/auth";

    const scope = [
      "https://www.googleapis.com/auth/userinfo.email",
      "https://www.googleapis.com/auth/userinfo.profile",
    ].join(" ");

    const params = {
      response_type: "code",
      client_id: REACT_APP_GOOGLE_CLIENT_ID,
      redirect_uri: `${REACT_APP_API_END_POINT}/${GET_URL_SIGN_IN}`,
      prompt: "select_account",
      access_type: "offline",
      scope,
    };

    const urlParams = new URLSearchParams(params).toString();

    window.location = `${googleAuthUrl}?${urlParams}`;
  }, []);

  return (
    <Container className="border bg-white px-0">
      <Row className="gx-0 h-100">
        <Col md={6} className="p-1 h-100 my-auto">
          <div className="p-3 h-100">
            <Card className="header-content d-flex justify-content-center mb-0 border-0 h-100">
              <h2 className="header-title d-flex align-items-center f-s-18">
                <img src={Logo} alt="logo" className="logo" />
                <span>{t("title")}</span>
              </h2>
              <h3 className="mt-3 f-s-18">{t("sub_title")}</h3>
              <p className="text-muted f-s-18 mt-1 mb-4">
                {t("text_description")}
              </p>
              <button
                className="google-login-btn mx-auto"
                onClick={() => openGoogleLoginPage()}
              >
                <div className="google-img">
                  <img src={Google} alt="google" />
                </div>
                <span>{t("login_with_google")}</span>
              </button>
            </Card>
          </div>
        </Col>
        <Col md={6} className="p-0">
          <div className="sidebar-login" />
        </Col>
      </Row>
    </Container>
  );
};

export default withTranslation(["login", "common"])(Login);
