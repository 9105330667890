import React from "react";
import { useTranslation } from "react-i18next";
import FormInput from "src/components/Common/Form/FormInput";

import { FORM_NAME } from "./index";
import Modes from "./Modes";
import { PATTERN_NEGATIVE_NUMBER } from "src/constant/common";

const Stage = ({ stages, errors, register, options, control, numberOfStage }) => {
  const { t } = useTranslation(["thrust-calculation", "common"]);

  return (
    <>
      {stages?.map((stage, stageIndex) => (
        <div className={`col-md-12 mt-2 ${numberOfStage !== stageIndex + 1 && "border-bottom"}`} key={stage.id}>
          <div className="row">
            <div className="col-md-4">
              <FormInput
                register={register}
                label={t("new.total_mass_label", { count: stages.length - stageIndex })}
                name={`${FORM_NAME.STAGE}[${stageIndex}].${FORM_NAME.TOTAL_PROPELLANT_MASS}`}
                errors={errors?.[FORM_NAME.STAGE]?.[stageIndex]?.[FORM_NAME.TOTAL_PROPELLANT_MASS]?.message}
                placeholder={t("new.total_mass_placeholder")}
                required
                requiredMessage={t("new.total_mass_required")}
                classInput="min-height-by-px-38"
                pattern={{
                  value: PATTERN_NEGATIVE_NUMBER,
                  message: t(`new.field_number_validate`, { field: t("new.total_mass_field") }),
                }}
                valueGreaterThanZero
                errorClass="text-nowrwap"
                unit="kg"
              />
            </div>
          </div>

          <Modes options={options} stageIndex={stageIndex} errors={errors} control={control} register={register} />
        </div>
      ))}
    </>
  );
};

export default Stage;
