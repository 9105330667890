import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { Label } from "reactstrap";

const FormTextarea = ({
  label,
  name,
  placeholder,
  classInput,
  classGroup,
  register,
  errors,
  required,
  requiredMessage,
  pattern,
  maxLength,
  maxLengthMessage,
  min,
  minMessage,
  rows = 6,
}) => {
  const { t } = useTranslation("common");

  return (
    <div className={classNames("form-group position-relative", classGroup)}>
      {label && (
        <Label for={name} className={classNames("position-relative", { "required-input": required })}>
          {label}
        </Label>
      )}
      <textarea
        id={name}
        placeholder={placeholder}
        className={classNames("form-control", classInput, {
          "border-danger": errors,
        })}
        rows={rows}
        {...register(name, {
          ...(required && { required: requiredMessage || t("required") }),
          ...(maxLength && { maxLength: { value: maxLength, message: maxLengthMessage || t("name_maxlength") } }),
          validate: {
            ...(min && { min: (value) => parseFloat(value) > parseFloat(min) || minMessage }),
            ...(required && { requiredTrimmed: (value) => value?.toString()?.trim?.().length > 0 || requiredMessage || t("required") }),
          },
          ...(pattern && { pattern: { value: pattern.value, message: pattern.message } }),
          onBlur: (e) => {
            const target = e.target;
            if (typeof target.value === "string" && target.value) {
              target.value = target.value.trim();
            }
          },
        })}
      />

      {errors && <div className={`text-danger font-12 position-absolute`}>{errors.message}</div>}
    </div>
  );
};

export default FormTextarea;
