import React from "react";
import {
  Row,
  Container,
} from "reactstrap";

const AerodynamicsSimulatorList = () => {
 
  return (
    <Container fluid>
      <Row>
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-2">
                <h2 className="header-title mb-0">AERODYNAMICS SIMULATOR</h2>
              </div>
            </div>
          </div>
        </div>
      </Row>
    </Container>
  );
};

export default AerodynamicsSimulatorList;
