import React from "react";
import { useTranslation } from "react-i18next";
import { useFieldArray } from "react-hook-form";
import { COMPONENT_MODE, FORM_NAME } from ".";
import FormInput from "src/components/Common/Form/FormInput";
import FormSelect from "src/components/Common/Form/FormSelect";
import { PATTERN_INTEGER_NEGATIVE_NUMBER } from "src/constant/common";

const Engine = ({ options, stageIndex, modeIndex, register, control, errors }) => {
  const { t } = useTranslation(["thrust-calculation"]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: `${FORM_NAME.STAGE}[${stageIndex}].${FORM_NAME.MODES}[${modeIndex}].${FORM_NAME.ENGINE_COMPONENT}`,
  });

  return (
    <>
      {fields?.map((component, componentIndex) => (
        <div key={component.id} className="row align-items-center position-relative">
          <div className="col-6">
            <FormSelect
              label={componentIndex === 0 ? t("new.engine_component") : ""}
              placeholder={t("new.engine_component_placeholder")}
              control={control}
              name={`${FORM_NAME.STAGE}[${stageIndex}].${FORM_NAME.MODES}.[${modeIndex}].${FORM_NAME.ENGINE_COMPONENT}.[${componentIndex}].${FORM_NAME.COMPONENT_ID}`}
              errors={
                errors?.[FORM_NAME.STAGE]?.[stageIndex]?.[FORM_NAME.MODES]?.[modeIndex]?.[FORM_NAME.ENGINE_COMPONENT]?.[componentIndex]?.[
                  FORM_NAME.COMPONENT_ID
                ]?.message
              }
              options={options}
              required
              requiredMessage={t("new.engine_component_required")}
              classInput="min-height-by-px-38"
              indexMenu={1051}
            />
          </div>
          <div className="col-5">
            <FormInput
              register={register}
              label={componentIndex === 0 ? t("new.number_of_units") : ""}
              name={`${FORM_NAME.STAGE}[${stageIndex}].${FORM_NAME.MODES}.[${modeIndex}].${FORM_NAME.ENGINE_COMPONENT}.[${componentIndex}].${FORM_NAME.NUMBER_OF_UNITS}`}
              errors={
                errors?.[FORM_NAME.STAGE]?.[stageIndex]?.[FORM_NAME.MODES]?.[modeIndex]?.[FORM_NAME.ENGINE_COMPONENT]?.[componentIndex]?.[
                  FORM_NAME.NUMBER_OF_UNITS
                ]?.message
              }
              placeholder={t("new.number_of_units_placeholder")}
              required
              requiredMessage={t("new.number_of_units_required")}
              classInput="min-height-by-px-38"
              pattern={{
                value: PATTERN_INTEGER_NEGATIVE_NUMBER,
                message: t(`new.field_number_integer_validate`),
              }}
              valuePositive
            />
          </div>
          {fields?.length > 1 && (
            <span className="position-absolute r-15 w-auto bt-25" onClick={() => remove(componentIndex)} role="button">
              <i className="bi bi-trash3" style={{ fontSize: "24px", color: "red" }}></i>
            </span>
          )}
        </div>
      ))}
      <button
        type="button"
        onClick={() => append(COMPONENT_MODE)}
        className="btn btn-secondary"
        style={{ marginBottom: "20px", marginLeft: "0", marginTop: fields.length > 0 ? "10px" : "30px" }}
      >
        {t("new.add_engine")}
      </button>
    </>
  );
};

export default Engine;
