import { Tooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";
import { 
  Button,
  Spinner,
} from "reactstrap";
import { Link } from "react-router-dom";

const ButtonCollapse = ({handleCollapse, isCollapsed}) => {  
  return (
    <Button
      outline
      size="sm"
      className="px-1 btn-collapse height-by-px-36"
      onClick={handleCollapse}
    >
      <i
        className={`bi bi-chevron-${
          isCollapsed ? "down" : "up"
        } f-s-14 fw-bold text-dark`}
      />
    </Button>
  );
};

const SingleDashboard = ({handleClick, isReady, isLoading, isShow = true, id}) => {  
  const { t } = useTranslation("flight-simulator");
  return (
    isShow ? (
      isLoading ? (
        <Spinner size="sm" color="secondary" />
      ) : (
        <>
          <button
            className="btn btn-blank px-1 border-0"
            onClick={() => handleClick()}
            data-tooltip-id={`dashboard-${id}`}
            data-tooltip-content={t("list.table.dashboard")}
          >
            <i className={`bi bi-speedometer2 ${isReady ? 'text-success' : 'text-dark'} f-s-20`}/>
          </button>
          <Tooltip id={`dashboard-${id}`} className="text-break-space" />
        </>
      )
    ) : null
  );
};

const ButtonDownload = ({url = '', id}) => {  
  const { t } = useTranslation("flight-simulator");
  return (
    !!url ? (
      <div>
        <a 
          href={url}
          data-tooltip-id={`download-${id}`}
          data-tooltip-content={t("list.table.download")}
        >
          <i className="bi bi-cloud-download f-s-20 text-dark" />
        </a>
        <Tooltip id={`download-${id}`} className="text-break-space" />
      </div>
    ) : null
  );
};


const ButtonCopy = ({isShow = true, toUrl, id}) => {  
  const { t } = useTranslation("flight-simulator");
  return (
    isShow ? (
      <>
        <Link 
          to={toUrl}
          data-tooltip-id={`copy-${id}`}
          data-tooltip-content={t("list.table.copy")}
        >
          <i className="bi bi-copy f-s-20 text-dark" />
        </Link>
        <Tooltip id={`copy-${id}`} className="text-break-space" />
      </>
    ) : null
  );
};

const ButtonRetry = ({isShow = true, toUrl, id}) => {  
  const { t } = useTranslation("flight-simulator");
  return (
    isShow ? (
      <>
        <Link 
          to={toUrl}
          data-tooltip-id={`retry-${id}`}
          data-tooltip-content={t("new.retry")}
        >
          <i className="bi bi-arrow-clockwise f-s-20 text-dark" />
        </Link>
        <Tooltip id={`retry-${id}`} className="text-break-space" />
      </>
    ) : null
  );
};


const ButtonFileViewer = ({handleClick, id}) => {  
  const { t } = useTranslation("flight-simulator");
  return (
    <>
      <button
        className="btn btn-blank border-0 p-0"
        onClick={() => handleClick()}
        data-tooltip-id={`fileview-${id}`}
        data-tooltip-content={t("list.table.display_data")}
      >
        <i className="bi bi-file-earmark-text f-s-20 text-dark"></i>
      </button>
      <Tooltip id={`fileview-${id}`} className="text-break-space" />
    </>
  );
};

const ButtonErrorWarning = ({isShow = false, errorContent, id}) => {  
  return (
    isShow ? (
      <>
        <i 
          className="bi text-danger bi-exclamation-triangle f-s-20 px-1"
          data-tooltip-id={`serie-item-${id}`}
          data-tooltip-content={errorContent}
        />
        <Tooltip id={`serie-item-${id}`} className="text-break-space z-i-1009" />
      </>
    ) : null
  );
};

export {
  ButtonCollapse,
  SingleDashboard,
  ButtonDownload,
  ButtonCopy,
  ButtonRetry,
  ButtonFileViewer,
  ButtonErrorWarning
};