import api from "src/configs/api";
import {
  GET_ENGINE_COMPONENT,
  CREATE_ENGINE_COMPONENT,
  GET_ENGINE_COMPONENT_DETAIL,
  EDIT_ENGINE_COMPONENT,
} from "src/constant/endpoint";


const getEngineComponent = async (params) => {
  return await api
    .get(GET_ENGINE_COMPONENT, { params })
    .then((response) => response?.data?.data);

};

const createEngineComponent = ({ data }) => {
  return api.post(CREATE_ENGINE_COMPONENT, { data }).then((response) => response?.data);
}

const editEngineComponent = ({ id, data }) =>{
    return api.put(EDIT_ENGINE_COMPONENT(id), {data} ).then((response) => response?.data);
}


const getEngineComponentDetail = async (id) => {
    return await api
      .get(GET_ENGINE_COMPONENT_DETAIL(id))
      .then((response) => response?.data?.data);
  };

export {
  createEngineComponent,
  getEngineComponent,
  editEngineComponent,
  getEngineComponentDetail,
};
