import React from "react";
import routeGuard from "src/HOC/routeGuard";
import RocketModelList, {
} from "src/components/RocketModel/list";

const RocketModel = () => {

  return (
    <RocketModelList
    />
  );
};

export default routeGuard(RocketModel);