import { calculateCenterOfGravity, calculateRocketLength, findMaxMin, sumArrayWithKey } from "src/utils/common";

const getComponentDetails = (json, componentDetails = []) => {
  const transformedJson = json.map((stage, index) => {
    const updatedStage = { ...stage };
    updatedStage.isOpen = componentDetails[index]?.isOpen ?? true;

    updatedStage.children = updatedStage.children.map((level0, level0Index) => {
      const updatedLevel0 = { ...level0 };
      updatedLevel0.isOpen = componentDetails[index]?.children?.[level0Index]?.isOpen ?? true;

      updatedLevel0.children = updatedLevel0.children.map((level1, level1Index) => {
        const updatedLevel1 = { ...level1 };
        updatedLevel1.isOpen = componentDetails[index]?.children?.[level0Index]?.children?.[level1Index] ?? true;

        updatedLevel1.children = updatedLevel1.children.map((component) => {
          const updatedComponent = { ...component };
          const { properties: props } = updatedComponent;

          updatedComponent.mass = props?.mass?.value;
          updatedComponent.l_axis_position = props.l_axis_position;
          updatedComponent.center_of_gravity_x = props.center_of_gravity.value[0];
          updatedComponent.center_of_gravity_y = props.center_of_gravity.value[1];
          updatedComponent.center_of_gravity_z = props.center_of_gravity.value[2];
          updatedComponent.inner_diameter = props?.inner_diameter?.value;
          updatedComponent.outer_diameter = props?.outer_diameter?.value;
          updatedComponent.length = props.length.value;
          updatedComponent.moment_of_inertia_x = props.moment_of_inertia.value[0];
          updatedComponent.moment_of_inertia_y = props.moment_of_inertia.value[1];
          updatedComponent.moment_of_inertia_z = props.moment_of_inertia.value[2];
          updatedComponent.product_of_inertia_x = props.product_of_inertia.value[0];
          updatedComponent.product_of_inertia_y = props.product_of_inertia.value[1];
          updatedComponent.product_of_inertia_z = props.product_of_inertia.value[2];

          return updatedComponent;
        });

        return updatedLevel1;
      });

      return updatedLevel0;
    });

    return updatedStage;
  });

  return transformComponentDetailsData(transformedJson);
};

const calculateProperties = (node) => {
  node.mass = sumArrayWithKey(node?.children, "mass") || 0;
  node.l_axis_position = findMaxMin(node?.children, "l_axis_position")?.min || 0;
  node.outer_diameter = findMaxMin(node?.children, "outer_diameter")?.max || 0;
  node.length = calculateRocketLength(node?.children) || 0;
  const centerOfGravity = calculateCenterOfGravity(node?.children) || {};
  node.center_of_gravity_x = centerOfGravity.center_of_gravity_x || 0;
  node.center_of_gravity_y = centerOfGravity.center_of_gravity_y || 0;
  node.center_of_gravity_z = centerOfGravity.center_of_gravity_z || 0;
};

const transformComponentDetailsData = (data) => {
  const transformedData = data.map((stage) => {
    const transformedLevel0Children = stage.children.map((level0) => {
      const transformedLevel1Children = level0.children.map((level1) => {
        calculateProperties(level1); // Apply your properties calculation
        return level1; // Return the transformed level1
      });

      const transformedLevel0 = {
        ...level0,
        children: transformedLevel1Children,
      };
      calculateProperties(transformedLevel0); // Calculate properties at level0 after transformation
      return transformedLevel0;
    });

    const transformedStage = {
      ...stage,
      children: transformedLevel0Children,
    };
    calculateProperties(transformedStage); // Calculate properties at stage level after transformation
    return transformedStage;
  });

  return transformedData;
};

export { getComponentDetails, transformComponentDetailsData };
