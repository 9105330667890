import React, { useState } from "react";
import { 
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Spinner,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";

const SeriesDashboard = ({handleClickFlight, handleClickSensitivity, id, isLoading, isShow = true, serieData }) => {
  const { t } = useTranslation("flight-simulator");
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  
  const isSensitivityAnalysisTrue = (obj) => {
    if (typeof obj !== 'object' || obj === null) return false;
    return Object.keys(obj).some(key => 
      (typeof obj[key] === 'object' && isSensitivityAnalysisTrue(obj[key])) || 
      (key === 'is_sensitivity_analysis' && obj[key]?.value === 1)
    );
  };

  return (
    isShow ? (
      isLoading ? (
        <Spinner size="sm" color="secondary" />
      ) : (
        <>
          <Dropdown
            isOpen={dropdownOpen}
            toggle={toggleDropdown}
            className="align-self-center"
            data-tooltip-id={`dashboard-serie-${id}`}
            data-tooltip-content={t("list.table.dashboard")}
            direction="end"
          >
            <DropdownToggle
              data-toggle="dropdown"
              tag="button"
              className="border-0 bg-transparent"
            >
              <i className={`bi bi-speedometer2 ${serieData.dashboard_url && serieData.sensitivity_analysis_dashboard_url ? 'text-success' : 'text-dark'} f-s-20`}/>
            </DropdownToggle>
            
            <DropdownMenu
              className="topbar-dropdown-menu profile-dropdown-items"
            >
              <div onClick={toggleDropdown}>
                <div
                  role="button"
                  className={`dropdown-item ${serieData.dashboard_url ? 'text-success' : 'text-dark'}`}
                  onClick={handleClickFlight}
                >
                  <span>{t('list.table.flight_analysis_dashboard')}</span>
                </div>
                {
                  isSensitivityAnalysisTrue(serieData.jsonObject) ? (
                    <div
                      role="button"
                      className={`dropdown-item ${serieData.sensitivity_analysis_dashboard_url ? 'text-success' : 'text-dark'}`}
                      onClick={handleClickSensitivity}
                    >
                      <span>{t('list.table.sensitivity_analysis_dashboard')}</span>
                    </div>
                  ) : null
                }
              </div>
            </DropdownMenu>
          </Dropdown>
          <Tooltip id={`dashboard-serie-${id}`} className="text-break-space" />
        </>
      )
    ) : null
  );
};

export default SeriesDashboard;
