import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Container, CardHeader } from "reactstrap";
import useUrlState from "@ahooksjs/use-url-state";
import { useTranslation } from "react-i18next";
import { getRocketModel } from "src/services/rocket-model";
import { PAGINATION_DEFAULT, PROCESS_STATUS, DASHBOARD_STATUS, REFETCH_INTERVAL_TIME } from "src/constant/common";
import { handleScrollTo, handleScrollToTop } from "src/utils/common";
import { useQuery } from "src/Hooks/useReactQuery";
import LoadingWrapper from "src/components/Common/Table/Loading";
import ReactPaginate from "react-paginate";
import ModelAddRocketModel from "src/components/Common/Modal/ModelAddRocketModel";
import ModelCopyRocketModel from "src/components/Common/Modal/ModalCopyRocketModel";
import { Link } from "react-router-dom";
import APP_ROUTES from "src/routes/routes";
import ShowDataCell from "src/components/Common/DataCell";

const RocketModelList = () => {
  const { t } = useTranslation(["rocket-model", "common"]);

  const [params, setParams] = useUrlState(
    {},
    {
      navigateMode: "replace",
      parseOptions: {
        parseNumbers: true,
      },
      stringifyOptions: {
        skipNull: true,
      },
    }
  );

  const [isLoadingSimulator, setIsLoadingSimulator] = useState(true);
  const [allowScrollToId, setAllowScrollToId] = useState(true);
  const { data: dataRocketModel, refetch } = useQuery(
    ["getRocketModel"],
    () =>
      getRocketModel({
        page: params.page,
        limit: PAGINATION_DEFAULT.pageSize,
        q: params?.condition,
      }),
    {
      onSuccess: (data) => {
        for (const testItem of data?.items) {
          if (testItem?.items) {
            testItem.items.sort((a, b) => (a.type > b.type ? -1 : b.type > a.type ? 1 : 0));

            if (testItem?.simulator_status === PROCESS_STATUS.SUCCEEDED) {
              const haveFailedItem = testItem.items.some((item) => item.error_message);
              testItem.simulator_status = haveFailedItem ? PROCESS_STATUS.FAILED : testItem.simulator_status;
            }

            if (!testItem?.items?.length) {
              testItem.simulator_status = PROCESS_STATUS.PENDING;
            }
          }
        }
        if (params?.id && allowScrollToId) {
          handleScrollTo(0, 90, `row${params.id}`);
          setAllowScrollToId(false);
        }
      },
      onSettled: () => {
        setIsLoadingSimulator(false);
      },
      refetchInterval: REFETCH_INTERVAL_TIME,
    }
  );

  const onChangePage = (value) => {
    setParams({
      ...params,
      page: value,
    });
    handleScrollToTop();
  };

  const getCurrentPage = () => {
    return (params?.page || PAGINATION_DEFAULT.page) - 1;
  };

  useEffect(() => {
    setIsLoadingSimulator(true);
    refetch();
  }, [params]);

  return (
    <Container fluid>
      <Row>
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-2">
                <h2 className="header-title mb-0">{t("list.title")}</h2>
              </div>
              <Row>
                <Col md={12}>
                  <Card>
                    <div className="d-flex justify-content-end">
                      <CardHeader className="bg-white border-0">
                        <ModelAddRocketModel onSuccess={refetch} />
                      </CardHeader>
                    </div>
                    <CardBody className="bg-white">
                      <LoadingWrapper loading={isLoadingSimulator}>
                        <div className="react-bootstrap-table overflow-auto simulation-table">
                          <table className="table striped table-collapse table-fixed">
                            <thead>
                              <tr>
                                <th style={{ width: "7%" }}>{t("list.table.id")}</th>
                                <th style={{ width: "15%" }}>{t("list.table.name")}</th>
                                <th style={{ width: "10%" }}>{t("list.table.status")}</th>
                                <th style={{ width: "10%" }}>{t("list.table.stage")}</th>
                                <th style={{ width: "10%" }}>{t("list.table.total_mass")}</th>
                                <th style={{ width: "10%" }}>{t("list.table.total_length")}</th>
                                <th style={{ width: "10%" }}>{t("list.table.remarks")}</th>
                                <th style={{ width: "9%" }}></th>
                              </tr>
                            </thead>
                            <tbody>
                              {dataRocketModel?.items?.length > 0 ? (
                                dataRocketModel.items.map((row, indexRow) => (
                                  <Fragment key={indexRow}>
                                    <tr id={`row${row?.id}`}>
                                      <td>{row.id}</td>
                                      <td>
                                        <Link to={`${APP_ROUTES.ROCKET_MODEL}/${row.id}?${params.page ? "&page=" + params.page : ""}`}>
                                          {row.name}
                                        </Link>
                                      </td>
                                      <td>
                                        {row.edit_status === DASHBOARD_STATUS.INPROGRESS
                                          ? t("list.table.in_progress")
                                          : row.edit_status === DASHBOARD_STATUS.FIXED
                                          ? t("list.table.fixed")
                                          : t("list.table.fixed")}
                                      </td>
                                      <td>{row.number_of_stage}</td>
                                      <td>{row.total_mass}</td>
                                      <td>{row.total_length}</td>
                                      <td>
                                        <ShowDataCell data={row.remarks} />
                                      </td>
                                      <td className="text-center">
                                        <ModelCopyRocketModel modelId={row?.id} onSuccess={refetch} />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colSpan={7} className="hiddenRow"></td>
                                    </tr>
                                  </Fragment>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="8" className="text-center">
                                    {t("list.table.no_data")}
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                        {dataRocketModel?.pagination?.total_page > 1 && (
                          <div className="pagination-custom">
                            <div className="fw-bold">
                              {dataRocketModel?.pagination?.total > 0 &&
                                t("pagination", {
                                  first: getCurrentPage() * 50 + 1,
                                  last:
                                    dataRocketModel?.pagination?.total < (getCurrentPage() + 1) * 50
                                      ? dataRocketModel?.pagination?.total
                                      : (getCurrentPage() + 1) * 50,
                                  total: dataRocketModel?.pagination?.total,
                                })}
                            </div>
                            <ReactPaginate
                              nextLabel={">"}
                              onPageChange={(value) => {
                                onChangePage(value.selected + 1);
                              }}
                              {...{
                                pageRangeDisplayed: 5,
                                marginPagesDisplayed: 2,
                                pageCount: dataRocketModel?.pagination?.total_page,
                                forcePage: getCurrentPage(),
                              }}
                              previousLabel={"<"}
                              pageClassName="page-item"
                              pageLinkClassName="page-link"
                              previousClassName="page-item"
                              previousLinkClassName="page-link"
                              nextClassName="page-item"
                              nextLinkClassName="page-link"
                              breakLabel={"..."}
                              breakClassName="page-item"
                              breakLinkClassName="page-link"
                              containerClassName="pagination"
                              activeClassName="active"
                              renderOnZeroPageCount={null}
                            />
                          </div>
                        )}
                      </LoadingWrapper>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Row>
    </Container>
  );
};

export default RocketModelList;
