import classNames from "classnames";
import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { getPreSignedUrlTestAnalysis, uploadFilesTos3 } from "src/services/upload";
import { Spinner, Label } from "reactstrap";
import { getFileName, getFileType } from "src/utils/common";
import { FILE_TYPE } from "src/constant/common";
import { Tooltip } from "react-tooltip";
import { UPLOAD_TYPE } from 'src/constant/common';

const TestAnalysisUpload = ({
  name,
  placeholder,
  classInput,
  classGroup,
  setValue,
  register,
  clearErrors,
  errors,
  required,
  requiredMessage,
  label,
  uploadType,
}) => {
  const { t } = useTranslation(["test-analysis", "common"]);
  const [toggle, setToggle] = useState(false);
  const [itemDelete, setItemDelete] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [files, setFiles] = useState([]);
  const [dragOver, setDragOver] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const handleUploadFile = async (e) => {
    if (e?.target?.files?.length > 10) {
      clearErrors(name);
      setErrMsg(t("new.limit_attachments"));
      return;
    }

    const fileData = [];

    [...e?.target?.files].map((item) => {
      if (getFileType(item.name) !== FILE_TYPE.CSV) {
        clearErrors(name);
        return setErrMsg(t("new.validate_attachments", {
          file_name: item.name,
        }));
      } else {
        return fileData.push(item);
      }
    });
    if (fileData.length > 0) {
      setUploading(true);
      setErrMsg('');
      Promise.all([...fileData].map(uploadFile))
        .then((res) => {
          setValue(name, res[0]);
          setFiles(res);
        })
        .finally(() => setUploading(false));
    }
  };

  const handleDragEnter = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOver(true);
  }, []);

  const handleDragLeave = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOver(false);
  }, []);

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!dragOver) {
      setDragOver(true);
    }
  }, [dragOver]);

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOver(false);

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const dropFile = e.dataTransfer.files[0];
      if (getFileType(dropFile.name) !== FILE_TYPE.CSV) {
        clearErrors(name);
        return setErrMsg(t("new.validate_attachments", {
          file_name: dropFile.name,
        }));
      }
      setErrMsg('');
      setUploading(true);
      Promise.all([dropFile].map(uploadFile))
        .then((res) => {
          setValue(name, res[0]);
          setFiles(res);
        })
        .finally(() => {
          setUploading(false);
          e.dataTransfer.clearData();
        });
    }
  }, []);

  const uploadFile = async (file) => {
    const presignedUrl = await getPreSignedUrlTestAnalysis({
      data: {
        file_name: file?.name,
        type: uploadType || UPLOAD_TYPE.RAW
      },
    });

    const { url, fields } = presignedUrl;

    return await uploadFilesTos3({
      url,
      fields,
      file,
    });
  };

  const handleDeleteAttachments = (index) => {
    const newFiles = files;
    newFiles.splice(index, 1);
    setFiles(newFiles);
    setItemDelete(null);
    setToggle(false);
  };

  return (
    <div className={classNames("form-group position-relative", classGroup)}>
      {label && <Label for={name}>{label}</Label>}
      {files?.map((item, index) => (
        <div
          className="card shadow-none border width-by-percent-100 m-b-7"
          key={index}
        >
          <div className="p-2 d-flex justify-content-between align-items-center">
            <div className="row align-items-center">
              <div className="col-auto">
                <div className="avatar-sm">
                  <span className="avatar-title bg-soft-warning text-warning rounded fs-24">
                    <i className="bi bi-file-earmark-zip"></i>
                  </span>
                </div>
              </div>
              <div 
                className="col text-truncate ps-1 text-muted fw-bold fs-15"
                data-tooltip-id={`${name}-file-name`}
                data-tooltip-content={getFileName(item)}
              >
                {getFileName(item)}
              </div>
            </div>
            <Tooltip id={`${name}-file-name`} className="text-break-space" />
            <div>
              <i
                className="bi bi-trash text-danger f-s-24 user-select-pointer"
                role="button"
                onClick={() => {
                  document.body.click();
                  setToggle(true);
                  setItemDelete(index);
                }}
              />
            </div>
          </div>
        </div>
      ))}
      
      {!files.length && (
        <>
          <label
            htmlFor={name}
            className="w-100 btn-upload-lg"
            disabled={uploading}
            onDragEnter={handleDragEnter}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            {uploading ? (
              <div className="p-2 d-flex align-items-center justify-content-center">
                <Spinner color="secondary" />
              </div>
            ) : (
              <>
                <i className="bi bi-cloud-upload fs-24"></i>
                <div className="text-break-space text-center">{t("new.upload_placeholder")}</div>
                <div className="btn btn-primary btn-upload-sm p-d-2 m-t-10">{t("new.upload_button")}</div>
              </>
            )}
          </label>

          <input
            {...register(name, {
              required: required
            })}
            draggable
            type="file"
            name={name}
            id={name}
            hidden
            placeholder={placeholder}
            className={classNames("form-control", classInput)}
            onChange={handleUploadFile}
            accept=".csv"
            onClick={(event) => (event.currentTarget.value = null)}
            disabled={uploading}
            required={required}
          />
           {errors[name] && <div className="text-danger font-12 position-absolute">{requiredMessage}</div>}
           {errMsg && !errors[name] && <div className="text-danger font-12 position-absolute">{errMsg}</div>}
          
        </>
      )}
      

      <Modal isOpen={toggle} toggle={() => setToggle(false)} backdrop="static">
        <ModalHeader>{t("new.delete_modal.title_attachments")}</ModalHeader>
        <ModalBody>
          <div>{t("new.delete_modal.sure")}</div>
          <div>{t("new.delete_modal.recover")}</div>
        </ModalBody>
        <ModalFooter>
          <span
            className="btn btn-secondary btn-sm"
            role="button"
            onClick={() => setToggle(false)}
          >
            {t("new.delete_modal.cancel")}
          </span>
          <span
            className="btn btn-danger btn-sm"
            role="button"
            onClick={() => handleDeleteAttachments(itemDelete)}
          >
            {t("new.delete_modal.delete")}
          </span>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default TestAnalysisUpload;
