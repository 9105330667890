import React, { useState } from "react";
import { ChevronDown } from "react-feather";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";

const ProfileDropdown = (props) => {
  const { username, menuItems, avatar } = props;
  const tag = props?.tag || "div";
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={toggleDropdown}
      className="align-self-center profile-dropdown min-w170px"
      tag={tag}
    >
      <DropdownToggle
        data-toggle="dropdown"
        tag="a"
        className="nav-link dropdown-toggle nav-user mr-0 mr-0"
        onClick={toggleDropdown}
        aria-expanded={dropdownOpen}
        role="button"
      >
        <div className="media user-profile cursor-pointer d-flex gap-1">
          <div className="media-body text-left d-flex align-items-center">
            <h6 className="pro-user-name ml-2 my-0 d-flex gap-2 align-items-center">
              {avatar && (
                <img
                  src={avatar}
                  alt="avatar"
                  className="rounded-circle shadow-4-strong"
                />
              )}

              <span>{username}</span>
            </h6>
          </div>
          <ChevronDown
            className="ml-2 align-self-center icon-dropdown-profile color-gray-600"
            size={18}
          />
        </div>
      </DropdownToggle>
      <DropdownMenu
        end={true}
        className="topbar-dropdown-menu profile-dropdown-items"
      >
        <div onClick={toggleDropdown}>
          {menuItems.map((item, i) => {
            const Icon = item.icon;
            return (
              <div key={`${i}-profile-menu`}>
                {item.hasDivider ? <DropdownItem divider /> : null}
                {item.isText ? (
                  <div
                    className={`notify-item text-overflow m-l-3 ${
                      item.customClassName ? item.customClassName : ""
                    }`}
                  >
                    <p className="only-text p-l-24 p-r-24 active mb-0">
                      {item.label}
                    </p>
                    <span className="only-text p-l-24 p-r-24 mb-2 color-gray-900">
                      {item?.doctorID}
                    </span>
                    <DropdownItem divider />
                  </div>
                ) : (
                  item.redirectTo && (
                    <Link
                      to={item.redirectTo}
                      className={`dropdown-item notify-item ${
                        item.customClassName ? item.customClassName : ""
                      }`}
                      style={{ whiteSpace: "normal" }}
                      onClick={item.action}
                      role="presentation"
                    >
                      {Icon && (
                        <Icon
                          className={`icon-dual no-fill icon-xs mr-2 ${item.iconClass}`}
                        />
                      )}
                      <span>{item.label}</span>
                    </Link>
                  )
                )}
                {item.onClick && (
                  <div
                    role="button"
                    className={`dropdown-item notify-item cursor-pointer ${
                      item.customClassName ? item.customClassName : ""
                    }`}
                    onClick={item.onClick}
                  >
                    {Icon && (
                      <Icon
                        className={`icon-dual no-fill icon-xs mr-2 ${item.iconClass}`}
                      />
                    )}
                    <span>{item.label}</span>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default ProfileDropdown;
