import useDragAndDrop from "src/components/Common/DragAndDropTable/useDragAndDrop";
import { ExpandButton, AddMore, DeleteButton } from "src/components/Common/DragAndDropTable/TableActions";
import EditableInput from "src/components/Common/Form/EditableInput";
import { ROCKET_COMPONENT_LEVEL0, ROCKET_COMPONENTS } from "src/constant/common";
import Select from "react-select";

const StageRow = ({ indexList, data, handleSave, handleEndDrop, handleCollapse, handleAddMore }) => {
  const currentLevel = "stage";
  const ref = useDragAndDrop(currentLevel, indexList, handleEndDrop);

  return (
    <tr ref={ref} className="cursor-drag">
      <td className="width-by-px-50 sticky main left-0px border-none">
        <i className="bi bi-list f-s-20"></i>
      </td>
      <td className="width-by-px-150 sticky main main-input left-50px">
        <div className="d-flex align-items-center gap-1">
          {data?.children?.length > 0 && (
            <ExpandButton
              {...{
                level: currentLevel,
                index: indexList,
                isOpen: data.isOpen,
                handleCollapse,
              }}
            />
          )}
          <EditableInput
            {...{
              disabled: true,
              fieldName: `stage|name|${indexList}`,
              initialValue: data.name,
              handleSave,
            }}
          />
        </div>
        <AddMore className="width-by-px-180" {...{ level: "level0", index: indexList, handleAddMore }} addChild />
      </td>
      <td colSpan={3} className="width-by-px-540 sticky main left-150px"></td>
      <td className="position-relative">
        <EditableInput disabled initialValue={data?.mass} />
      </td>
      <td className="position-relative">
        <EditableInput disabled initialValue={data?.l_axis_position} />
      </td>
      <td className="position-relative">
        <EditableInput disabled initialValue={data?.length} />
      </td>
      <td className="position-relative">
        <EditableInput disabled initialValue={data?.center_of_gravity_x} />
      </td>
      <td className="position-relative">
        <EditableInput disabled initialValue={data?.center_of_gravity_y} />
      </td>
      <td className="position-relative">
        <EditableInput disabled initialValue={data?.center_of_gravity_z} />
      </td>
      <td className="position-relative">
        <EditableInput disabled initialValue={data?.outer_diameter} />
      </td>
      <td colSpan={8} className="position-relative"></td>
    </tr>
  );
};

const Level0Row = ({ indexList, data, handleSave, handleEndDrop, handleCollapse, handleAddMore, handleDelete, stage, currentStage }) => {
  const currentLevel = "level0";
  const ref = useDragAndDrop(currentLevel, indexList, handleEndDrop);

  return (
    <tr ref={ref} className="cursor-drag">
      <td className="width-by-px-50 sticky main left-0px border-none">
        <i className="bi bi-list f-s-20"></i>
      </td>
      <td className="sticky main left-50px"></td>
      <td className="width-by-px-150 sticky main main-input left-150px ">
        <div className="d-flex align-items-center gap-1">
          {data?.children?.length > 0 && (
            <ExpandButton
              {...{
                level: currentLevel,
                index: indexList,
                isOpen: data.isOpen,
                handleCollapse,
              }}
            />
          )}
          <Select
            className="w-100"
            options={ROCKET_COMPONENTS?.[stage]?.[currentStage] || ROCKET_COMPONENT_LEVEL0}
            value={{ value: data.name, label: data.name }}
            onChange={(newVal) => handleSave(`${currentLevel}|name|${indexList}`, newVal.value)}
            isSearchable={false}
            menuShouldScrollIntoView={false}
          />
          <AddMore {...{ level: currentLevel, index: indexList, handleAddMore }} />
          <AddMore className="width-by-px-130" {...{ level: "level1", index: indexList, handleAddMore }} addChild />
        </div>
      </td>
      <td colSpan={2} className="width-by-px-300 sticky main left-390px"></td>
      <td className="position-relative">
        <EditableInput disabled initialValue={data?.mass} />
      </td>
      <td className="position-relative">
        <EditableInput disabled initialValue={data?.l_axis_position} />
      </td>
      <td className="position-relative">
        <EditableInput disabled initialValue={data?.length} />
      </td>
      <td className="position-relative">
        <EditableInput disabled initialValue={data?.center_of_gravity_x} />
      </td>
      <td className="position-relative">
        <EditableInput disabled initialValue={data?.center_of_gravity_y} />
      </td>
      <td className="position-relative">
        <EditableInput disabled initialValue={data?.center_of_gravity_z} />
      </td>
      <td className="position-relative">
        <EditableInput disabled initialValue={data?.outer_diameter} />
      </td>
      <td colSpan={7} className="position-relative"></td>
      <td className="text-center">
        <DeleteButton {...{ level: currentLevel, index: indexList, handleDelete }} />
      </td>
    </tr>
  );
};

const Level1Row = ({ indexList, data, handleSave, handleEndDrop, handleCollapse, handleAddMore, handleDelete }) => {
  const currentLevel = "level1";
  const ref = useDragAndDrop(currentLevel, indexList, handleEndDrop);

  return (
    <tr ref={ref} className="cursor-drag">
      <td className="width-by-px-50 sticky main left-0px border-none">
        <i className="bi bi-list f-s-20"></i>
      </td>
      <td colSpan={2} className="sticky main left-50px "></td>
      <td className="width-by-px-150 sticky main main-input left-390px ">
        <div className="d-flex align-items-center gap-1">
          {data?.children?.length > 0 && (
            <ExpandButton
              {...{
                level: currentLevel,
                index: indexList,
                isOpen: data.isOpen,
                handleCollapse,
              }}
            />
          )}
          <EditableInput
            {...{
              fieldName: `${currentLevel}|name|${indexList}`,
              initialValue: data.name,
              handleSave,
            }}
          />
          <AddMore {...{ level: currentLevel, index: indexList, handleAddMore }} />
          <AddMore className="width-by-px-130" {...{ level: "component", index: indexList, handleAddMore }} addChild />
        </div>
      </td>
      <td className="width-by-px-150 sticky main left-540px "></td>
      <td className="position-relative">
        <EditableInput
          disabled
          {...{
            initialValue: data?.mass,
            handleSave,
          }}
        />
      </td>
      <td className="position-relative">
        <EditableInput disabled initialValue={data?.l_axis_position} />
      </td>
      <td className="position-relative">
        <EditableInput disabled initialValue={data?.length} />
      </td>
      <td className="position-relative">
        <EditableInput disabled initialValue={data?.center_of_gravity_x} />
      </td>
      <td className="position-relative">
        <EditableInput disabled initialValue={data?.center_of_gravity_y} />
      </td>
      <td className="position-relative">
        <EditableInput disabled initialValue={data?.center_of_gravity_z} />
      </td>
      <td className="position-relative">
        <EditableInput disabled initialValue={data?.outer_diameter} />
      </td>
      <td colSpan={7} className="position-relative"></td>
      <td className="text-center">
        <DeleteButton {...{ level: currentLevel, index: indexList, handleDelete }} />
      </td>
    </tr>
  );
};

const ComponentRow = ({ indexList, data, handleSave, handleEndDrop, handleAddMore, handleDelete, initComponentColumn }) => {
  const currentLevel = "component";
  const ref = useDragAndDrop(currentLevel, indexList, handleEndDrop);

  return (
    <tr ref={ref} className="cursor-drag">
      <td className="width-by-px-50 sticky main left-0px  border-none">
        <i className="bi bi-list f-s-20"></i>
      </td>
      <td colSpan={3} className="sticky main left-50px "></td>
      <td className="width-by-px-150 sticky main main-input left-540px ">
        <div className="d-flex align-items-center gap-1">
          <EditableInput
            {...{
              fieldName: `${currentLevel}|name|${indexList}`,
              initialValue: data.name,
              handleSave,
            }}
          />
          <AddMore {...{ level: currentLevel, index: indexList, handleAddMore }} />
        </div>
      </td>
      {Object.keys(initComponentColumn).map((key, index) => (
        <td key={index}>
          <EditableInput
            {...{
              fieldName: `${currentLevel}|${key}|${indexList}`,
              initialValue: data[key],
              handleSave,
              level: "component",
              type: "number",
            }}
          />
        </td>
      ))}
      <td className="text-center">
        <DeleteButton {...{ level: currentLevel, index: indexList, handleDelete }} />
      </td>
    </tr>
  );
};

export { StageRow, Level0Row, Level1Row, ComponentRow };
