import axios from "axios";
import api from "src/configs/api";
import { POST_PRESIGNED_URL, POST_PRE_SIGNED_UPLOAD } from "src/constant/endpoint";

const getPreSignedUrl = ({ data }) =>
  api.post(POST_PRESIGNED_URL, { data }).then((response) => response?.data?.data);

const getPreSignedUrlTestAnalysis = ({ data }) =>
  api.post(POST_PRE_SIGNED_UPLOAD, { data }).then((response) => response?.data?.data);

const uploadFilesTos3 = async ({ url, fields, file }) => {
  const formData = new FormData();

  Object.keys(fields).forEach((key) => {
    formData.append(key, fields[key]);
  });

  formData.append("file", file);

  return await axios
    .post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(() => fields.key);
};

export { getPreSignedUrl, uploadFilesTos3, getPreSignedUrlTestAnalysis };
