import classNames from "classnames";
import React, { useEffect } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Select, { components } from "react-select";
import { Label } from "reactstrap";

const FormSelect = ({
  label,
  name,
  placeholder,
  classInput,
  classGroup,
  options,
  control,
  defaultValue = null,
  errors,
  required,
  requiredMessage,
  onChangeExtra,
  indexMenu = 1051,
  disabled,
}) => {
  const { t } = useTranslation("common");

  const MenuList = (props) => {
    useEffect(() => {
      const currentOption = options?.find((c) => c.value === props.selectProps?.value?.value);
      const currentOptionIndex = props.options?.map((o) => o.value)?.indexOf(currentOption?.value);
      if (currentOptionIndex >= 0) {
        const option = props.children.find((child) => child.props && child.props.isSelected);
        const optionElement = document?.getElementById(option?.props?.innerProps?.id);

        if (optionElement) {
          optionElement?.scrollIntoView?.({ block: "nearest" });
        }
      }
    }, [props.selectProps?.value?.value]);
    return <components.MenuList {...props} />;
  };

  return (
    <div className={classNames("form-group position-relative", classGroup)}>
      {label && (
        <Label for={name} className={classNames("position-relative", { "required-input": required })}>
          {label}
        </Label>
      )}

      <Controller
        control={control}
        defaultValue={defaultValue}
        name={name}
        rules={{
          ...(required && { required: requiredMessage || t("required") }),
        }}
        render={({ field: { onChange, value, ref } }) => (
          <Select
            inputRef={ref}
            value={options?.find((c) => c.value === value) || ""}
            onChange={(val) => {
              onChange(val.value); // Updates react-hook-form
              if (onChangeExtra) onChangeExtra(val); // Additional handler for external logic
            }}
            isDisabled={disabled}
            options={options}
            placeholder={placeholder}
            className={classNames(classInput)}
            menuPortalTarget={document.body}
            components={{ MenuList }}
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                borderColor: errors ? "#ff5c75" : "#ced4da",
                paddingLeft: "calc(0.9rem - 8px)",
              }),
              placeholder: (defaultStyles) => ({
                ...defaultStyles,
                color: "#adb5bd",
              }),
              menuPortal: (base) => ({ ...base, zIndex: indexMenu }),
            }}
            isSearchable={false}
          />
        )}
      />

      {errors && <div className="text-danger font-12 position-absolute">{errors}</div>}
    </div>
  );
};

export default FormSelect;
