import classNames from "classnames";
import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { sidebarState } from "src/recoil/atoms/sidebar";
import { Outlet } from "react-router-dom";
import TopBar from "src/components/Common/Layout/TopBar";
import SidebarMenu from "src/components/Common/Layout/SidebarMenu";
import { unstable_useBlocker as useBlocker } from "react-router-dom";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useTranslation } from "react-i18next";
import { navigationPromptState } from "src/recoil/atoms/global";

const MainLayout = ({ classCustom = "" }) => {
  const [navigationPrompt, setNavigationPromptState] = useRecoilState(navigationPromptState);
  const [sidebar] = useRecoilState(sidebarState);
  const blocker = useBlocker(({ currentLocation, nextLocation }) => navigationPrompt && currentLocation.pathname !== nextLocation.pathname);
  const { t } = useTranslation(["common"]);

  // Handle show confirm modal when close tab and enter url
  const beforeunload = (e) => {
    if (navigationPrompt) {
      e.preventDefault();
      const message = "";
      e.returnValue = message;
      return message;
    }
  };
  useEffect(() => {
    window.addEventListener("beforeunload", beforeunload);
    return () => {
      window.removeEventListener("beforeunload", beforeunload);
    };
  }, [navigationPrompt]);

  return (
    <div style={{ display: "flex", height: "100%" }}>
      <SidebarMenu />
      <div id="wrapper">
        <TopBar />
        <div className={classNames("content-page py-3", classCustom, sidebar.collapsed ? "m-l-80" : "m-l-250")}>
          <div className="content">
            <Outlet />
          </div>
        </div>
      </div>

      <Modal isOpen={blocker.state === "blocked"} backdrop="static" centered size="sm" style={{ maxWidth: "400px" }}>
        <ModalHeader>{t("redirect_router_title")}</ModalHeader>
        <ModalBody>
          <div>{t("redirect_router_content")}</div>
          <div>{t("redirect_router_content_sub")}</div>
        </ModalBody>
        <ModalFooter className="justify-content-end p-t-10 p-b-20">
          <button className="btn btn-secondary btn-sm width-by-px-130" onClick={() => blocker.reset()}>
            {t("redirect_router_cancel")}
          </button>
          <button
            className="btn btn-danger btn-sm width-by-px-130"
            onClick={() => {
              setNavigationPromptState(false);
              blocker.proceed();
            }}
          >
            {t("redirect_router_submit")}
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default MainLayout;
