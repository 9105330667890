import axios from "axios";
import { REACT_APP_API_END_POINT } from "src/constant/environment";
import { ACCESS_TOKEN, getLocalStorage, removeLocalStorage } from "src/utils/storage";
import { STATUS_ERROR_CODE } from "src/constant/common";
import APP_ROUTES from "src/routes/routes";
import { setRecoil } from "recoil-nexus";
import { alertState } from "src/recoil/atoms/global";

const defaultOptions = {
  withCredentials: false,
};

const getBasicAuthen = () => {
  if (getLocalStorage(ACCESS_TOKEN)) {
    return {
      Authorization: `Bearer ${getLocalStorage(ACCESS_TOKEN)}`,
    };
  }

  return {};
};

const headerDefault = () => {
  return {
    "Content-Type": "application/json",
    Accept: "application/json",
    ...getBasicAuthen(),
  };
};

const API_URL = REACT_APP_API_END_POINT;

function getApi(path, option = {}, apiURL) {
  const response = axios.get(`${apiURL || API_URL}/${path.replace(/^\//, "")}`, {
    ...defaultOptions,
    ...option,
    headers: {
      ...headerDefault(),
      ...option?.headers,
    },
  });

  return response;
}

function postApi(path, option = {}) {
  const response = axios.post(`${API_URL}/${path.replace(/^\//, "")}`, option?.data, {
    ...defaultOptions,
    ...option,
    headers: {
      ...headerDefault(),
      ...option?.headers,
    },
  });

  return response;
}

function putApi(path, option = {}) {
  const response = axios.put(`${API_URL}/${path.replace(/^\//, "")}`, option?.data, {
    ...defaultOptions,
    ...option,
    headers: {
      ...headerDefault(),
      ...option.headers,
    },
  });

  return response;
}

function patchApi(path, option = {}) {
  const response = axios.patch(`${API_URL}/${path.replace(/^\//, "")}`, option?.data, {
    ...defaultOptions,
    ...option,
    headers: {
      ...headerDefault(),
      ...option.headers,
    },
  });

  return response;
}

function deleteApi(path, option = {}) {
  const response = axios.delete(`${API_URL}/${path.replace(/^\//, "")}`, {
    ...defaultOptions,
    ...option,
    headers: {
      ...headerDefault(),
      ...option.headers,
    },
  });

  return response;
}

const handleErrors = (serverResponse) => {
  if (serverResponse.status === STATUS_ERROR_CODE.NOT_AUTHORIZED) {
    removeLocalStorage(ACCESS_TOKEN);
    window.location.href = APP_ROUTES.LOGIN;
    return serverResponse;
  }
  return null;
};

axios.interceptors.response.use(
  (response) => {
    if (handleErrors(response)) return;
    return response;
  },
  (error) => {
    if (typeof error.response == "undefined") {
      setRecoil(alertState, () => ({
        status: true,
        message: "No Internet. Please check your connection and try again.",
        type: "danger",
      }));
      return Promise.reject(new Error("No Internet"));
    }
    handleErrors(error.response);
    return Promise.reject(error);
  }
);

const Api = {
  get: getApi,
  post: postApi,
  put: putApi,
  patch: patchApi,
  delete: deleteApi,
};

export default Api;
