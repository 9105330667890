import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Container, CardHeader, Collapse } from "reactstrap";
import useUrlState from "@ahooksjs/use-url-state";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  getSimulators,
  getSeriesInput,
  getContentFromLink,
  getMultipleOptDashboardLink,
  getMultipleDashboardLink,
} from "src/services/simulator-flight";
import { PAGINATION_DEFAULT, REFETCH_INTERVAL_TIME } from "src/constant/common";
import SearchInput from "src/components/Common/SearchInput";
import { getFileName, sortData, uniqArray, handleScrollTo, handleScrollToTop } from "src/utils/common";
import {
  PROCESS_STATUS,
  SIMULATION_RESULT,
  DASHBOARD_STATUS,
  RETRY_TIME,
  SIMULATION_TYPE,
  ERROR_CODE_BY_TYPE,
  SUB_TEXT_BY_ERROR_TYPE,
} from "src/constant/common";
import APP_ROUTES from "src/routes/routes";
import { useMutation, useQuery } from "src/Hooks/useReactQuery";
import LabelStatus from "src/components/Common/LabelStatus";
import SimulationDashboard from "src/components/Common/Dropdown/SimulationDashboard";
import LoadingWrapper from "src/components/Common/Table/Loading";
import Simulation6DofDashboard from "src/components/Common/Dropdown/Simulation6DofDashboard";
import ReactPaginate from "react-paginate";
import { useSetRecoilState } from "recoil";
import { alertState } from "src/recoil/atoms/global";
import ModalSerieFilesView from "src/components/Common/Modal/ModalSerieFilesView";
import { ButtonCollapse, SingleDashboard, ButtonDownload, ButtonCopy, ButtonRetry } from "src/components/Common/Button";
import ThreeDofTable from "src/components/Common/Table/ThreeDofTable";
import SixDofTable from "src/components/Common/Table/SixDofTable";
import ShowDataCell from "src/components/Common/DataCell";

const FlightSimulatorList = () => {
  const { t } = useTranslation(["flight-simulator", "common"]);

  const showAlert = useSetRecoilState(alertState);

  const [params, setParams] = useUrlState(
    {},
    {
      navigateMode: "replace",
      stringifyOptions: {
        skipNull: true,
        skipEmptyString: true,
      },
    }
  );

  const [textSearch, setTextSearch] = useState(params?.condition || null);

  const [seriesById, setSeriesById] = useState();
  const [isLoadingSimulator, setIsLoadingSimulator] = useState(true);
  const [simulationIdsOpen, setSimulationIdsOpen] = useState([]);
  const [simulationIsUpdated, setSimulationIsUpdated] = useState([]);
  const [dashboardLoading, setDashboardLoading] = useState({});
  const [allowScrollToId, setAllowScrollToId] = useState(true);
  const [dataModalFileView, setDataModalFileView] = useState({ isOpen: false, serieData: {}, fileData: {} });

  const { data: dataSimulator, refetch } = useQuery(
    ["getSimulators"],
    () =>
      getSimulators({
        page: params.page || 1,
        limit: PAGINATION_DEFAULT.pageSize,
        q: params?.condition,
      }),
    {
      onSuccess: (data) => {
        if (params?.id && allowScrollToId) {
          handleScrollTo(0, 90, `row${params.id}`);
          setAllowScrollToId(false);
        }
        for (const simulationItem of data.items) {
          if (
            simulationIdsOpen.some((id) => id === simulationItem.id) &&
            simulationItem.status === PROCESS_STATUS.FAILED &&
            !simulationIsUpdated.some((id) => id === simulationItem.id)
          ) {
            getSeriesDetail(simulationItem);
            setSimulationIsUpdated((oldVal) => [...oldVal, simulationItem.id]);
          }
        }
      },
      onSettled: () => {
        setIsLoadingSimulator(false);
      },
      refetchInterval: REFETCH_INTERVAL_TIME,
    }
  );
  const { mutate: getSeriesData } = useMutation(
    {
      mutationKey: (id) => `getSeries${id}`,
      mutationFn: (id) => getSeriesInput(id),
    },
    true
  );

  const columnsSeriesTable = [
    {
      dataField: "param_filename",
      text: "param_filename",
    },
    {
      dataField: "series",
      text: "series",
    },
  ];

  const convertDataSeries = (data) => {
    let columnsName = [];
    const dataSeries = data.map((series) => {
      const seriesItem = series.jsonObject?.default || {};
      const { param_filename, serie_file_name, serie_name, jsonObject } = series;
      let columnsData = {};

      Object.keys(seriesItem).forEach((field) => {
        columnsData = {
          ...columnsData,
          [field]:
            Array.isArray(seriesItem[field]?.value) && seriesItem[field]?.value?.length > 0
              ? [...seriesItem[field]?.value].join(", ")
              : seriesItem[field]?.value,
        };

        columnsName.push({
          dataField: `${field}`,
          text: `${field} ${seriesItem[field]?.unit ? `(${seriesItem[field]?.unit})` : ""}`,
        });
      });

      return {
        ...series,
        paramList: { param_filename, series: serie_name, ...columnsData },
        name: serie_file_name,
        fileContent: jsonObject,
        errorList: series?.errors?.length ? getErrorDetail(series.errors, serie_file_name) : [],
      };
    });

    return {
      dataSeries: sortData(sortData(dataSeries, "param_filename"), "serie_name"),
      columnSeries: uniqArray([...columnsSeriesTable, ...uniqArray(columnsName)]),
    };
  };

  const getSeriesDetail = (simulationData) => {
    const { id, type } = simulationData;
    getSeriesData(id, {
      onSuccess: async (data) => {
        if (type === SIMULATION_TYPE["6DOF"]) {
          await setSerieDataFor6DoFType(simulationData, data);
          return;
        }
        const json = [];
        let countError = 0;
        await Promise.all(
          [...data].map((i) =>
            getContentFromLink(i?.link)
              .then((res) => {
                json.push({
                  ...i,
                  param_filename: getFileName(i?.serie_file_name),
                  jsonObject: res.data,
                });
              })
              .catch((error) => {
                countError += 1;
                showAlert({
                  status: true,
                  message: t("file_error"),
                  type: "danger",
                });
              })
          )
        );
        if (countError === data.length) {
          setSeriesById((oldVal) => ({
            ...oldVal,
            [id]: {
              data: oldVal?.[id]?.data || [],
              columns: oldVal?.[id]?.columns || [],
              loading: false,
              error: !!!oldVal?.[id]?.data?.length,
            },
          }));
          return;
        }
        const seriesData = convertDataSeries(json);
        setSeriesById((oldVal) => ({
          ...oldVal,
          [id]: {
            data: seriesData.dataSeries,
            columns: seriesData.columnSeries,
            loading: false,
            error: false,
          },
        }));
      },
      onError: () => {
        setSeriesById((oldVal) => ({
          ...oldVal,
          [id]: {
            data: oldVal?.[id]?.data || [],
            columns: oldVal?.[id]?.columns || [],
            loading: false,
            error: !!!oldVal?.[id]?.data?.length,
          },
        }));
      },
    });
  };

  const onClickCollapsed = (row) => {
    const id = row?.id;
    if (simulationIdsOpen.some((id) => id === row.id)) {
      setSimulationIdsOpen((oldVal) => [...oldVal.filter((id) => id !== row.id)]);
      return;
    }
    setSimulationIdsOpen((oldVal) => [...oldVal, row.id]);
    setSeriesById((oldVal) => ({
      ...oldVal,
      [id]: {
        ...oldVal?.[id],
        loading: true,
      },
    }));
    getSeriesDetail(row);
  };

  const getFieldFromErrorData = (errFields = []) => {
    let fields = errFields.filter((item) => !String(item).includes("list[") && !String(item).includes("literal["));
    if (fields.some((field) => Number.isInteger(field))) {
      fields = fields.map((field) => (Number.isInteger(field) ? `[${field}]` : field)).join(".");
      fields = fields.replace(/\.\[/g, "[");
    } else {
      fields = fields.join(".");
    }
    return fields;
  };

  const getErrorDetail = (errList, fileName) => {
    if (!errList?.length) {
      return [];
    }
    let outputList = [];
    const groupByErrorCode = {};
    for (const errItem of errList) {
      const errCode = ERROR_CODE_BY_TYPE?.[errItem.type] || "FE-0005";
      groupByErrorCode[errCode] = groupByErrorCode?.[errCode] || { [errItem.type]: [] };
      groupByErrorCode[errCode][errItem.type] = groupByErrorCode?.[errCode]?.[errItem.type] || [];
      groupByErrorCode[errCode][errItem.type].push(errItem);
    }

    for (const errCode in groupByErrorCode) {
      let errMsgItem = "• " + t(`error.${errCode}-title`);
      const errGroup = groupByErrorCode[errCode];

      switch (errCode) {
        case "FE-0007":
          for (const type in errGroup) {
            for (const errItem of errGroup[type]) {
              const fieldName1 = getFieldFromErrorData(errItem?.fields?.[0]);
              const fieldName2 = getFieldFromErrorData(errItem?.fields?.[1]);
              errMsgItem += t(`error.${errCode}-content`, {
                field_name_1: fieldName1,
                field_name_2: fieldName2,
                file_name: getFileName(fileName),
              });
            }
          }
          break;
        default:
          for (const type in errGroup) {
            const fields = [...new Set(errGroup[type].map((item) => getFieldFromErrorData(item.loc)))].join(", ");
            const descMsg = errGroup[type][0]?.msg || "";
            const min = errGroup[type][0]?.ctx?.min_length;
            const max = errGroup[type][0]?.ctx?.max_length;
            const limitVal = min ? `least \`${min}\`` : max ? `most \`${max}\`` : "least `1`";
            const subText = SUB_TEXT_BY_ERROR_TYPE[type] || "";
            errMsgItem += t(`error.${errCode}-content`, {
              field_name: fields,
              file_name: getFileName(fileName),
              detail_msg: descMsg,
              limit_value: limitVal,
              sub_text: subText,
            });
          }
          break;
      }
      outputList.push(errMsgItem);
    }

    return outputList;
  };

  const setSerieDataFor6DoFType = async (simulationItem, serieData) => {
    let filesContent = [];
    let fileResponseContent = "";
    const fileList = serieData?.params || serieData?.inputs;
    await Promise.all(
      [...fileList].map((item, index) =>
        getContentFromLink(item?.link)
          .then((res) => {
            if (item?.errors?.length) {
              item.errorList = getErrorDetail(item.errors, item.name);
            }
            item.fileContent = res.data;
            filesContent[index] = item;
          })
          .catch((error) => {
            showAlert({
              status: true,
              message: t("file_error"),
              type: "danger",
            });
          })
      )
    );
    if (serieData?.response) {
      await getContentFromLink(serieData?.response?.link)
        .then((res) => {
          fileResponseContent = res.data;
        })
        .catch((error) => {
          showAlert({
            status: true,
            message: t("file_error"),
            type: "danger",
          });
        });
    }

    setSeriesById({
      ...seriesById,
      [simulationItem.id]: {
        data: filesContent,
        fileResponse: fileResponseContent,
        fileResponseLink: serieData?.response?.link,
        fileResponseName: serieData?.response?.name,
        loading: false,
        error: false,
      },
    });
  };

  const onChangePage = (value) => {
    setParams({
      ...params,
      page: value,
    });
    handleScrollToTop();
  };

  const checkMultipleOptDashboardUrl = async (simulationData, params, retryCount) => {
    try {
      const res = await getMultipleOptDashboardLink(params);
      if (res.data.status === DASHBOARD_STATUS.INPROGRESS) {
        if (retryCount === RETRY_TIME) {
          showAlert({
            status: true,
            message: t("over_time"),
            type: "danger",
          });
          setDashboardLoading((oldVal) => ({ ...oldVal, [simulationData.id]: false }));
          return;
        }
        await new Promise((resolve) => setTimeout(resolve, 3000));
        retryCount += 1;
        await checkMultipleOptDashboardUrl(simulationData, params, retryCount);
        return;
      }
      simulationData.opt_dashboard_url = res.data.url;
      simulationData.opt_dashboard_status = res.data.status;
      setDashboardLoading((oldVal) => ({ ...oldVal, [simulationData.id]: false }));
      if (res.data.status === DASHBOARD_STATUS.SUCCEEDED) {
        window.open(res.data.url, "_blank");
      }
    } catch (e) {
      setDashboardLoading((oldVal) => ({ ...oldVal, [simulationData.id]: false }));
      showAlert({
        status: true,
        message: e?.response?.data?.error?.message || e?.response?.data?.message,
        type: "danger",
      });
    }
  };

  const checkMultipleDashboardUrl = async (simulationData, params, retryCount) => {
    try {
      const res = await getMultipleDashboardLink(params);
      if (res.data.status === DASHBOARD_STATUS.INPROGRESS) {
        if (retryCount === RETRY_TIME) {
          showAlert({
            status: true,
            message: t("over_time"),
            type: "danger",
          });
          setDashboardLoading((oldVal) => ({ ...oldVal, [simulationData.id]: false }));
          return;
        }
        await new Promise((resolve) => setTimeout(resolve, 3000));
        retryCount += 1;
        await checkMultipleDashboardUrl(simulationData, params, retryCount);
        return;
      }
      simulationData.multi_dashboard_url = res.data.url;
      simulationData.multi_dashboard_status = res.data.status;
      setDashboardLoading((oldVal) => ({ ...oldVal, [simulationData.id]: false }));
      if (res.data.status === DASHBOARD_STATUS.SUCCEEDED) {
        window.open(res.data.url, "_blank");
      }
    } catch (e) {
      setDashboardLoading((oldVal) => ({ ...oldVal, [simulationData.id]: false }));
      showAlert({
        status: true,
        message: e?.response?.data?.error?.message || e?.response?.data?.message,
        type: "danger",
      });
    }
  };

  const handleClickMultipleOptDashboard = async (simulationData) => {
    if (simulationData.opt_dashboard_status === DASHBOARD_STATUS.SUCCEEDED && simulationData.opt_dashboard_url) {
      window.open(simulationData.opt_dashboard_url, "_blank");
      return;
    }
    setDashboardLoading({ ...dashboardLoading, [simulationData.id]: true });
    const data = {
      id: simulationData.id,
    };
    await checkMultipleOptDashboardUrl(simulationData, data, 1);
  };

  const handleClickMultipleDashboard = async (simulationData) => {
    if (simulationData.multi_dashboard_status === DASHBOARD_STATUS.SUCCEEDED && simulationData.multi_dashboard_url) {
      window.open(simulationData.multi_dashboard_url, "_blank");
      return;
    }
    setDashboardLoading({ ...dashboardLoading, [simulationData.id]: true });
    const data = {
      id: simulationData.id,
    };
    await checkMultipleDashboardUrl(simulationData, data, 1);
  };

  const onKeyDownInput = (e) => {
    if (e.key === "Enter") {
      onSubmitSearch(e.target.value);
    }
  };

  const onSubmitSearch = (textSearch) => {
    if (params?.condition === textSearch && !isLoadingSimulator) {
      setIsLoadingSimulator(true);
      refetch();
    }
    setParams({
      page: 1,
      condition: textSearch,
    });
  };

  const getCurrentPage = () => {
    return (params?.page || PAGINATION_DEFAULT.page) - 1;
  };

  const isShowMultiDashboard3Dof = (data) =>
    data.status === PROCESS_STATUS.SUCCEEDED &&
    (data.execution_file_name === SIMULATION_RESULT.MULTI || data.execution_file_name === SIMULATION_RESULT.PAYLOAD) &&
    data.type === SIMULATION_TYPE["3DOF"];
  const isShowMultiDashboard6Dof = (data) =>
    data.status === PROCESS_STATUS.SUCCEEDED &&
    data.execution_file_name === SIMULATION_RESULT.SIX_DOF &&
    data.type === SIMULATION_TYPE["6DOF"];
  const isShowSingleDashboard3Dof = (data) =>
    data.status === PROCESS_STATUS.SUCCEEDED &&
    data.execution_file_name === SIMULATION_RESULT.SINGLE &&
    data.type === SIMULATION_TYPE["3DOF"];

  useEffect(() => {
    setIsLoadingSimulator(true);
    refetch();
  }, [params]);

  return (
    <Container fluid>
      <Row>
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-2">
                <h2 className="header-title mb-0">{t("list.title")}</h2>
                <SearchInput
                  submit={onSubmitSearch}
                  setTextSearch={setTextSearch}
                  onKeyDownInput={onKeyDownInput}
                  textSearch={textSearch}
                  placeholder={t("list.search_placeholder")}
                />
              </div>
              <Row>
                <Col md={12}>
                  <Card>
                    <div className="d-flex justify-content-end">
                      <CardHeader className="bg-white border-0">
                        <Link to={APP_ROUTES.FLIGHT_NEW} className="btn btn-primary text-capitalize">
                          {t("list.redirect_new")}
                        </Link>
                      </CardHeader>
                    </div>
                    <CardBody className="bg-white">
                      <LoadingWrapper loading={isLoadingSimulator}>
                        <div className="react-bootstrap-table overflow-auto simulation-table">
                          <table className="table striped table-collapse table-fixed">
                            <thead>
                              <tr>
                                <th className="width-by-px-120">{t("list.table.id")}</th>
                                <th>{t("list.table.name")}</th>
                                <th className="width-by-px-130">{t("list.table.status")}</th>
                                <th className="width-by-px-80">{t("list.table.param")}</th>
                                <th className="width-by-px-80">{t("list.table.type")}</th>
                                <th>{t("list.table.version")}</th>
                                <th className="width-by-px-160">{t("list.table.execution_file")}</th>
                                <th>{t("list.table.user")}</th>
                                <th className="width-by-px-110">{t("list.table.update_at")}</th>
                                <th>{t("list.table.note")}</th>
                                <th className="width-by-px-60"></th>
                                <th className="width-by-px-60"></th>
                                <th className="width-by-px-60"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {dataSimulator?.items?.length > 0 ? (
                                dataSimulator.items.map((row, indexRow) => {
                                  return [
                                    <tr key={indexRow} id={`row${row?.id}`}>
                                      <td>{row.id}</td>
                                      <td>{row.name}</td>
                                      <td>
                                        <LabelStatus
                                          row={row}
                                          errorList={getErrorDetail(row?.validate_error?.errors, row?.validate_error?.name)}
                                        />
                                      </td>
                                      <td>
                                        <ButtonCollapse
                                          handleCollapse={() => onClickCollapsed(row)}
                                          isCollapsed={!simulationIdsOpen.includes(row.id)}
                                        />
                                      </td>
                                      <td>{row.type}</td>
                                      <td>{row.version_name}</td>
                                      <td>{row.execution_file_name}</td>
                                      <td>{row.updated_by}</td>
                                      <td>{row.updated_at}</td>
                                      <td>
                                        <ShowDataCell data={row.note} />
                                      </td>
                                      <td className="text-center">
                                        <SimulationDashboard
                                          isShow={isShowMultiDashboard3Dof(row)}
                                          simulationData={row}
                                          onClickMultiParam={() => handleClickMultipleDashboard(row)}
                                          onClickMultiOpt={() => handleClickMultipleOptDashboard(row)}
                                          isLoading={dashboardLoading?.[row.id]}
                                        />
                                        <SingleDashboard
                                          id={row.id}
                                          isShow={isShowSingleDashboard3Dof(row)}
                                          isLoading={dashboardLoading?.[row.id]}
                                          handleClick={() => handleClickMultipleDashboard(row)}
                                          isReady={!!row.multi_dashboard_url}
                                        />
                                        <Simulation6DofDashboard
                                          isShow={isShowMultiDashboard6Dof(row)}
                                          simulationData={row}
                                          updateSimulation={refetch}
                                        />
                                      </td>
                                      <td className="text-center">
                                        <ButtonDownload url={row.result_link} id={row.id} />
                                      </td>
                                      <td className="text-center">
                                        <ButtonCopy
                                          id={row.id}
                                          isShow={row.status === PROCESS_STATUS.SUCCEEDED}
                                          toUrl={`/flight/${row?.id}?action=copy${params.page ? "&page=" + params.page : ""}${
                                            params?.condition ? "&condition=" + params.condition : ""
                                          }`}
                                        />
                                        <ButtonRetry
                                          id={row.id}
                                          isShow={row.status === PROCESS_STATUS.FAILED}
                                          toUrl={`/flight/${row?.id}?action=retry${params.page ? "&page=" + params.page : ""}${
                                            params?.condition ? "&condition=" + params.condition : ""
                                          }`}
                                        />
                                      </td>
                                    </tr>,
                                    <tr className="collapse-row" key={`sub-row-${indexRow}`}>
                                      <td colSpan={13} className="hiddenRow">
                                        <Collapse isOpen={simulationIdsOpen.includes(row.id)}>
                                          <ThreeDofTable
                                            isShow={row.type === SIMULATION_TYPE["3DOF"]}
                                            simulationData={row}
                                            isError={seriesById?.[row?.id]?.error}
                                            isLoading={seriesById?.[row?.id]?.loading}
                                            serieData={seriesById?.[row?.id]}
                                            handleViewFile={setDataModalFileView}
                                            updateData={setSeriesById}
                                          />
                                          <SixDofTable
                                            isShow={row.type === SIMULATION_TYPE["6DOF"]}
                                            simulationData={row}
                                            isError={seriesById?.[row?.id]?.error}
                                            isLoading={seriesById?.[row?.id]?.loading}
                                            serieData={seriesById?.[row?.id]}
                                            handleViewFile={setDataModalFileView}
                                            updateData={setSeriesById}
                                          />
                                        </Collapse>
                                      </td>
                                    </tr>,
                                  ];
                                })
                              ) : (
                                <tr>
                                  <td className="text-center" colSpan="13">
                                    {!isLoadingSimulator && <span>{t("no_data", { ns: "common" })}</span>}
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                          <ModalSerieFilesView
                            {...dataModalFileView}
                            onClose={() => setDataModalFileView((oldVal) => ({ ...oldVal, isOpen: false }))}
                          />
                        </div>
                        {dataSimulator?.pagination?.total_page > 1 && (
                          <div className="pagination-custom">
                            <div className="fw-bold">
                              {dataSimulator?.pagination?.total > 0 &&
                                t("pagination", {
                                  first: getCurrentPage() * 50 + 1,
                                  last:
                                    dataSimulator?.pagination?.total < (getCurrentPage() + 1) * 50
                                      ? dataSimulator?.pagination?.total
                                      : (getCurrentPage() + 1) * 50,
                                  total: dataSimulator?.pagination?.total,
                                })}
                            </div>
                            <ReactPaginate
                              nextLabel={">"}
                              onPageChange={(value) => {
                                onChangePage(value.selected + 1);
                              }}
                              {...{
                                pageRangeDisplayed: 5,
                                marginPagesDisplayed: 2,
                                pageCount: dataSimulator?.pagination?.total_page,
                                forcePage: getCurrentPage(),
                              }}
                              previousLabel={"<"}
                              pageClassName="page-item"
                              pageLinkClassName="page-link"
                              previousClassName="page-item"
                              previousLinkClassName="page-link"
                              nextClassName="page-item"
                              nextLinkClassName="page-link"
                              breakLabel={"..."}
                              breakClassName="page-item"
                              breakLinkClassName="page-link"
                              containerClassName="pagination"
                              activeClassName="active"
                              renderOnZeroPageCount={null}
                            />
                          </div>
                        )}
                      </LoadingWrapper>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Row>
    </Container>
  );
};

export default FlightSimulatorList;
