import React from "react";
import { Spinner } from "reactstrap";

const Loading = () => (
  <div className="loading-page">
    <div className="spin spin-spinning">
      <div className="spin-dot spin-dot-spin">
        <Spinner />
      </div>
    </div>
  </div>
);

export default Loading;
