import React, { useState } from "react";
import { 
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Spinner,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import { DASHBOARD_STATUS, RETRY_TIME, DASHBOARD_TYPE } from "src/constant/common";
import { useSetRecoilState } from 'recoil';
import { alertState } from 'src/recoil/atoms/global';
import { get6DofDashboardLink } from "src/services/simulator-flight";

const Simulation6DofDashboard = ({simulationData, isShow = true, updateSimulation }) => {
  const { t } = useTranslation("flight-simulator");
  const showAlert = useSetRecoilState(alertState);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleClickRenderDashboard = async (type) => {
    const dashboardByType = simulationData.simulator_dashboards.filter(item => item.dashboard_type === type)?.[0];
    if (dashboardByType?.dashboard_status === DASHBOARD_STATUS.SUCCEEDED && dashboardByType?.dashboard_url) {
      window.open(dashboardByType.dashboard_url, '_blank');
      return;
    }
    setIsLoading(true);
    const data = {
      id: simulationData.id,
      dashboard_input_type: 'multiple',
      dashboard_type: type,
    }
    await checkDashboardStatus(data, 1, type);
  };  

  const checkDashboardStatus = async (params, retryCount, type) => {
    try {
      const res = await get6DofDashboardLink(params);
      if (await handleInProgressStatus(res, params, retryCount, type)) return;
      setIsLoading(false);
      updateSimulation();
      if (res.data.dashboard_status === DASHBOARD_STATUS.SUCCEEDED) {
        window.open(res.data.dashboard_url, '_blank');
      }
    } catch (e) {
      setIsLoading(false);
      showAlert({
        status: true,   
        message: e?.response?.data?.error?.message || e?.response?.data?.message, 
        type: 'danger',
      });
    }
  }

  const handleInProgressStatus = async (res, params, retryCount, type) => {
    if (res.data.dashboard_status !== DASHBOARD_STATUS.INPROGRESS) return false;
    if (retryCount === RETRY_TIME) {
      showAlert({ status: true, message: t("over_time"), type: 'danger' });
      setIsLoading(false);
      return true;
    }
    await new Promise(resolve => setTimeout(resolve, 3000));
    await checkDashboardStatus(params, retryCount + 1, type);
    return true;
  }

  const isDashboardReady = (type) => {
    return !!simulationData.simulator_dashboards.filter(item => item.dashboard_type === type)?.[0]?.dashboard_url;
  }
  
  return (
    isShow ? (
      isLoading ? (
        <Spinner size="sm" color="secondary" />
      ) : (
        <>
          <Dropdown
            isOpen={dropdownOpen}
            toggle={toggleDropdown}
            className="align-self-center"
            data-tooltip-id={`dashboard-${simulationData?.id}`}
            data-tooltip-content={t("list.table.dashboard")}
          >
            <DropdownToggle
              data-toggle="dropdown"
              tag="button"
              className="border-0 bg-transparent"
            >
              <i className={`bi bi-speedometer2 ${simulationData.simulator_dashboards.length > 1 ? 'text-success' : 'text-dark'} f-s-20`}/>
            </DropdownToggle>
            
            <DropdownMenu
              end={true}
              className="topbar-dropdown-menu profile-dropdown-items"
            >
              <div onClick={toggleDropdown}>
                <div
                  role="button"
                  className={`dropdown-item ${isDashboardReady(DASHBOARD_TYPE.ASCENT_6DOF) ? 'text-success' : 'text-dark'}`}
                  onClick={() => handleClickRenderDashboard(DASHBOARD_TYPE.ASCENT_6DOF)}
                >
                  <span>{t(`list.table.ascending_multi_dashboard`)}</span>
                </div>
                <div
                  role="button"
                  className={`dropdown-item ${isDashboardReady(DASHBOARD_TYPE.DESCENT_6DOF) ? 'text-success' : 'text-dark'}`}
                  onClick={() => handleClickRenderDashboard(DASHBOARD_TYPE.DESCENT_6DOF)}
                >
                  <span>{t(`list.table.descending_multi_dashboard`)}</span>
                </div>
              </div>
            </DropdownMenu>
          </Dropdown>
          <Tooltip id={`dashboard-${simulationData?.id}`} className="text-break-space" />
        </>
      )
    ) : null
  );
};

export default Simulation6DofDashboard;
