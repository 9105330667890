import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Collapse, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import CsvToTable from "src/components/Common/CsvToTable";
import "react-json-view-lite/dist/index.css";
import { isCSVContent } from "src/utils/common";

const ModelDetail = ({ modelDetail, data }) => {
  const { t } = useTranslation(["rocket-model", "common"]);
  const [showContent, setShowContent] = useState(true);
  const [isOpenDownloadDD, setIsOpenDownloadDD] = useState(false);

  return (
    <div className="card">
      <div className="card-body">
        <div className="d-flex justify-content-between align-items-center">
          <button className="btn btn-blank text-dark border-0 p-0 header-title" onClick={() => setShowContent(!showContent)}>
            {t("detail.title")} <i className={showContent ? "bi bi-caret-up-fill" : "bi bi-caret-down-fill"}></i>
          </button>
          <Dropdown isOpen={isOpenDownloadDD} toggle={() => setIsOpenDownloadDD(!isOpenDownloadDD)}>
            <DropdownToggle color="primary" disabled={!!!data?.csvUrl && !!!data?.jsonUrl}>
              {t("detail.download")} <i className="bi bi-caret-down-fill"></i>
            </DropdownToggle>
            <DropdownMenu className="topbar-dropdown-menu profile-dropdown-items">
              <DropdownItem
                disabled={!!!data?.csvUrl}
                onClick={(e) => {
                  e.preventDefault();
                  const link = document.createElement("a");
                  link.href = data?.csvUrl;
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                }}
              >
                {t("detail.download_csv")}
              </DropdownItem>
              <DropdownItem
                disabled={!!!data?.jsonUrl}
                onClick={(e) => {
                  e.preventDefault();
                  const link = document.createElement("a");
                  link.href = data?.jsonUrl;
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                }}
              >
                {t("detail.download_json")}
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>

      <Collapse isOpen={showContent}>
        <div className="react-bootstrap-table overflow-auto mx-3 max-height-by-px-500 mb-3 simulation-table table-detail table-nowrap">
          {isCSVContent(modelDetail) ? <CsvToTable csvData={modelDetail} /> : <div className="text-center p-2">File error</div>}
        </div>
      </Collapse>
    </div>
  );
};

export default ModelDetail;
