import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { StageRow, Level0Row, Level1Row, ComponentRow } from "src/components/Common/DragAndDropTable/TableRows";

const DragAndDropTable = ({
  isFixed,
  data,
  handleSave,
  handleEndDrop,
  handleCollapse,
  handleAddMore,
  initComponentColumn,
  handleDelete,
  totalStage,
}) => {
  const { t } = useTranslation(["rocket-model", "common"]);
  const tableRef = useRef(null);
  const fixedScrollLeft = 754;

  useEffect(() => {
    const elements = document.querySelectorAll(".cursor-drag");

    elements.forEach((element, index) => {
      const zIndexBase = 5 + (elements.length - index);

      element.querySelectorAll(".main").forEach((child) => {
        // Remove old z-i- classes
        child.classList.forEach((className) => {
          if (className.startsWith("z-i-")) {
            child.classList.remove(className);
          }
        });

        // Add new z-i class
        const newClass = `z-i-${zIndexBase}-i`;
        child.classList.add(newClass);

        // Handle main-input class specifics
        if (child.classList.contains("main-input")) {
          child.classList.remove(newClass);
          child.classList.add(`z-i-${zIndexBase + 1}-i`);
        }
      });
    });
  }, [data]);

  const handleKeyDown = (event) => {
    const inputs = tableRef.current.querySelectorAll("input");
    const tableContainer = tableRef.current.closest(".table-container");

    if (event.target.matches("table input") && event.shiftKey && event.key === "Tab") {
      event.preventDefault();

      const index = Array.prototype.indexOf.call(inputs, event.target);
      let nextIndex = index - 1;

      while (nextIndex !== index) {
        if (nextIndex < 0) {
          nextIndex = inputs.length - 1; // Cycle back to the first input
        }
        const nextInput = inputs[nextIndex];
        if (!nextInput.disabled) {
          nextInput.focus();
          nextInput?.setSelectionRange(0, nextInput.value.length);

          if (nextInput?.name !== "component") return;
          // Checking overlap primarily on the left side
          const containerRect = tableContainer.getBoundingClientRect();
          const nextInputRect = nextInput.getBoundingClientRect();
          const nextInputLeft = nextInputRect.left - containerRect.left + tableContainer.scrollLeft;

          // If nextInput is overlapping from left beyond container's left boundary, scroll to make it visible
          if (nextInputLeft - fixedScrollLeft < tableContainer.scrollLeft) {
            // Scroll to position the next input just after the left boundary
            tableContainer.scrollTo({
              left: nextInputLeft - fixedScrollLeft,
              behavior: "instant",
            });
          }

          break;
        }
        nextIndex--;
      }

      return;
    }
    if (event.target.matches("table input") && event.key === "Tab") {
      event.preventDefault();

      const index = Array.prototype.indexOf.call(inputs, event.target);
      let nextIndex = index + 1;

      while (nextIndex !== index) {
        if (nextIndex >= inputs.length) {
          nextIndex = 0; // Cycle back to the first input
        }
        const nextInput = inputs[nextIndex];
        if (!nextInput.disabled) {
          nextInput.focus();
          nextInput?.setSelectionRange(0, nextInput.value.length);
          if (nextInput?.name !== "component") return;
          // Checking overlap primarily on the left side
          const containerRect = tableContainer.getBoundingClientRect();
          const nextInputRect = nextInput.getBoundingClientRect();
          const nextInputLeft = nextInputRect.left - containerRect.left + tableContainer.scrollLeft;

          // If nextInput is overlapping from left beyond container's left boundary, scroll to make it visible
          if (nextInputLeft - fixedScrollLeft < tableContainer.scrollLeft) {
            // Scroll to position the next input just after the left boundary
            tableContainer.scrollTo({
              left: nextInputLeft - fixedScrollLeft,
              behavior: "instant",
            });
          }

          break;
        }
        nextIndex++;
      }
    }
  };
  return (
    <div className="react-bootstrap-table mx-3 mb-3 pb-3 table-responsive max-height-by-px-700 position-relative hidden-scroll table-container">
      <div className="position-sticky border-left top-0 l-0 table-drag"></div>
      <table
        className="table table-hover table-fixed table-bordered table-header-sticky position-relative mb-0 border-table-left"
        ref={tableRef}
        onKeyDown={handleKeyDown}
      >
        <thead>
          <tr>
            <th className="width-by-px-50 sticky left-0px"></th>
            <th className="width-by-px-100 sticky left-50px">{t("detail.stage_label")}</th>
            <th className="width-by-px-240 sticky left-150px">{t("detail.level_0")}</th>
            <th className="width-by-px-150 sticky left-390px">{t("detail.level_1")}</th>
            <th className="width-by-px-200 sticky left-540px">{t("detail.component")}</th>
            <th className="width-by-px-200">{t("detail.mass")}</th>
            <th className="width-by-px-200">{t("detail.l_axis")}</th>
            <th className="width-by-px-200">{t("detail.length")}</th>
            <th className="width-by-px-200">{t("detail.center_of_gravity_x")}</th>
            <th className="width-by-px-200">{t("detail.center_of_gravity_y")}</th>
            <th className="width-by-px-200">{t("detail.center_of_gravity_z")}</th>
            <th className="width-by-px-200">{t("detail.outer_diameter")}</th>
            <th className="width-by-px-200">{t("detail.inner_diameter")}</th>
            <th className="width-by-px-240">{t("detail.moment_of_inertia_x")}</th>
            <th className="width-by-px-240">{t("detail.moment_of_inertia_y")}</th>
            <th className="width-by-px-240">{t("detail.moment_of_inertia_z")}</th>
            <th className="width-by-px-240">{t("detail.product_of_inertia_x")}</th>
            <th className="width-by-px-240">{t("detail.product_of_inertia_y")}</th>
            <th className="width-by-px-240">{t("detail.product_of_inertia_z")}</th>
            <th className="width-by-px-50"></th>
          </tr>
        </thead>
        <DndProvider backend={HTML5Backend}>
          <tbody>
            <tr>
              <td className="p-0 none-border">{isFixed && <div className="position-absolute w-100 h-100 z-i-1002 top-0 left-0"></div>}</td>
            </tr>
            {data.map((stage, stageIndex) => (
              <React.Fragment key={stageIndex}>
                <StageRow
                  {...{
                    indexList: stageIndex,
                    data: stage,
                    handleSave,
                    handleEndDrop,
                    handleCollapse,
                    handleAddMore,
                  }}
                />
                {stage.isOpen &&
                  stage.children.map((level0, level0Index) => {
                    const level0IndexList = `${stageIndex}|${level0Index}`;
                    return (
                      <React.Fragment key={level0Index}>
                        <Level0Row
                          {...{
                            indexList: level0IndexList,
                            data: level0,
                            handleSave,
                            handleEndDrop,
                            handleCollapse,
                            handleAddMore,
                            handleDelete,
                            stage: totalStage,
                            currentStage: level0?.original_stage ?? parseInt(stage?.name),
                          }}
                        />
                        {level0.isOpen &&
                          level0.children.map((level1, level1Index) => {
                            const level1IndexList = `${level0IndexList}|${level1Index}`;
                            return (
                              <React.Fragment key={level1Index}>
                                <Level1Row
                                  {...{
                                    indexList: level1IndexList,
                                    data: level1,
                                    handleSave,
                                    handleEndDrop,
                                    handleCollapse,
                                    handleAddMore,
                                    handleDelete,
                                  }}
                                />
                                {level1.isOpen &&
                                  level1.children.map((component, componentIndex) => {
                                    const componentIndexList = `${level1IndexList}|${componentIndex}`;
                                    return (
                                      <ComponentRow
                                        {...{
                                          indexList: componentIndexList,
                                          data: component,
                                          handleSave,
                                          handleEndDrop,
                                          handleCollapse,
                                          handleAddMore,
                                          initComponentColumn,
                                          handleDelete,
                                        }}
                                        key={componentIndex}
                                      />
                                    );
                                  })}
                              </React.Fragment>
                            );
                          })}
                      </React.Fragment>
                    );
                  })}
              </React.Fragment>
            ))}
          </tbody>
        </DndProvider>
      </table>
    </div>
  );
};

export default DragAndDropTable;
