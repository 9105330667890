const APP_ROUTES = {
  HOME: "/",
  LOGIN: "/login",
  TOKEN_PAGE: "/token/:token",
  FLIGHT_LIST: "/flight",
  TEST_ANALYSIS_LIST: "/test-analysis",
  FLIGHT_NEW: "/flight/new",
  ROCKET_MODEL: "/rocket-model",
  AERODYNAMICS: "/aerodynamics",
  ROCKET_MODEL_DETAIL: "/rocket-model/:id",
  LITERATURE_VALUES: "/literature-values",
  CFD_RESULTS: "/cfd-results",
  THRUST_CALCULATION_LIST: "/thrust-calculator",
  ENGINE_COMPONENT_LIST: "/engine-component",
  FLIGHT_DUPLICATE_DETAIL: "/flight/:id",
  ACCESS_DENIED: "/access-denied",
  NOT_FOUND: "/404",
};

export default APP_ROUTES;
