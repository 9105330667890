import { useMutation } from "src/Hooks/useReactQuery";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Spinner } from "reactstrap";
import APP_ROUTES from "src/routes/routes";
import { ACCESS_TOKEN, setLocalStorage } from "src/utils/storage";

const Token = () => {
  const { token } = useParams();
  const navigate = useNavigate();

  const { mutate } = useMutation({
    mutationFn: (token) => setLocalStorage(ACCESS_TOKEN, token),
  });

  useEffect(() => {
    mutate(token, {
      onSuccess: () => navigate(APP_ROUTES.HOME),
    });
  }, []);

  return (
    <div className="full-page d-flex align-items-center justify-content-center">
      <Spinner color="secondary" />
    </div>
  );
};

export default Token;
