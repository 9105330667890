import api from "src/configs/api";
import {
  GET_TEST_ANALYSIS,
  GET_TEST_ANALYSIS_PROCESS,
  GET_SERIES_DASHBOARD,
  GET_MULTIPLE_OPT_DASHBOARD,
  CREATE_TEST_ANALYSIS,
  DELETE_TEST_ANALYSIS_ITEM,
  POST_TEST_ANALYSIS_ITEM,
} from "src/constant/endpoint";

const getTestAnalysis = async (params) => {
  return await api
    .get(GET_TEST_ANALYSIS, { params })
    .then((response) => {
      response?.data?.data?.items?.map(item => {
        item.analysis_status = item.analysis_status === 'not_yet' ? 'PENDING' : item.analysis_status.toUpperCase();
        return item;
      });
      return response?.data?.data;
    });
};

const createTestAnalysis = ({ data }) => {
  return api.post(CREATE_TEST_ANALYSIS, { data }).then((response) => response?.data);
}

const createTestAnalysisItem = ({ data }) => {
  return api.post(POST_TEST_ANALYSIS_ITEM, { data }).then((response) => response?.data);
}

const getTestAnalysisProcess = async () =>
  await api.get(GET_TEST_ANALYSIS_PROCESS).then((response) => response?.data);

const deleteTestAnalysisItem = async (id) => {
  return await api
    .delete(DELETE_TEST_ANALYSIS_ITEM(id))
    .then((response) => response?.data?.data);
};

const getDashboardLink = async (data) => {
  return await api.post(GET_SERIES_DASHBOARD(data.id), {data: data.params});
}

const getMultipleOptDashboardLink = async (data) => {
  return await api.post(GET_MULTIPLE_OPT_DASHBOARD(data.id), {data});
}

export {
  createTestAnalysis,
  getTestAnalysisProcess,
  getTestAnalysis,
  getDashboardLink,
  getMultipleOptDashboardLink,
  deleteTestAnalysisItem,
  createTestAnalysisItem
};
