import { useDrag, useDrop } from "react-dnd";
import { useRef } from "react";

const useDragAndDrop = (currentLevel, indexList, handleEndDrop) => {
  const ref = useRef(null);

  const [, drag] = useDrag(
    () => ({
      type: currentLevel,
      item: { index: indexList },
      end: (item, monitor) => handleEndDrop(currentLevel, item, monitor),
    }),
    [indexList]
  );

  const [, drop] = useDrop(() => ({
    accept: currentLevel,
    drop: () => ({ index: indexList }),
  }));

  drag(drop(ref));

  return ref;
};

export default useDragAndDrop;
