import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Container, CardHeader } from "reactstrap";
import useUrlState from "@ahooksjs/use-url-state";
import { useTranslation } from "react-i18next";
import { getEngineComponent } from "src/services/engine-component";
import { PAGINATION_DEFAULT, REFETCH_INTERVAL_TIME } from "src/constant/common";
import { handleScrollTo, handleScrollToTop } from "src/utils/common";
import { useQuery } from "src/Hooks/useReactQuery";
import ModalAddEngineComponent from "../../Common/Modal/ModalAddEngineComponent";
import ModalEditEngineComponent from "../../Common/Modal/ModalEditEngineComponent";
import TableCommon from "src/components/Common/Table";

const EngineComponentList = () => {
  const { t } = useTranslation(["engine-component"]);

  const [params, setParams] = useUrlState(
    {},
    {
      navigateMode: "replace",
      parseOptions: {
        parseNumbers: true,
      },
      stringifyOptions: {
        skipNull: true,
      },
    }
  );

  const columns = [
    {
      dataField: "id",
      text: t("list.table.id"),
      headerClass: "width-by-percent-10",
    },
    {
      dataField: "name",
      text: t("list.table.name"),
      headerClass: "width-by-percent-12",
    },
    {
      dataField: "isp",
      text: t("list.table.isp"),
      headerClass: "width-by-percent-12",
      formatter: (_, row) => {
        return row?.isp ?? "-";
      },
    },
    {
      dataField: "thrust",
      text: t("list.table.thrust"),
      headerClass: "width-by-percent-12",
      formatter: (_, row) => {
        return row?.thrust ?? "-";
      },
    },
    {
      dataField: "mass",
      text: t("list.table.mass"),
      headerClass: "width-by-percent-12",
      formatter: (_, row) => {
        return row?.mass ?? "-";
      },
    },
    {
      dataField: "center_of_gravity",
      text: t("list.table.center_of_gravity"),
      headerClass: "width-by-px-220",
      formatter: (_, row) => {
        if (Array.isArray(row?.center_of_gravity)) {
          return `[${row?.center_of_gravity}]`;
        }
        return row?.center_of_gravity ?? "-";
      },
    },
    {
      dataField: "length",
      text: t("list.table.length"),
      headerClass: "width-by-percent-16",
      formatter: (_, row) => {
        return row?.length ?? "-";
      },
    },
    {
      dataField: "nozzle_exit_diameter",
      text: t("list.table.nozzle_exit_diameter"),
      headerClass: "width-by-px-200",
      formatter: (_, row) => {
        return row?.nozzle_exit_diameter ?? "-";
      },
    },
    {
      dataField: "actions",
      text: "", //
      headerClass: "width-by-percent-10",
      formatter: (_, row) => {
        return <ModalEditEngineComponent modelId={row?.id} onSuccess={refetch} />;
      },
    },
  ];

  const [isLoadingSimulator, setIsLoadingSimulator] = useState(true);
  const [allowScrollToId, setAllowScrollToId] = useState(true);

  const { data: dataEngineComponent, refetch } = useQuery(
    ["getEngineComponent"],
    () =>
      getEngineComponent({
        page: params.page,
        limit: PAGINATION_DEFAULT.pageSize,
        q: params?.condition,
      }),
    {
      onSuccess: (data) => {
        for (const testItem of data?.items) {
          if (testItem?.items) {
            testItem.items.sort((a, b) => (a.type > b.type ? -1 : b.type > a.type ? 1 : 0));
          }
        }

        if (params?.id && allowScrollToId) {
          handleScrollTo(0, 90, `row${params.id}`);
          setAllowScrollToId(false);
        }
      },
      onSettled: () => {
        setIsLoadingSimulator(false);
      },
      refetchInterval: REFETCH_INTERVAL_TIME,
    }
  );

  const onChangePage = (value) => {
    setParams({
      ...params,
      page: value,
    });
    handleScrollToTop();
  };

  useEffect(() => {
    setIsLoadingSimulator(true);
    refetch();
  }, [params]);

  return (
    <Container fluid>
      <Row>
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-2">
                <h2 className="header-title mb-0">{t("list.title")}</h2>
              </div>
              <Row>
                <Col md={12}>
                  <Card>
                    <div className="d-flex justify-content-end">
                      <CardHeader className="bg-white border-0">
                        <ModalAddEngineComponent
                          onSuccess={() => {
                            if (params?.page === 1) {
                              refetch();
                            } else {
                              setParams({ ...params, page: 1 });
                            }
                            handleScrollToTop();
                          }}
                        />
                      </CardHeader>
                    </div>
                    <CardBody className="bg-white">
                      <TableCommon
                        loading={isLoadingSimulator}
                        columns={columns}
                        data={dataEngineComponent?.items || []}
                        onChangePage={onChangePage}
                        tableFixed
                        tableClassName="striped table-collapse"
                        customClassName="overflow-auto simulation-table"
                        pageCurrent={(params?.page || PAGINATION_DEFAULT.page) - 1}
                        pageCount={dataEngineComponent?.pagination?.total_page}
                        totalItem={dataEngineComponent?.pagination?.total}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Row>
    </Container>
  );
};

export default EngineComponentList;
