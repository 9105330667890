import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { Collapse } from "reactstrap";
import { HISTORIES_ACTION_NAME, MAPPING_HISTORIES_ACTION_TO_TEXT, PROCESS_STATUS } from "src/constant/common";
import LoadingWrapper from "src/components/Common/Table/Loading";
import { MAPPING_CONTENT_CHANGE_TO_TEXT } from "src/utils/common";
import SimulationHistory from "./SimulationHistory";
import ReactDiffViewer from "react-diff-viewer";

const RenderContent = ({ i, t, stage, isShow, toggleShowDetail }) => {
  const showDetail = [
    HISTORIES_ACTION_NAME.UPDATE_ENGINE_COMPONENT,
    HISTORIES_ACTION_NAME.ADD_NEW_CALCULATION,
    HISTORIES_ACTION_NAME.RUN_SIMULATION,
    HISTORIES_ACTION_NAME.CALCULATE_THRUST,
    HISTORIES_ACTION_NAME.SAVE_UNPROCESSED_COMPONENT_DETAILS,
    HISTORIES_ACTION_NAME.UPDATE_COMPONENT_DETAIL,
  ].includes(i.action_name);
  const id = i?.action_name === HISTORIES_ACTION_NAME.CALCULATE_THRUST ? i?.new_value?.id : null;
  const isHiddenContent = [
    HISTORIES_ACTION_NAME.UPDATE_ENGINE_COMPONENT,
    HISTORIES_ACTION_NAME.RUN_SIMULATION,
    HISTORIES_ACTION_NAME.SAVE_UNPROCESSED_COMPONENT_DETAILS,
    HISTORIES_ACTION_NAME.UPDATE_COMPONENT_DETAIL,
  ].includes(i.action_name);
  const isCreateRocketModel = i?.action_name === HISTORIES_ACTION_NAME.CREATE_ROCKET_MODEL;

  const showValue = (val) => val || "empty";
  const safeStringify = (obj) => {
    try {
      return JSON.stringify(obj || {}, null, 4);
    } catch (error) {
      console.error("JSON stringify failed:", error);
      return "{}";
    }
  };

  const ActionContent = ({ action, data, show, stage, t }) => {
    switch (action) {
      case HISTORIES_ACTION_NAME.UPDATE_ROCKET_MODEL:
        return (
          <ul className="mb-0 m-l-90 ">
            {
              data?.original_value?.name !== data?.new_value?.name ? (
                <li>
                  Name: {showValue(data?.original_value?.name)} <i className="bi bi-arrow-right"></i> {data?.new_value?.name}
                </li>
              ) : ""
            }

            {isCreateRocketModel && (
              <li>
                Number of Stage: {showValue(data?.original_value?.number_of_stage)} <i className="bi bi-arrow-right"></i>{" "}
                {data?.new_value?.number_of_stage}
              </li>
            )}
            {(data?.original_value?.remarks !== data?.new_value?.remarks) ? (
              <li>
                Remarks: {showValue(data?.original_value?.remarks)} <i className="bi bi-arrow-right"></i>{" "}
                {showValue(data?.new_value?.remarks)}
              </li>
            ) : (
              ""
            )}
          </ul>
        );
      case HISTORIES_ACTION_NAME.SAVE_UNPROCESSED_COMPONENT_DETAILS:
        return show ? (
          <div className=" mb-0 m-l-90">
            <ReactDiffViewer
              className="mb-0 m-l-90"
              oldValue={safeStringify(data?.original_value?.unprocessed_component_details || data?.original_value?.component_details)}
              newValue={safeStringify(data?.new_value?.unprocessed_component_details)}
              splitView
              showDiffOnly
            />
          </div>
        ) : (
          <></>
        );
      case HISTORIES_ACTION_NAME.UPDATE_COMPONENT_DETAIL:
        return show ? (
          <div className=" mb-0 m-l-90">
            <ReactDiffViewer
              className="mb-0 m-l-90"
              oldValue={safeStringify(data?.original_value?.component_details)}
              newValue={safeStringify(data?.new_value?.component_details)}
              splitView
              showDiffOnly
            />
          </div>
        ) : (
          <></>
        );
      case HISTORIES_ACTION_NAME.RUN_SIMULATION:
        return (
          <SimulationHistory
            show={show}
            originalData={data?.comments}
            stage={stage}
            isSuccess={data?.new_value?.simulator_status === PROCESS_STATUS.SUCCEEDED}
            t={t}
          />
        );
      default:
        return null;
    }
  };
  const renderByAction = () => <ActionContent action={i.action_name} data={i} show={isShow} stage={stage} t={t} />;

  return (
    <div className="row px-3 pb-2">
      <div className="col-12 border-top d-flex align-items-center pt-3 gap-2">
        <div className="d-flex align-items-center w-auto">
          <img className="rounded-circle" src={i?.created_by?.image_url} alt="" width="48" height="48" />
        </div>
        <div className="w-auto">
          <p className="mb-0">
            <span className="fw-bold">{i?.created_by?.name}</span>{" "}
            {t("logs.content", { type: MAPPING_HISTORIES_ACTION_TO_TEXT(t)[i.action_name] ?? t("logs.updated") })} at{" "}
            <span className="text-secondary">{format(i?.updated_at, "MMM dd, yyyy HH:mm")}</span>
          </p>
        </div>
      </div>
      {showDetail && (
        <div className="col-12">
          <ul className="mb-0 m-l-60">
            <li className="d-flex gap-1">
              <span
                className="mb-0"
                dangerouslySetInnerHTML={{
                  __html: MAPPING_CONTENT_CHANGE_TO_TEXT(t, i?.comments, id, i?.new_value)[i?.action_name](),
                }}
              />
              {isHiddenContent && <i className={`bi bi-${isShow ? "eye-slash" : "eye"}`} role="button" onClick={() => toggleShowDetail(oldState => ({ ...oldState, [i.id]: !isShow }))}></i>}
            </li>
          </ul>
        </div>
      )}
      <div className="col-12 mt-1">{renderByAction()}</div>
    </div>
  );
};

const HistoryLogs = ({ logs, loadingHistories, stage }) => {
  const LIMIT_LOGS = 5;
  const { t } = useTranslation(["rocket-model"]);
  const [data, setData] = useState(null);
  const [showAll, setShowAll] = useState(false);
  const [show, setShow] = useState(false);
  const [showContent, setShowContent] = useState(true);
  const [init, setInit] = useState(true);
  const [showDetailById, setShowDetailById] = useState({});

  const handleShowAll = (e) => {
    e.preventDefault();
    setShowAll(false);
    setShow(true);
    setTimeout(() => {
      const logsContainer = document.getElementById("logs");
      logsContainer?.scrollIntoView({ behavior: "instant", block: "end" });
    });
  };

  useEffect(() => {
    if (logs) {
      setData(logs);
      if (init && logs?.pagination?.total > LIMIT_LOGS) {
        setShowAll(!!(logs?.pagination?.total > LIMIT_LOGS));
        setInit(false);
      }
    }
  }, [logs]);

  return (
    <LoadingWrapper loading={loadingHistories}>
      <div className="row" id="logs">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <button className="btn btn-blank text-dark border-0 p-0 header-title" onClick={() => setShowContent(!showContent)}>
                {t("logs.title")} <i className={showContent ? "bi bi-caret-up-fill" : "bi bi-caret-down-fill"}></i>
              </button>

              <Collapse isOpen={showContent}>
                {showAll && (
                  <div className="text-center mb-1">
                    <span className="text-primary" role="button" onClick={(e) => handleShowAll(e)}>
                      {t("logs.load_more_count", { count: data?.pagination?.total - LIMIT_LOGS })}
                    </span>
                  </div>
                )}

                {show &&
                  data?.items?.slice(0, data?.items?.length - LIMIT_LOGS)?.map((i, index) => (
                    <Fragment key={index}>
                      <RenderContent t={t} i={i} stage={stage} isShow={showDetailById[i.id]} toggleShowDetail={setShowDetailById} />
                    </Fragment>
                  ))}
                {data?.items
                  ?.slice(data?.items?.length > LIMIT_LOGS ? data?.items?.length - LIMIT_LOGS : 0, data?.items?.length)
                  ?.map((i, index) => (
                    <Fragment key={index}>
                      <RenderContent t={t} i={i} stage={stage} isShow={showDetailById[i.id]} toggleShowDetail={setShowDetailById} />
                    </Fragment>
                  ))}
              </Collapse>
            </div>
          </div>
        </div>
      </div>
    </LoadingWrapper>
  );
};

export default HistoryLogs;
