import React, { useEffect, useState } from "react";
import { MODEL_DETAIL_TEMPLATE_CSV } from "src/constant/common";
import Papa from "papaparse";

const SimulationHistory = ({ show, stage, originalData, isSuccess, t }) => {
  const [data, setData] = useState(null);
  useEffect(() => {
    Papa.parse(MODEL_DETAIL_TEMPLATE_CSV?.[stage], {
      header: true,
      dynamicTyping: false,
      complete: (result) => {
        const template = result.data?.filter((i) => !!i[""]);
        if (originalData && template) {
          setData(applyMockDataToTemplate(originalData, template));
        }
      },
    });
  }, [stage, originalData]);

  const applyMockDataToTemplate = (mockData, template) => {
    // Function to convert a value to a float-like string representation
    const formatValue = (value) => {
      if (value === 0) return "0.0";
      return Number.isInteger(value) ? `${value}.0` : value?.toString();
    };
    // Function to fill an individual entry with data
    const populateEntry = (entry, data) => {
      entry["mass (kg)"] = formatValue(data.mass.value);
      entry["length (m)"] = formatValue(data.length.value);
      entry["center_of_gravity_gx (m)"] = formatValue(data.center_of_gravity.value[0]);
      entry["center_of_gravity_gy (m)"] = formatValue(data.center_of_gravity.value[1]);
      entry["center_of_gravity_gz (m)"] = formatValue(data.center_of_gravity.value[2]);
      entry["diameter (m)"] = formatValue(data.diameter.max_value ?? data.diameter.value);
      // Format tensor_of_inertia as a string
      entry["tensor_of_inertia (kgm^2)"] = JSON.stringify(data.tensor_of_inertia.value.map((row) => row.map(formatValue)))
        ?.replaceAll('"', "")
        ?.replaceAll(",", ", ");
    };
    return template.map((entry) => {
      const key = entry[""];
      const data = mockData[key];
      if (data) {
        populateEntry(entry, data);
      }
      return entry;
    });
  };

  if (!show || !data || !originalData) return;

  return isSuccess ? (
    <>
      <div className="react-bootstrap-table overflow-auto max-height-by-px-500 simulation-table table-detail table-nowrap">
        <table className="table table-striped table-hover table-bordered">
          <thead>
            <tr>{data?.length > 0 && Object.keys(data?.[0]).map((key, index) => <th key={index}>{key}</th>)}</tr>
          </thead>
          <tbody>
            {data?.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {Object.values(row).map((val, colIndex) => (
                  <td key={colIndex}>{val}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  ) : (
    <>
      <ul className="mb-0 m-l-90">
        <li className=" d-flex gap-1">{originalData?.error?.message || t("detail.run_simulation_error")}</li>
        {originalData?.error?.errors?.map((i, index) => (
          <li key={index}>
            <span className="text-danger">{`[${i?.error_id}]`}</span> {i?.message}
          </li>
        ))}
      </ul>
    </>
  );
};

export default SimulationHistory;
