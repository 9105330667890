import api from "src/configs/api";

import {
  GET_THRUST_CALCULATOR,
  CREATE_THRUST_CALCULATOR,
  GET_THRUST_CALCULATOR_DETAIL,
  GET_ROCKET_MODEL_THRUST_CALCULATOR,
} from "src/constant/endpoint";

const getThrustCalculation = async (data) => {
  const { rocketId, ...params } = data;
  if (rocketId) {
    return await api.get(GET_ROCKET_MODEL_THRUST_CALCULATOR(rocketId), { params }).then((response) => response?.data?.data);
  }
  return await api.get(GET_THRUST_CALCULATOR, { params }).then((response) => response?.data?.data);
};

const createThrustCalculation = async ({ data }) => {
  return await api.post(CREATE_THRUST_CALCULATOR, { data }).then((response) => response?.data);
};

const getThrustCalculationDetail = async (id) => {
  return await api.get(GET_THRUST_CALCULATOR_DETAIL(id)).then((response) => response?.data?.data);
};

export { createThrustCalculation, getThrustCalculation, getThrustCalculationDetail };
