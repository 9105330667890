import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { RecoilRoot } from "recoil";
import "./i18n";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Routes from "./routes/useRoutes";
import RecoilNexus from "recoil-nexus";
import "./scss/theme.scss";
import "bootstrap-icons/font/bootstrap-icons.css";

const queryClient = new QueryClient();

const router = createBrowserRouter(Routes);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Suspense>
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <RecoilNexus />
        <RouterProvider router={router} />
      </RecoilRoot>
    </QueryClientProvider>
  </Suspense>
  // </React.StrictMode>
);
