import React from "react";
import APP_ROUTES from "./routes";

import FlightSimulator from "src/pages/FlightSimulator";
import RocketModel from "src/pages/RocketModel";
import RocketModelDetail from "src/pages/RocketModel/detail";
import EngineComponent from "src/pages/EngineComponent";
import TestAnalysis from "src/pages/TestAnalysis";
import Aerodynamics from "src/pages/Aerodynamics";
import NewFlightSimulator from "src/pages/FlightSimulator/new";
import ThrustCalculation from "src/pages/ThrustCalculation";
import Login from "src/pages/Login";
import FlightSimulatorDetail from "src/pages/FlightSimulator/detail";
import Error404 from "src/pages/NotFound";
import Token from "src/pages/Token";
import AccessDenied from "src/pages/AccessDenied";
import MainLayout from "src/components/Common/Layout/MainLayout";
import Notifier from "src/components/Common/Notifier";

const Routes = [
  {
    path: APP_ROUTES.HOME,
    element: (
      <>
        <Notifier />
        <MainLayout />
      </>
    ),
    children: [
      {
        index: true,
        element: <FlightSimulator />,
      },
      {
        path: APP_ROUTES.FLIGHT_NEW,
        element: <NewFlightSimulator />,
      },
      {
        path: APP_ROUTES.TEST_ANALYSIS_LIST,
        element: <TestAnalysis />,
      },
      {
        path: APP_ROUTES.ROCKET_MODEL,
        element: <RocketModel />,
      },
      {
        path: APP_ROUTES.THRUST_CALCULATION_LIST,
        element: <ThrustCalculation />,
      },
      {
        path: APP_ROUTES.ROCKET_MODEL_DETAIL,
        element: <RocketModelDetail />,
      },
      {
        path: APP_ROUTES.ENGINE_COMPONENT_LIST,
        element: <EngineComponent />,
      },
      {
        path: APP_ROUTES.FLIGHT_DUPLICATE_DETAIL,
        element: <FlightSimulatorDetail />,
      },
      {
        path: APP_ROUTES.AERODYNAMICS,
        element: <Aerodynamics />,
      },
    ],
  },
  {
    path: APP_ROUTES.LOGIN,
    element: <Login />,
  },
  {
    path: APP_ROUTES.TOKEN_PAGE,
    element: <Token />,
  },
  {
    path: APP_ROUTES.ACCESS_DENIED,
    element: <AccessDenied />,
  },
  {
    path: "*",
    element: <Error404 />,
  },
];

export default Routes;
