import { useMutation } from "src/Hooks/useReactQuery";
import React from "react";
import { useNavigate } from "react-router-dom";
import routeGuard from "src/HOC/routeGuard";
import FormFlightSimulator, {
  DEFAULT_DATA_PARAMS,
  FORM_NAME,
} from "src/components/FlightSimulator/form";
import APP_ROUTES from "src/routes/routes";
import {
  createSimulator,
} from "src/services/simulator-flight";

const FlightSimulator = () => {
  const navigate = useNavigate();
  const data = {
    [FORM_NAME.NAME]: "",
    [FORM_NAME.TYPE]: "",
    [FORM_NAME.VERSION]: "",
    [FORM_NAME.EXECUTION]: "",
    [FORM_NAME.NOTE]: "",
    [FORM_NAME.PARAMS]: [],
    [FORM_NAME.INPUTS]: [],
    [FORM_NAME.FILE_RESPONSE]: "",
    [FORM_NAME.SERIES]: [DEFAULT_DATA_PARAMS],
  };

  const createSimulatorMutate = useMutation({
    mutationFn: ({ data }) => createSimulator({ data }),
  });

  const submitData = ({ data }, callback) =>
    createSimulatorMutate.mutate(
      { data },
      {
        onSuccess: () => navigate(APP_ROUTES.HOME),
        onSettled: () => callback(),
      }
    );

  return (
    <FormFlightSimulator
      submitData={submitData}
      data={data}
    />
  );
};

export default routeGuard(FlightSimulator);
