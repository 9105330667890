import React from "react";
import routeGuard from "src/HOC/routeGuard";
import TestAnalysisList from "src/components/TestAnalysis/list";

const TestAnalysis = () => {
  return (
    <TestAnalysisList />
  );
};

export default routeGuard(TestAnalysis);
