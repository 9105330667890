import api from "src/configs/api";
import {
  GET_ROCKET_MODEL,
  EDIT_ROCKET_MODEL,
  GET_ROCKET_MODEL_DETAIL,
  CREATE_ROCKET_MODEL,
  GET_ROCKET_MODEL_HISTORIES,
  BULK_THRUST_CALCULATOR_TO_ROCKET_MODEL,
  DELETE_ROCKET_MODEL,
} from "src/constant/endpoint";

const getRocketModel = async (params) => {
  return await api.get(GET_ROCKET_MODEL, { params }).then((response) => response?.data?.data);
};

const createRocketModel = ({ data }) => {
  return api.post(CREATE_ROCKET_MODEL, { data }).then((response) => response?.data);
};

const editRocketModel = async ({ id, data, callback, promise }) => {
  return await api
    .patch(EDIT_ROCKET_MODEL(id), { data })
    .then((response) => {
      callback?.();
      return response?.data;
    })
    .catch((err) => (promise ? Promise.reject(err) : err));
};

const getRocketModelDetail = async (id) => {
  return await api
    .get(GET_ROCKET_MODEL_DETAIL(id))
    .then((response) => response?.data?.data)
    .catch((err) => err);
};

const getLogHistories = async (id, params) => {
  return await api.get(GET_ROCKET_MODEL_HISTORIES(id), { params }).then((response) => response?.data?.data);
};

const bulkThrustCalculation = async ({ id, data }) => {
  return await api.post(BULK_THRUST_CALCULATOR_TO_ROCKET_MODEL(id), { data }).then((response) => response?.data);
};

const deleteRocketModel = async ({ id }) => {
  return await api.delete(DELETE_ROCKET_MODEL(id)).then((response) => response?.data);
};

export {
  createRocketModel,
  editRocketModel,
  getRocketModelDetail,
  getRocketModel,
  getLogHistories,
  bulkThrustCalculation,
  deleteRocketModel,
};
