import React from "react";
import routeGuard from "src/HOC/routeGuard";
import AerodynamicsSimulatorList from "src/components/Aerodynamics/list";

const Aerodynamics = () => {

  return (
    <AerodynamicsSimulatorList
    />
  );
};

export default routeGuard(Aerodynamics);