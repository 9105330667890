import React, { useState, useEffect } from "react";
import { Form, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useTranslation } from "react-i18next";
import FormInput from "../Form/FormInput";
import { useForm } from "react-hook-form";
import { getEngineComponent, createEngineComponent } from "src/services/engine-component";

import { useMutation } from "src/Hooks/useReactQuery";
import { getConfigFromId } from "src/utils/common";
import { useSetRecoilState } from "recoil";
import { alertState } from "src/recoil/atoms/global";
import { PATTERN_GRAVITY_OF_CENTER, PATTERN_NEGATIVE_NUMBER } from "src/constant/common";

const FORM_NAME = {
  NAME: "name",
  STAGE: "stage",
  CENTERGRAVITY: "center_of_gravity",
  ISP: "isp",
  LENGTH: "length",
  THRUST: "thrust",
  NOZZLE_EXIT: "nozzle_exit_diameter",
  MASS: "mass",
  INNERDIAMETER: "inner_diameter",
  MEMO: "memo",
};

const ModalAddEngineComponent = ({ onSuccess }) => {
  const { t } = useTranslation("engine-component");
  const [toggle, setToggle] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [, setIsLoading] = useState(true);
  const [config, setConfig] = useState([]);
  const showAlert = useSetRecoilState(alertState);

  const { data: options, mutate: getOptionsMutate } = useMutation({
    mutationFn: () => getEngineComponent(),
    onSettled: () => setIsLoading(false),
  });

  const createTestAnalysisMutate = useMutation({
    mutationFn: ({ data }) => createEngineComponent({ data }),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm({});

  const processId = watch(FORM_NAME.STAGE);

  const handleSubmitForm = (data) => {
    const inputData = {
      name: data.name.trim(),
      stage: data.stage,
      center_of_gravity: JSON.parse(`[${data.center_of_gravity}]`),
      isp: data.isp,
      length: data.length,
      thrust: data.thrust,
      nozzle_exit_diameter: data.nozzle_exit_diameter,
      mass: data.mass,
      memo: data.memo,
    };

    for (const item of config) {
      inputData.config[item.name] = item.type === "number" ? parseFloat(data[item.name]) : data[item.name];
    }

    setIsSubmit(true);
    createTestAnalysisMutate.mutate(
      { data: inputData },
      {
        onSuccess: () => {
          setToggle(false);
          onSuccess();
          showAlert({
            status: true,
            message: t("new.create_success"),
            type: "success",
          });
        },
        onSettled: () => {
          setIsSubmit(false);
        },
      }
    );
  };

  useEffect(() => {
    if (processId) {
      setConfig(getConfigFromId(processId, options, "engine-component"));
    }
  }, [processId]);

  useEffect(() => {
    getOptionsMutate();
  }, []);

  return (
    <>
      <button
        onClick={() => {
          reset();
          setConfig([]);
          setToggle(true);
        }}
        className="btn btn-primary text-capitalize"
      >
        {t("list.redirect_new")}
      </button>
      <Modal
        centered
        isOpen={toggle}
        toggle={() => setToggle(false)}
        size="xl"
        backdrop="static"
        style={{ maxWidth: "880px", width: "85%" }}
      >
        <ModalHeader toggle={() => setToggle(false)}>{t("new.title")}</ModalHeader>
        <ModalBody className="p-b-0">
          <Form className="p-x-15">
            <div className="row">
              <div className="row">
                <div className="col-md-6">
                  <FormInput
                    register={register}
                    label={t("new.name")}
                    name={FORM_NAME.NAME}
                    placeholder={t("new.name_placeholder")}
                    errors={errors?.[FORM_NAME.NAME]?.message}
                    requiredMessage={t("new.name_required")}
                    required
                    maxLength={255}
                    maxLengthMessage={t("new.name_maxlength", { field: t("new.name") })}
                  />
                </div>
                <div className="col-md-6">
                  <FormInput
                    register={register}
                    label={t("new.center_of_gravity")}
                    name={FORM_NAME.CENTERGRAVITY}
                    placeholder={t("new.center_of_gravity_placeholder")}
                    errors={errors?.[FORM_NAME.CENTERGRAVITY]?.message}
                    requiredMessage={t("new.center_of_gravity_required")}
                    required
                    maxLength={255}
                    maxLengthMessage={t("new.name_maxlength", { field: t("new.center_of_gravity") })}
                    pattern={{
                      value: PATTERN_GRAVITY_OF_CENTER,
                      message: t("new.center_of_gravity_format"),
                    }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <FormInput
                    register={register}
                    label={t("new.isp")}
                    name={FORM_NAME.ISP}
                    placeholder={t("new.isp_placeholder")}
                    errors={errors?.[FORM_NAME.ISP]?.message}
                    requiredMessage={t("new.isp_required")}
                    required
                    maxLength={255}
                    maxLengthMessage={t("new.name_maxlength", { field: t("new.isp") })}
                    pattern={{
                      value: PATTERN_NEGATIVE_NUMBER,
                      message: t(`new.field_number_validate`, { field: t("new.isp") }),
                    }}
                    valueGreaterThanZero
                    unit="s"
                  />
                </div>
                <div className="col-md-6">
                  <FormInput
                    register={register}
                    label={t("new.length")}
                    name={FORM_NAME.LENGTH}
                    placeholder={t("new.length_placeholder")}
                    errors={errors?.[FORM_NAME.LENGTH]?.message}
                    requiredMessage={t("new.length_required")}
                    required
                    step="any"
                    maxLength={255}
                    maxLengthMessage={t("new.name_maxlength", { field: t("new.length") })}
                    pattern={{
                      value: PATTERN_NEGATIVE_NUMBER,
                      message: t(`new.field_number_validate`, { field: t("new.length") }),
                    }}
                    valueGreaterThanZero
                    unit="m"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <FormInput
                    register={register}
                    label={t("new.thrust")}
                    name={FORM_NAME.THRUST}
                    placeholder={t("new.thrust_placeholder")}
                    errors={errors?.[FORM_NAME.THRUST]?.message}
                    requiredMessage={t("new.thrust_required")}
                    required
                    maxLength={255}
                    maxLengthMessage={t("new.name_maxlength", { field: t("new.thrust") })}
                    pattern={{
                      value: PATTERN_NEGATIVE_NUMBER,
                      message: t(`new.field_number_validate`, { field: t("new.thrust") }),
                    }}
                    valueGreaterThanZero
                    unit="N"
                  />
                </div>
                <div className="col-md-6">
                  <FormInput
                    register={register}
                    label={t("new.nozzle_exit_diameter")}
                    name={FORM_NAME.NOZZLE_EXIT}
                    placeholder={t("new.nozzle_exit_diameter_placeholder")}
                    errors={errors?.[FORM_NAME.NOZZLE_EXIT]?.message}
                    requiredMessage={t("new.nozzle_exit_diameter_required")}
                    required
                    maxLength={255}
                    maxLengthMessage={t("new.name_maxlength", { field: t("new.nozzle_exit_diameter") })}
                    pattern={{
                      value: PATTERN_NEGATIVE_NUMBER,
                      message: t(`new.field_number_validate`, { field: t("new.nozzle_exit_diameter") }),
                    }}
                    valueGreaterThanZero
                    unit="m"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <FormInput
                    register={register}
                    label={t("new.mass")}
                    name={FORM_NAME.MASS}
                    placeholder={t("new.mass_placeholder")}
                    errors={errors?.[FORM_NAME.MASS]?.message}
                    requiredMessage={t("new.mass_required")}
                    required
                    step="any"
                    maxLength={255}
                    maxLengthMessage={t("new.name_maxlength", { field: t("new.mass") })}
                    pattern={{
                      value: PATTERN_NEGATIVE_NUMBER,
                      message: t(`new.field_number_validate`, { field: t("new.mass") }),
                    }}
                    valueGreaterThanZero
                    unit="kg"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <FormInput
                    register={register}
                    label={t("new.memo")}
                    name={FORM_NAME.MEMO}
                    placeholder={t("new.memo_placeholder")}
                    errors={errors?.[FORM_NAME.MEMO]?.message}
                  />
                </div>
              </div>
            </div>
          </Form>
        </ModalBody>
        <ModalFooter className="justify-content-center p-t-10 p-b-20">
          <button
            className="btn btn-sm btn-secondary m-x-10 width-by-px-120"
            onClick={() => {
              setToggle(false);
              document.body.click();
            }}
          >
            {t("new.cancel")}
          </button>
          <button
            className="btn btn-sm btn-primary m-x-10 width-by-px-120"
            disabled={isSubmit}
            onClick={handleSubmit((data) => handleSubmitForm(data))}
          >
            {t("new.register")}
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ModalAddEngineComponent;
