import React, { useState } from "react";
import { 
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Spinner,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import { SIMULATION_RESULT } from "src/constant/common";

const SimulationDashboard = ({onClickMultiParam, onClickMultiOpt, simulationData, isLoading, isShow = true }) => {
  const { t } = useTranslation("flight-simulator");
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const renderDropdownItemLabel = (type) => {
    return simulationData.execution_file_name === SIMULATION_RESULT.PAYLOAD ? t(`list.table.payload_${type}_dashboard`) : t(`list.table.${type}_dashboard`);
  }
  
  return (
    isShow ? (
      isLoading ? (
        <Spinner size="sm" color="secondary" />
      ) : (
        <>
          <Dropdown
            isOpen={dropdownOpen}
            toggle={toggleDropdown}
            className="align-self-center"
            data-tooltip-id={`dashboard-${simulationData?.id}`}
            data-tooltip-content={t("list.table.dashboard")}
          >
            <DropdownToggle
              data-toggle="dropdown"
              tag="button"
              className="border-0 bg-transparent"
            >
              <i className={`bi bi-speedometer2 ${simulationData.opt_dashboard_url && simulationData.multi_dashboard_url ? 'text-success' : 'text-dark'} f-s-20`}/>
            </DropdownToggle>
            
            <DropdownMenu
              end={true}
              className="topbar-dropdown-menu profile-dropdown-items"
            >
              <div onClick={toggleDropdown}>
                <div
                  role="button"
                  className={`dropdown-item ${simulationData.multi_dashboard_url ? 'text-success' : 'text-dark'}`}
                  onClick={onClickMultiParam}
                >
                  <span>{renderDropdownItemLabel('multi_param')}</span>
                </div>
                <div
                  role="button"
                  className={`dropdown-item ${simulationData.opt_dashboard_url ? 'text-success' : 'text-dark'}`}
                  onClick={onClickMultiOpt}
                >
                  <span>{renderDropdownItemLabel('multi_opt')}</span>
                </div>
              </div>
            </DropdownMenu>
          </Dropdown>
          <Tooltip id={`dashboard-${simulationData?.id}`} className="text-break-space" />
        </>
      )
    ) : null
  );
};

export default SimulationDashboard;
