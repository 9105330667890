import React from "react";
// import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Container } from "reactstrap";
// import { Link } from "react-router-dom";
// import logo from "src/assets/images/logo.png";
import APP_ROUTES from "src/routes/routes";
import { useRecoilState } from "recoil";
import { authState } from "src/recoil/atoms/auth";
import { LogOut } from "react-feather";
import ProfileDropdown from "./ProfileDropdown";
import { ACCESS_TOKEN, removeLocalStorage } from "src/utils/storage";
import { sidebarState } from "src/recoil/atoms/sidebar";

const TopBar = () => {
  const [auth, setAuth] = useRecoilState(authState);
  const [sidebar, setSidebar] = useRecoilState(sidebarState);
  // const { t } = useTranslation("topbar");

  const navigate = useNavigate();

  const handleLogout = (e) => {
    e.preventDefault();
    removeLocalStorage(ACCESS_TOKEN);
    setAuth({});
    setTimeout(() => {
      navigate(APP_ROUTES.LOGIN);
    }, 0);
  };

  const ProfileMenus = [
    {
      label: "Logout",
      icon: LogOut,
      onClick: handleLogout,
    },
  ];

  const handleSidebarClick = () => {
    if (sidebar.broken) {
      setSidebar({...sidebar, toggled: !sidebar.toggled});
      return;
    }
    setSidebar({...sidebar, collapsed: !sidebar.collapsed});
  }

  return (
    <React.Fragment>
      <div className={`navbar navbar-expand flex-column flex-md-row navbar-custom bg-white ${sidebar.broken ? 'p-0' : (sidebar.collapsed ? 'p-l-80' : 'p-l-250')}`}>
        <Container fluid>
          <div className="d-flex align-items-center gap-2 height-by-px-70 justify-content-between w-100">
            {/* <div
              className="d-flex align-items-center gap-2 mx-3"
              onClick={() => navigate(APP_ROUTES.HOME)}
              role="button"
            >
              <div className="mr-2">
                <Link to={APP_ROUTES.HOME}>
                  <img src={logo} alt="" height="48" />
                </Link>
              </div>
              <p className="fw-bold mb-0">{t("title")}</p>
            </div> */}
            <button className="btn btn-blank p-20 border-0" onClick={() => handleSidebarClick()}><i className="bi bi-list f-s-30" style={{ color: 'gray' }}></i></button>

            <div className="d-flex align-items-center gap-2 mx-2">
              <ProfileDropdown
                menuItems={ProfileMenus}
                username={auth?.name}
                avatar={auth?.image_url}
              />
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TopBar;
