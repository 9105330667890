import { 
  Spinner,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { ButtonFileViewer, ButtonErrorWarning } from "src/components/Common/Button";
import { getFileName, extractIDsFromString } from "src/utils/common";
import { PROCESS_STATUS, DASHBOARD_STATUS, RETRY_TIME, DASHBOARD_TYPE, SIMULATION_RESULT } from "src/constant/common";
import Series6DofDashboard from "src/components/Common/Dropdown/Series6DofDashboard";
import { get6DofDashboardLink } from "src/services/simulator-flight";
import { useSetRecoilState } from 'recoil';
import { alertState } from 'src/recoil/atoms/global';

const SixDofTable = ({isLoading, isShow = true, isError, serieData, handleViewFile, simulationData, updateData}) => {
  const { t } = useTranslation(["flight-simulator","common"]);
  const showAlert = useSetRecoilState(alertState);

  const handleUpdateSerieData = (index, field, newValue) => {
    const newData = {...serieData};
    newData.data[index][field] = newValue;
    updateData(oldVal => ({
      ...oldVal,
      [simulationData.id]: newData
    }));
  };

  const handleClickRenderDashboard = async (simulationData, serieItemData, serieIndex, type) => {
    if (!serieItemData) return;
    const dashboardByType = serieItemData.simulator_dashboards.filter(item => item.dashboard_type === type)?.[0];
    if (dashboardByType?.dashboard_status === DASHBOARD_STATUS.SUCCEEDED && dashboardByType?.dashboard_url) {
      window.open(dashboardByType.dashboard_url, '_blank');
      return;
    }
    handleUpdateSerieData(serieIndex, 'loading', true);
    const [serieId] = extractIDsFromString(serieItemData.name);
    const data = {
      id: simulationData.id,
      input_file_id: serieId,
      dashboard_input_type: 'single',
      dashboard_type: type,
    }
    await checkDashboardStatus(serieItemData, serieIndex,  data, 1, type);
  };

  const checkDashboardStatus = async (serieItemData, serieIndex, params, retryCount, type) => {
    try {
      const res = await get6DofDashboardLink(params);
      if (await handleInProgressStatus(res, serieItemData, serieIndex, params, retryCount, type)) return;
      const newDashboards = [...serieItemData.simulator_dashboards];
      newDashboards.push(res.data);
      handleUpdateSerieData(serieIndex, 'loading', false);
      handleUpdateSerieData(serieIndex, 'simulator_dashboards', newDashboards);
      if (res.data.dashboard_status === DASHBOARD_STATUS.SUCCEEDED) {
        window.open(res.data.dashboard_url, '_blank');
      }
    } catch (e) {
      handleUpdateSerieData(serieIndex, 'loading', false);
      showAlert({
        status: true,   
        message: e?.response?.data?.error?.message || e?.response?.data?.message, 
        type: 'danger',
      });
    }
  }

  const handleInProgressStatus = async (res, serieItemData, serieIndex, params, retryCount, type) => {
    if (res.data.dashboard_status !== DASHBOARD_STATUS.INPROGRESS) return false;
    if (retryCount === RETRY_TIME) {
      showAlert({ status: true, message: t("over_time"), type: 'danger' });
      handleUpdateSerieData(serieIndex, 'loading', false);
      return true;
    }
    await new Promise(resolve => setTimeout(resolve, 3000));
    await checkDashboardStatus(serieItemData, serieIndex, params, retryCount + 1, type);
    return true;
  }
 
  return (
    isShow ? (
      isError ? (
        <div className="p-2 d-flex align-items-center justify-content-center w-100 height-by-px-70">
          {t("common:network_error")}
        </div>
      ) : isLoading ? (
        <div className="p-2 d-flex align-items-center justify-content-center w-100">
          <Spinner color="secondary" />
        </div>
      ) : (
        <div className="react-bootstrap-table table-detail overflow-auto">
          <table className="table table-fixed">
            <thead className="table-secondary">
              <tr>
                <th className="width-by-px-80"></th>
                <th>{t("list.table.input_data")}</th>
              </tr>
            </thead>
            <tbody>
              {
                serieData?.data?.map((file, fileIndex) => (
                  <tr key={fileIndex}>
                    <td>
                      <div className="d-flex gap-2 align-items-center">
                        <div className="d-flex align-items-center min-height-by-px-30">
                          <ButtonFileViewer handleClick={() => handleViewFile({isOpen: true, serieData, fileData: file})} id={`${simulationData.id}-${fileIndex}`}/>
                        </div>
                        <ButtonErrorWarning isShow={file?.errorList?.length > 0} errorContent={file?.errorList?.join('\n')} id={`${simulationData.id}-${fileIndex}`}/>
                        <Series6DofDashboard 
                          id={`${simulationData.id}-${fileIndex}`}
                          isLoading={file?.loading}
                          isShow={(simulationData.status === PROCESS_STATUS.SUCCEEDED && !file?.errorList?.length && simulationData.execution_file_name === SIMULATION_RESULT.SIX_DOF)}
                          serieData={file}
                          handleClickAscent={() => handleClickRenderDashboard(simulationData, file, fileIndex, DASHBOARD_TYPE.ASCENT_6DOF)}
                          handleClickDescent={() => handleClickRenderDashboard(simulationData, file, fileIndex, DASHBOARD_TYPE.DESCENT_6DOF)}
                        />
                      </div>
                    </td>
                    <td>
                      {getFileName(file.name)} {serieData?.fileResponseName ? `, ${getFileName(serieData?.fileResponseName)}` : ''}
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      )
    ) : null
  );
};

export default SixDofTable;