import React, { useEffect, useState } from "react";
import { Form, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useTranslation } from "react-i18next";
import FormInput from "../Form/FormInput";
import FormSelect from "../Form/FormSelect";
import FormTextarea from "../Form/FormTextarea";
import { useForm } from "react-hook-form";
import { useMutation } from "src/Hooks/useReactQuery";
import { useSetRecoilState } from "recoil";
import { alertState } from "src/recoil/atoms/global";
import { createRocketModel, getRocketModelDetail, getRocketModel } from "src/services/rocket-model";
import APP_ROUTES from "../../../routes/routes";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { STAGE_OPTIONS } from "src/constant/common";

const FORM_NAME = {
  NAME: "name",
  STAGE: "number_of_stage",
  REMARKS: "remarks",
};

const ModelCopyRocketModel = ({ modelId, onSuccess }) => {
  const { t } = useTranslation("rocket-model", "common");
  const [toggle, setToggle] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const showAlert = useSetRecoilState(alertState);
  const navigate = useNavigate();
  const [componentDetails, setComponentDetails] = useState([]);

  const createRocketModelMutate = useMutation({
    mutationFn: ({ data }) => createRocketModel({ data }),
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({});

  useEffect(() => {
    const fetchModelDetails = async () => {
      if (toggle && modelId) {
        const modelDetails = await getRocketModelDetail(modelId);
        if (modelDetails) {
          reset({
            [FORM_NAME.NAME]: `Copy of ${modelDetails.name || ""}`,
            [FORM_NAME.STAGE]: modelDetails.number_of_stage || "",
            [FORM_NAME.REMARKS]: modelDetails.remarks || "",
          });

          setComponentDetails(modelDetails?.component_details);
        }
      }
    };

    fetchModelDetails();
  }, [toggle, modelId, reset]);

  const handleSubmitForm = async (data) => {
    const inputData = {
      name: data.name.trim(),
      number_of_stage: data.number_of_stage,
      remarks: data.remarks,
      unprocessed_component_details: componentDetails ?? null,
    };

    setIsSubmit(true);

    try {
      const existingRocketModels = await getRocketModel({ name: inputData.name });

      if (existingRocketModels && existingRocketModels.length > 0) {
        showAlert({
          status: true,
          message: t("new.name_conflict"),
          type: "error",
        });
        setIsSubmit(false);
        return;
      }

      createRocketModelMutate.mutate(
        { data: inputData },
        {
          onSuccess: (response) => {
            setToggle(false);
            onSuccess();
            showAlert({
              status: true,
              message: t("new.create_success"),
              type: "success",
            });
            const createdRocketModelId = response?.id;
            if (createdRocketModelId) {
              navigate(`${APP_ROUTES.ROCKET_MODEL}/${createdRocketModelId}`);
            }
          },
          onSettled: () => {
            setIsSubmit(false);
          },
        }
      );
    } catch (error) {
      showAlert({
        status: true,
        message: t("new.error_checking"),
        type: "error",
      });
      setIsSubmit(false);
    }
  };

  const closeModal = () => {
    setToggle(false);
  };

  return (
    <>
      <div>
        <button
          onClick={() => {
            reset();
            setToggle(true);
          }}
          className="btn btn-blank border-0"
          data-tooltip-id={`copy-${modelId}`}
          data-tooltip-content={t("list.table.copy")}
          onFocus={(e) => {
            // immediately blurs after focus
            e.target.blur();
          }}
        >
          <i className="bi bi-copy f-s-20 text-dark" />
        </button>
        <Tooltip id={`copy-${modelId}`} className="text-break-space" />
      </div>

      <Modal centered isOpen={toggle} toggle={closeModal} size="lg" backdrop="static">
        <ModalHeader toggle={closeModal}>{t("new.title")}</ModalHeader>
        <ModalBody className="p-b-0">
          <Form className="p-x-15">
            <div className="row">
              <div className="col-md-12">
                <FormInput
                  register={register}
                  label={t("new.name")}
                  name={FORM_NAME.NAME}
                  placeholder={t("new.name_placeholder")}
                  errors={errors?.[FORM_NAME.NAME]?.message}
                  requiredMessage={t("new.name_required")}
                  required
                  maxLength={255}
                  style={{ length: "50%" }}
                  maxLengthMessage={t("new.name_maxlength")}
                />
                <FormSelect
                  label={t("new.stage")}
                  name={FORM_NAME.STAGE}
                  placeholder={t("new.stage_placeholder")}
                  control={control}
                  errors={errors?.[FORM_NAME.STAGE]?.message}
                  required
                  options={STAGE_OPTIONS}
                  requiredMessage={t("new.stage_required")}
                  disabled
                />
                <FormTextarea
                  register={register}
                  label={t("new.remarks")}
                  name={FORM_NAME.REMARKS}
                  placeholder={t("new.remarks_placeholder")}
                  errors={errors?.[FORM_NAME.REMARKS]?.message}
                />
              </div>
            </div>
          </Form>
        </ModalBody>
        <ModalFooter className="justify-content-center p-t-10 p-b-20">
          <button className="btn btn-sm btn-secondary m-x-10 width-by-px-120" onClick={closeModal}>
            {t("new.cancel")}
          </button>
          <button
            className="btn btn-sm btn-primary m-x-10 width-by-px-120"
            disabled={isSubmit}
            onClick={handleSubmit((data) => handleSubmitForm(data))}
          >
            {t("new.register")}
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ModelCopyRocketModel;
