import React from "react";
import routeGuard from "src/HOC/routeGuard";
import EngineComponentList, {
} from "src/components/EngineComponent/list";

const EngineComponent = () => {

  return (
    <EngineComponentList
    />
  );
};

export default routeGuard(EngineComponent);