import { useMutation } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Spinner } from "reactstrap";
import { useSetRecoilState } from "recoil";
import { LOADING_STATUS } from "src/constant/common";
import { authState } from "src/recoil/atoms/auth";
import APP_ROUTES from "src/routes/routes";
import { getMe } from "src/services/auth";
import { ACCESS_TOKEN, removeLocalStorage } from "src/utils/storage";

const routeGuard = (WrappedComponent) => (wrappedProps) => {
  const setAuth = useSetRecoilState(authState);
  const [authenticated, setAuthenticated] = useState(LOADING_STATUS.LOADING);
  const navigate = useNavigate();

  const { mutate } = useMutation({ mutationFn: () => getMe() });

  useEffect(() => {
    mutate(null, {
      onSuccess: (data) => {
        setAuthenticated(LOADING_STATUS.AUTHENTICATED);
        setAuth(data);
      },
      onError: () => {
        setAuthenticated(LOADING_STATUS.UNAUTHENTICATED);
        navigate(APP_ROUTES.LOGIN);
        removeLocalStorage(ACCESS_TOKEN);
        setAuth({});
      },
    });
  }, []);

  if (authenticated === LOADING_STATUS.LOADING) {
    <div className="full-page d-flex align-items-center justify-content-center">
      <Spinner color="secondary" />
    </div>;
  }

  return (
    authenticated === LOADING_STATUS.AUTHENTICATED && (
      <WrappedComponent {...wrappedProps} />
    )
  );
};

export default routeGuard;
