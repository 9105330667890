import React from "react";
import classNames from "classnames";
import { Spinner } from "reactstrap";

const Loading = ({ loading, children, customClass = "" }) => (
  <div className="table-spin-nested-loading">
    {loading && (
      <div>
        <div className={classNames("spin spin-spinning", customClass)}>
          <div className="spin-dot spin-dot-spin">
            <Spinner />
          </div>
        </div>
      </div>
    )}
    <div className={classNames("position-relative", { "spin-blur": loading })}>{children}</div>
  </div>
);

export default Loading;
