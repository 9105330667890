import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Login from "src/components/Login";
import APP_ROUTES from "src/routes/routes";
import { ACCESS_TOKEN, getLocalStorage } from "src/utils/storage";

const LoginPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (getLocalStorage(ACCESS_TOKEN)) {
      navigate(APP_ROUTES.HOME);
    }
  }, []);

  return (
    <div id="wrapper">
      <div className="login full-page">
        <Login />
      </div>
    </div>
  );
};

export default LoginPage;
