import React, { useState } from "react";
import { 
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import FormInput from "../Form/FormInput";
import TestAnalysisUpload from "../Form/TestAnalysisUpload";
import { useForm } from "react-hook-form";
import {
  createTestAnalysisItem
} from "src/services/test-analysis";
import { useMutation } from "src/Hooks/useReactQuery";
import { useSetRecoilState } from 'recoil';
import { alertState } from 'src/recoil/atoms/global';
import { PATTERN_NEGATIVE_NUMBER, UPLOAD_TYPE } from 'src/constant/common';

const FORM_NAME = {
  NO_WIND_PATH: "no_wind_path",
  BLOW_PATH: "blow_path",
  ATTACK_ANGLE: "attack_angle",
  ROLL_ANGLE: "roll_angle",
};

const FORM_NAME_COEFFICIENT = {
  CALCULATED_PATH: "calculated_path",
};

const ModalAddTestAnalysisItem = ({onSuccess, testAnalysisData}) => {
  const { t } = useTranslation("test-analysis");
  const [toggle, setToggle] = useState(false);
  const [toggleCoefficient, setToggleCoefficient] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [currentTestAnalysisId, setCurrentTestAnalysisId] = useState(null);
  
  const [isSubmit, setIsSubmit] = useState(false);
  const showAlert = useSetRecoilState(alertState);

  const createTestAnalysisItemMutate = useMutation({
    mutationFn: ({ data }) => createTestAnalysisItem({ data }),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    clearErrors,
  } = useForm({});

  const {
    register: registerCoefficient,
    handleSubmit: handleSubmitCoefficient,
    formState: { errors: errorCoefficient },
    reset: resetCoefficient,
    setValue: setValueCoefficient,
    clearErrors: clearErrorCoefficient,
  } = useForm({});

  const handleSubmitForm = (data) => {
    const inputData = {
      test_analysis_id: currentTestAnalysisId,
      config: data,
      type: UPLOAD_TYPE.RAW
    };
    const config = testAnalysisData?.process?.config?.test_analysis_item || {};
    Object.keys(config).forEach(key => {
      if (config[key] === 'number') {
        inputData.config[key] = parseFloat(inputData.config[key]);
      }
    });

    setIsSubmit(true);
    createTestAnalysisItemMutate.mutate(
      { data: inputData },
      {
        onSuccess: () => {
          setToggle(false);
          onSuccess(currentTestAnalysisId);
          showAlert({
            status: true,   
            message: t('new.create_success'), 
            type: 'success',
          });
        },
        onSettled: () => {
          setIsSubmit(false);
        },
      }
    ); 
  };

  const handleSubmitFormCoefficient = (data) => {
    const inputData = {
      test_analysis_id: currentTestAnalysisId,
      type: UPLOAD_TYPE.COEFFICIENT,
      calculated_path: data[FORM_NAME_COEFFICIENT.CALCULATED_PATH]
    };
    setIsSubmit(true);
    createTestAnalysisItemMutate.mutate(
      { data: inputData },
      {
        onSuccess: () => {
          setToggleCoefficient(false);
          onSuccess(currentTestAnalysisId);
          showAlert({
            status: true,   
            message: t('new.create_success'), 
            type: 'success',
          });
        },
        onSettled: () => {
          setIsSubmit(false);
        },
      }
    ); 
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  
  return (
    <>
      <Dropdown
        isOpen={dropdownOpen}
        toggle={toggleDropdown}
        className="align-self-center"
      >
        <DropdownToggle
          data-toggle="dropdown"
          tag="button"
          className="border-0 bg-transparent"
        >
          <i className="bi bi-plus-square text-dark fs-18"></i>
        </DropdownToggle>
        
        <DropdownMenu
          end={true}
          className="topbar-dropdown-menu profile-dropdown-items"
        >
          <div onClick={toggleDropdown}>
            <div
              role="button"
              className="dropdown-item"
              onClick={() => {
                setCurrentTestAnalysisId(testAnalysisData.id);
                reset();
                setToggle(true);
              }}
            >
              <span>{t("new.add_original_result")}</span>
            </div>
            <div
              role="button"
              className="dropdown-item"
              onClick={() => {
                setCurrentTestAnalysisId(testAnalysisData.id);
                resetCoefficient();
                setToggleCoefficient(true);
              }}
            >
              <span>{t("new.add_calculated_result")}</span>
            </div>
          </div>
        </DropdownMenu>
      </Dropdown>

      <Modal centered isOpen={toggle} toggle={() => setToggle(false)} size="lg" backdrop="static">
        <ModalHeader>{t("new.data_register")}</ModalHeader>
        <ModalBody className="p-b-0">
          <Form className="p-x-15">
            <div className="row">
              <div className="col-md-12">
                <TestAnalysisUpload
                  name={FORM_NAME.NO_WIND_PATH}
                  setValue={setValue}
                  label={t("new.no_wind_result")}
                  requiredMessage={t("new.no_wind_result_required")}
                  required
                  clearErrors={clearErrors}
                  errors={errors}
                  register={register}
                />
                <TestAnalysisUpload
                  name={FORM_NAME.BLOW_PATH}
                  setValue={setValue}
                  label={t("new.blow_result")}
                  requiredMessage={t("new.blow_result_required")}
                  required
                  clearErrors={clearErrors}
                  errors={errors}
                  register={register}
                />
              
                <FormInput
                  register={register}
                  pattern={{
                    value: PATTERN_NEGATIVE_NUMBER,
                    message: t("new.attack_angle_validate_number"),
                  }}
                  type="number"
                  label={t("new.attack_angle")}
                  name={FORM_NAME.ATTACK_ANGLE}
                  placeholder={t("new.attack_angle_placeholder")}
                  errors={errors?.[FORM_NAME.ATTACK_ANGLE]?.message}
                  requiredMessage={t("new.attack_angle_required")}
                  required
                />
                <FormInput
                  register={register}
                  type="number"
                  pattern={{
                    value: PATTERN_NEGATIVE_NUMBER,
                    message: t("new.roll_angle_validate_number"),
                  }}
                  label={t("new.roll_angle")}
                  name={FORM_NAME.ROLL_ANGLE}
                  placeholder={t("new.roll_angle_placeholder")}
                  errors={errors?.[FORM_NAME.ROLL_ANGLE]?.message}
                  requiredMessage={t("new.roll_angle_required")}
                  required
                />
              </div>
            </div>
          </Form>
        </ModalBody>
        <ModalFooter className="justify-content-center p-t-10 p-b-20">
          <button
            className="btn btn-sm btn-secondary m-x-10 width-by-px-120"
            onClick={() => {
              setToggle(false);
              document.body.click();
            }}
          >
            {t("new.cancel")}
          </button>
          <button
            className="btn btn-sm btn-primary m-x-10 width-by-px-120"
            disabled={isSubmit}
            onClick={handleSubmit((data) => handleSubmitForm(data))}
          >
            {t("new.register")}
          </button>
        </ModalFooter>
      </Modal>

      <Modal centered isOpen={toggleCoefficient} toggle={() => setToggleCoefficient(false)} size="lg" backdrop="static">
        <ModalHeader>{t("new.data_register")}</ModalHeader>
        <ModalBody className="p-b-0">
          <Form className="p-x-15">
            <div className="row">
              <div className="col-md-12">
                <TestAnalysisUpload
                  name={FORM_NAME_COEFFICIENT.CALCULATED_PATH}
                  setValue={setValueCoefficient}
                  label={t("new.coefficient_result")}
                  requiredMessage={t("new.coefficient_result_required")}
                  required
                  clearErrors={clearErrorCoefficient}
                  errors={errorCoefficient}
                  register={registerCoefficient}
                  uploadType={UPLOAD_TYPE.COEFFICIENT}
                />
              </div>
            </div>
          </Form>
        </ModalBody>
        <ModalFooter className="justify-content-center p-t-10 p-b-20">
          <button
            className="btn btn-sm btn-secondary m-x-10 width-by-px-120"
            onClick={() => {
              setToggleCoefficient(false);
            }}
          >
            {t("new.cancel")}
          </button>
          <button
            className="btn btn-sm btn-primary m-x-10 width-by-px-120"
            disabled={isSubmit}
            onClick={handleSubmitCoefficient((data) => handleSubmitFormCoefficient(data))}
          >
            {t("new.register")}
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ModalAddTestAnalysisItem;
