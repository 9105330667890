import React from "react";
import { 
  Spinner,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { ButtonFileViewer, ButtonErrorWarning, SingleDashboard } from "src/components/Common/Button";
import SeriesDashboard from "src/components/Common/Dropdown/SeriesDashboard";
import { PROCESS_STATUS, DASHBOARD_STATUS, RETRY_TIME, DASHBOARD_TYPE, SIMULATION_RESULT } from "src/constant/common";
import { extractIDsFromString } from "src/utils/common";
import { useSetRecoilState } from 'recoil';
import { alertState } from 'src/recoil/atoms/global';

import {
  getDashboardLink,
  getSensitivityDashboardLink,
} from "src/services/simulator-flight";

const ThreeDofTable = ({isLoading, isShow = true, isError, serieData, handleViewFile, simulationData, updateData}) => {
  const { t } = useTranslation(["flight-simulator","common"]);
  const showAlert = useSetRecoilState(alertState);

  const statusFieldByType = {
    [DASHBOARD_TYPE.MAIN] : 'dashboard_status',
    [DASHBOARD_TYPE.SENSITIVITY] : 'sensitivity_analysis_dashboard_status',
  };
  const urlFieldByType = {
    [DASHBOARD_TYPE.MAIN] : 'dashboard_url',
    [DASHBOARD_TYPE.SENSITIVITY] : 'sensitivity_analysis_dashboard_url',
  }

  const handleUpdateSerieData = (index, field, newValue) => {
    const newData = {...serieData};
    newData.data[index][field] = newValue;
    updateData(oldVal => ({
      ...oldVal,
      [simulationData.id]: newData
    }));
  };

  const handleClickRenderDashboard = async (simulationData, serieItemData, serieIndex, type = DASHBOARD_TYPE.MAIN) => {
    if (!serieItemData) return;
    if (serieItemData[statusFieldByType[type]] === DASHBOARD_STATUS.SUCCEEDED && serieItemData[urlFieldByType[type]]) {
      window.open(serieItemData[urlFieldByType[type]], '_blank');
      return;
    }
    handleUpdateSerieData(serieIndex, 'loading', true);
    const [serieId, serieName] = extractIDsFromString(serieItemData.serie_file_name);
    const data = {
      id: simulationData.id,
      params: {
        serie_id: serieId,
        serie_name: serieName
      }
    }
    await checkDashboardStatus(serieItemData, serieIndex,  data, 1, type);
  };

  const checkDashboardStatus = async (serieItemData, serieIndex, params, retryCount, type) => {
    try {
      const res = await getDashboardResponse(type, params);
      if (await handleInProgressStatus(res, serieItemData, serieIndex, params, retryCount, type)) return;
      updateDashboardData(serieIndex, type, res.data);
      openDashboardIfSucceeded(res.data);
    } catch (e) {
      handleError(serieIndex, e);
    }
  }

  const getDashboardResponse = (type, params) => 
    type === DASHBOARD_TYPE.MAIN ? getDashboardLink(params) : getSensitivityDashboardLink(params);

  const handleInProgressStatus = async (res, serieItemData, serieIndex, params, retryCount, type) => {
    if (res.data.status !== DASHBOARD_STATUS.INPROGRESS) return false;
    if (retryCount === RETRY_TIME) {
      showAlert({ status: true, message: t("over_time"), type: 'danger' });
      handleUpdateSerieData(serieIndex, 'loading', false);
      return true;
    }
    await new Promise(resolve => setTimeout(resolve, 3000));
    await checkDashboardStatus(serieItemData, serieIndex, params, retryCount + 1, type);
    return true;
  }

  const updateDashboardData = (serieIndex, type, data) => {
    handleUpdateSerieData(serieIndex, 'loading', false);
    handleUpdateSerieData(serieIndex, statusFieldByType[type], data.status);
    handleUpdateSerieData(serieIndex, urlFieldByType[type], data.url);
  }

  const openDashboardIfSucceeded = (data) => {
    if (data.status === DASHBOARD_STATUS.SUCCEEDED) {
      window.open(data.url, '_blank');
    }
  }

  const handleError = (serieIndex, e) => {
    handleUpdateSerieData(serieIndex, 'loading', false);
    showAlert({
      status: true,   
      message: e?.response?.data?.error?.message || e?.response?.data?.message, 
      type: 'danger',
    });
  }

  const isSensitivityAnalysisTrue = (obj) => {
    if (typeof obj !== 'object' || obj === null) return false;
    return Object.keys(obj).some(key => 
      (typeof obj[key] === 'object' && isSensitivityAnalysisTrue(obj[key])) || 
      (key === 'is_sensitivity_analysis' && obj[key]?.value === 1)
    );
  };

  return (
    isShow ? (
      isError ? (
        <div className="p-2 d-flex align-items-center justify-content-center w-100 height-by-px-70">
          {t("common:network_error")}
        </div>
      ) : isLoading ? (
        <div className="p-2 d-flex align-items-center justify-content-center w-100">
          <Spinner color="secondary" />
        </div>
      ) : (
        <div className="react-bootstrap-table table-detail overflow-auto">
          <table className="table">
            <thead className="table-secondary">
              <tr>
                <th className="width-by-px-80"></th>
                {serieData?.columns?.map((col, indexCol) => (
                  <th key={indexCol}>{col.text}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {
                serieData?.data?.map((serieRow, indexSerie) => (
                  <tr key={indexSerie}>
                    <td>
                      <div className="d-flex gap-2 align-items-center min-height-by-px-30">
                        <ButtonFileViewer handleClick={() => handleViewFile({isOpen: true, serieData, fileData: serieRow})} id={`${simulationData.id}-${indexSerie}`}/>
                        <ButtonErrorWarning isShow={serieRow?.errorList?.length > 0} errorContent={serieRow.errorList.join('\n')} id={`${simulationData.id}-${indexSerie}`}/>
                        <SeriesDashboard 
                          id={`${simulationData.id}-${indexSerie}`}
                          isLoading={serieRow?.loading}
                          isShow={(
                            simulationData.status === PROCESS_STATUS.SUCCEEDED &&
                            !serieRow?.errorList?.length &&
                            simulationData.execution_file_name === SIMULATION_RESULT.PAYLOAD &&
                            isSensitivityAnalysisTrue(serieRow.jsonObject)
                          )}
                          serieData={serieRow}
                          handleClickFlight={() => handleClickRenderDashboard(simulationData, serieRow, indexSerie, DASHBOARD_TYPE.MAIN)}
                          handleClickSensitivity={() => handleClickRenderDashboard(simulationData, serieRow, indexSerie, DASHBOARD_TYPE.SENSITIVITY)}
                        />
                        <SingleDashboard 
                          id={`${simulationData.id}-${indexSerie}`}
                          isLoading={serieRow?.loading}
                          isShow={
                            simulationData.status === PROCESS_STATUS.SUCCEEDED && 
                            !serieRow?.errorList?.length && 
                            (simulationData.execution_file_name !== SIMULATION_RESULT.PAYLOAD || !isSensitivityAnalysisTrue(serieRow.jsonObject))
                          }
                          isReady={!!serieRow.dashboard_url}
                          handleClick={() => handleClickRenderDashboard(simulationData, serieRow, indexSerie, DASHBOARD_TYPE.MAIN)}
                        />
                      </div>
                    </td>
  
                    {serieData?.columns?.map((col, indexCol) => (
                      <td key={indexCol}>{serieRow.paramList[col.dataField]}</td>
                    ))}
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      )
    ) : null
  );
};

export default ThreeDofTable;