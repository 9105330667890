import React, { useState } from "react";
import { 
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Spinner,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import { DASHBOARD_TYPE } from "src/constant/common";

const Series6DofDashboard = ({handleClickAscent, handleClickDescent, id, isLoading, isShow = true, serieData }) => {
  const { t } = useTranslation("flight-simulator");
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const isDashboardReady = (type) => {
    return !!serieData.simulator_dashboards.filter(item => item.dashboard_type === type)?.[0]?.dashboard_url;
  }

  return (
    isShow ? (
      isLoading ? (
        <Spinner size="sm" color="secondary" />
      ) : (
        <>
          <Dropdown
            isOpen={dropdownOpen}
            toggle={toggleDropdown}
            className="align-self-center"
            data-tooltip-id={`dashboard-serie-${id}`}
            data-tooltip-content={t("list.table.dashboard")}
            direction="end"
          >
            <DropdownToggle
              data-toggle="dropdown"
              tag="button"
              className="border-0 bg-transparent"
            >
              <i className={`bi bi-speedometer2 ${serieData.simulator_dashboards.length > 1 ? 'text-success' : 'text-dark'} f-s-20`}/>
            </DropdownToggle>
            
            <DropdownMenu
              className="topbar-dropdown-menu profile-dropdown-items"
            >
              <div onClick={toggleDropdown}>
                <div
                  role="button"
                  className={`dropdown-item ${isDashboardReady(DASHBOARD_TYPE.ASCENT_6DOF) ? 'text-success' : 'text-dark'}`}
                  onClick={handleClickAscent}
                >
                  <span>{t('list.table.ascending_dashboard')}</span>
                </div>
                <div
                  role="button"
                  className={`dropdown-item ${isDashboardReady(DASHBOARD_TYPE.DESCENT_6DOF) ? 'text-success' : 'text-dark'}`}
                  onClick={handleClickDescent}
                >
                  <span>{t('list.table.descending_dashboard')}</span>
                </div>
              </div>
            </DropdownMenu>
          </Dropdown>
          <Tooltip id={`dashboard-serie-${id}`} className="text-break-space" />
        </>
      )
    ) : null
  );
};

export default Series6DofDashboard;
