import React, { useCallback, useEffect } from "react";
import APP_ROUTES from "src/routes/routes";
import routeGuard from "src/HOC/routeGuard";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import RocketModelDetail from "src/components/RocketModel/detail";

const RocketModel = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page");

  const getRedirectURL = useCallback(() => 
    `${APP_ROUTES.ROCKET_MODEL}?id=${id}${page ? "&page=" + page : ""}`,
    [id, page]
  );
  

  useEffect(() => {
    window.onpopstate = () => {
      navigate(getRedirectURL());
    };
  }, []);


  return <RocketModelDetail getRedirectURL={getRedirectURL} />;
};

export default routeGuard(RocketModel);
