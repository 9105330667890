import React, { Fragment, useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  CardHeader,
  Button,
  Collapse,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import useUrlState from "@ahooksjs/use-url-state";
import { useSetRecoilState } from "recoil";
import { alertState } from "src/recoil/atoms/global";
import { useTranslation } from "react-i18next";
import ModalEditEngineComponent from "src/components/Common/Modal/ModalEditEngineComponent";
import { Link, useNavigate } from "react-router-dom";
import BulkThrustCalculation from "src/components/Common/Modal/ModalThrustCalculation/BulkThrustCalculation";

import { createThrustCalculation, getThrustCalculation, getThrustCalculationDetail } from "src/services/thrust-calculation";
import { ACTION_NAME, PAGINATION_DEFAULT, PROCESS_STATUS, REFETCH_INTERVAL_TIME } from "src/constant/common";
import {
  handleScrollTo,
  handleScrollToTop,
  mergeArrays,
  reverseTransformThrustCalculation,
  transformThrustCalculation,
  uniqueArray,
} from "src/utils/common";
import { useMutation, useQuery } from "src/Hooks/useReactQuery";
import TableCommon from "src/components/Common/Table";

import LabelStatus from "src/components/Common/LabelStatus";
import { Tooltip } from "react-tooltip";
import ModalThrustCalculation from "src/components/Common/Modal/ModalThrustCalculation";
import APP_ROUTES from "src/routes/routes";

const ThrustCalculationList = ({ rocketId, pageSize, bulkThrustCalculationMutation, stage, getLogs }) => {
  const { t } = useTranslation(["thrust-calculation", "common", "engine-component"]);
  const showAlert = useSetRecoilState(alertState);
  const navigate = useNavigate();

  const [params, setParams] = useUrlState(
    {},
    {
      navigateMode: "replace",
      parseOptions: { parseNumbers: true },
      stringifyOptions: { skipNull: true },
    }
  );
  const [pageCurrent, setPageCurrent] = useState(1);

  const [toggle, setToggle] = useState(false);
  const [showBulkModal, setShowBulkModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [allowScrollToId, setAllowScrollToId] = useState(true);
  const [defaultValues, setDefaultValues] = useState(null);
  const [showContent, setShowContent] = useState(true);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isAddNew, setIsAddNew] = useState(false);

  const { data: dataThrustCalculation, refetch } = useQuery(
    [rocketId ? `getThrustCalculation_${rocketId}` : "getThrustCalculation"],
    () =>
      getThrustCalculation({
        rocketId,
        page: rocketId ? pageCurrent : params.page,
        limit: pageSize || PAGINATION_DEFAULT.pageSize,
        q: params?.condition,
      }),
    {
      onSuccess: () => {
        if (params?.id && allowScrollToId) {
          handleScrollTo(0, 90, `row${params.id}`);
          setAllowScrollToId(false);
        }

        if (isAddNew) {
          showAlert({
            status: true,
            message: t("new.create_success"),
            type: "success",
          });
          setIsAddNew(false);
        }
      },
      onSettled: () => setIsLoading(false),
      refetchInterval: REFETCH_INTERVAL_TIME,
    }
  );

  const getThrustCalculationDetailMutate = useMutation({
    mutationFn: ({ id }) => getThrustCalculationDetail(id),
  });

  const createTestAnalysisMutate = useMutation({
    mutationFn: ({ data }) => createThrustCalculation({ data }),
  });

  const columnsCollapse = [
    {
      dataField: "name",
      text: t("list.table.name"),
      headerClass: "width-by-percent-13",
    },
    {
      dataField: "stage",
      text: t("list.table.stage"),
      headerClass: "width-by-percent-5",
    },
    {
      dataField: "mode",
      text: t("list.table.mode"),
      headerClass: "width-by-percent-13",
      formatter: (mode) => <span className="text-capitalize">{mode}</span>,
    },
    {
      dataField: "propellant",
      text: t("list.table.propellant"),
      headerClass: "width-by-percent-13",
      formatter: (_, row) => <>{row.propellant?.value ? `${row.propellant?.value} (${row.propellant_percentage?.value}%)` : "-"}</>,
    },
    {
      dataField: "",
      text: t("list.table.total_isp"),
      headerClass: "width-by-percent-11",
      formatter: (_, row) => <>{row?.total_isp?.value ?? "-"}</>,
    },
    {
      dataField: "",
      text: t("list.table.nozzle_area"),
      headerClass: "width-by-px-200",
      formatter: (_, row) => <>{row?.nozzle_area?.value ?? "-"}</>,
    },
    {
      dataField: "",
      text: t("list.table.burning_time"),
      headerClass: "width-by-percent-11",
      formatter: (_, row) => <>{row?.burning_time?.value ?? "-"}</>,
    },
    {
      dataField: "engine_component",
      text: t("list.table.engine_component"),
      headerClass: "width-by-px-250",
      formatter: (_, row) => (
        <>
          {row.engine_components?.map((component, index) => (
            <div key={index}>
              <ModalEditEngineComponent modelId={component?.component_id} onSuccess={() => {}} label={component.component.name} />
            </div>
          ))}
        </>
      ),
    },
    {
      dataField: "number_of_units",
      text: t("list.table.number_of_units"),
      headerClass: "",
      formatter: (_, row) => (
        <>
          {row.engine_components?.map((component, index) => (
            <span key={index}>
              {component.number_of_unit}
              <br />
            </span>
          ))}
        </>
      ),
    },
  ];

  const columns = [
    {
      dataField: "id",
      text: t("list.table.id"),
      headerClass: "width-by-percent-7",
    },
    {
      dataField: "name",
      text: t("list.table.name"),
      headerClass: "width-by-percent-20",
    },
    {
      dataField: "status",
      text: t("list.table.status"),
      headerClass: "width-by-percent-17 text-center",
      formatter: (_, row) => (
        <LabelStatus
          key={row.id}
          row={row}
          field={row.status === PROCESS_STATUS.SUCCEEDED ? "status" : "status"}
          containerClass="text-center"
          errorList={row?.error?.code ? row?.error?.errors?.map((i) => `[${i?.error_id}] ${i?.message}`) : [row?.error]}
        />
      ),
    },
    {
      dataField: "rocket_model",
      text: t("list.table.rocket_model"),
      headerClass: "width-by-percent-15",
      formatter: (_, row) => (
        <>
          {uniqueArray(row?.rocket_models, "id")?.length > 0 ? (
            <div className="">
              {uniqueArray(row?.rocket_models, "id")?.map((rocket, index) => (
                <Fragment key={index}>
                  {rocket?.id === rocketId ? (
                    <span
                      className="btn-link p-0 border-0 inline-block cursor-pointer"
                      onClick={() => {
                        const path = `${APP_ROUTES.ROCKET_MODEL}/${rocket?.id}#engine-detail`;
                        navigate(path);
                        navigate(0);
                      }}
                    >
                      {rocket?.name}
                    </span>
                  ) : (
                    <Link className="d-block" to={`${APP_ROUTES.ROCKET_MODEL}/${rocket?.id}#engine-detail`}>
                      {rocket?.name}
                    </Link>
                  )}
                </Fragment>
              ))}
            </div>
          ) : (
            <span>-</span>
          )}
        </>
      ),
    },
    {
      dataField: "data",
      text: t("list.table.data"),
      headerClass: "width-by-percent-31",
      formatter: (_, __, { isCollapse, toggleCollapse }) => (
        <Button outline size="sm" className="px-1 btn-collapse height-by-px-36" onClick={() => toggleCollapse()}>
          <i className={`bi bi-chevron-${isCollapse ? "down" : "up"} f-s-14 fw-bold text-dark`} />
        </Button>
      ),
      collapseRow: (data) => (
        <div>
          <TableCommon
            columns={columnsCollapse}
            data={mergeArrays(data?.input_data?.modes, data?.output_data?.data ?? data?.output_data) || []}
            tableClassName="table-collapse mb-0"
            customClassName="overflow-auto simulation-table table-detail"
            classTableHead="table-secondary"
            hasPagination={false}
          />
        </div>
      ),
    },
    {
      dataField: "",
      text: "",
      headerClass: "width-by-percent-5",
      formatter: (_, row) =>
        row.result_url && row?.status === PROCESS_STATUS.SUCCEEDED && !rocketId ? (
          <div>
            <a href={row.result_url} role="button" data-tooltip-id={`download-${row?.id}`} data-tooltip-content={t("list.table.download")}>
              <i className="bi bi-cloud-download f-s-20 text-dark" />
            </a>
            <Tooltip id={`download-${row?.id}`} className="text-break-space" />
          </div>
        ) : (
          <></>
        ),
    },
    {
      dataField: "",
      text: "",
      headerClass: "width-by-percent-5",
      formatter: (_, row) =>
        row?.status === PROCESS_STATUS.SUCCEEDED && !rocketId ? (
          <div>
            <button
              onClick={() => {
                getThrustCalculationDetailMutate.mutate(
                  { id: row?.id },
                  {
                    onSuccess: (data) => {
                      setDefaultValues(reverseTransformThrustCalculation(data));
                      setToggle(true);
                    },
                  }
                );
              }}
              data-tooltip-id={`copy-${row?.id}`}
              data-tooltip-content={t("list.table.copy")}
              className="btn btn-blank border-0"
              onFocus={(e) => {
                // immediately blurs after focus
                e.target.blur();
              }}
            >
              <i className="bi bi-copy f-s-20 text-dark" />
            </button>
            <Tooltip id={`copy-${row?.id}`} className="text-break-space" />
          </div>
        ) : (
          <></>
        ),
    },
  ];

  useEffect(() => {
    if (!toggle) {
      setDefaultValues(null);
    }
  }, [toggle]);

  useEffect(() => {
    setIsLoading(true);
    refetch();
  }, [params]);

  useEffect(() => {
    setIsLoading(true);
    refetch();
  }, [pageCurrent]);

  const onChangePage = async (value) => {
    if (rocketId) {
      setPageCurrent(value);
      handleScrollToTop("card-engine");
      return;
    }
    setParams({ ...params, page: value });
    handleScrollToTop();
  };

  const onSubmit = (body, callback) => {
    const data = {
      ...transformThrustCalculation(body),
      action: ACTION_NAME.RUN_SIMULATION,
    };
    if (rocketId) {
      data.rocket_model_id = rocketId;
    }

    createTestAnalysisMutate.mutate(
      { data },
      {
        onSuccess: () => {
          setToggle(false);
          setIsLoading(true);
          setIsAddNew(true);

          if (rocketId) {
            if (pageCurrent === 1) {
              refetch();
            } else {
              setPageCurrent(1);
            }
            handleScrollToTop("card-engine");
          } else {
            if (params?.page === 1) {
              refetch();
            } else {
              setParams({ ...params, page: 1 });
            }
            handleScrollToTop();
          }
          getLogs?.();
        },
        onSettled: () => {
          callback?.();
        },
      }
    );
  };

  const handleBulkData = (data, callback) => {
    bulkThrustCalculationMutation.mutate(
      { data },
      {
        onSuccess: () => {
          setShowBulkModal(false);
          setIsLoading(true);
          refetch();
          getLogs?.();
          showAlert({
            status: true,
            message: t("new.bulk_success"),
            type: "success",
          });
        },
        onSettled: () => callback?.(),
      }
    );
  };

  if (rocketId) {
    return (
      <Row id="engine-detail">
        <Col md={12}>
          <Card id="card-engine">
            <CardHeader className="bg-white border-0 d-flex justify-content-between align-items-center">
              <div className="btn btn-blank text-dark border-0 p-0 header-title mb-0" onClick={() => setShowContent(!showContent)}>
                {t("list.engine_detail")} <i className={showContent ? "bi bi-caret-up-fill" : "bi bi-caret-down-fill"}></i>
              </div>

              <Dropdown isOpen={showDropdown} toggle={() => setShowDropdown(!showDropdown)}>
                <DropdownToggle color="primary">
                  {t("list.redirect_new")} <i className="bi bi-caret-down-fill"></i>
                </DropdownToggle>
                <DropdownMenu className="topbar-dropdown-menu profile-dropdown-items">
                  <DropdownItem onClick={() => setToggle(true)}>{t("list.created_new")}</DropdownItem>
                  <DropdownItem onClick={() => setShowBulkModal(true)}>{t("list.select_existing")}</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </CardHeader>

            <Collapse isOpen={showContent}>
              <CardBody className="bg-white">
                <TableCommon
                  loading={isLoading}
                  columns={columns}
                  data={dataThrustCalculation?.items || []}
                  onChangePage={onChangePage}
                  tableFixed
                  tableClassName="striped table-collapse"
                  customClassName="overflow-auto simulation-table"
                  pageCurrent={(pageCurrent || PAGINATION_DEFAULT.page) - 1}
                  pageCount={dataThrustCalculation?.pagination?.total_page}
                  totalItem={dataThrustCalculation?.pagination?.total}
                  pageSize={pageSize}
                />
              </CardBody>
            </Collapse>
          </Card>
        </Col>
        {showBulkModal && (
          <BulkThrustCalculation
            toggle={showBulkModal}
            setToggle={setShowBulkModal}
            rocketId={rocketId}
            pageSize={pageSize}
            handleBulkData={handleBulkData}
            stage={stage}
          />
        )}
        {toggle && (
          <ModalThrustCalculation
            toggle={toggle}
            setToggle={setToggle}
            onSubmit={onSubmit}
            defaultValues={defaultValues}
            stage={stage}
            rocketId={rocketId}
          />
        )}
      </Row>
    );
  }

  return (
    <Container fluid>
      <Row>
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-2">
                <h2 className="header-title mb-0">{t("list.title")}</h2>
              </div>
              <Row>
                <Col md={12}>
                  <Card>
                    <div className="d-flex justify-content-end">
                      <CardHeader className="bg-white border-0">
                        <button onClick={() => setToggle(true)} className="btn btn-primary text-capitalize">
                          {t("list.redirect_new")}
                        </button>
                      </CardHeader>
                    </div>
                    <CardBody className="bg-white">
                      <TableCommon
                        loading={isLoading}
                        columns={columns}
                        data={dataThrustCalculation?.items || []}
                        onChangePage={onChangePage}
                        tableFixed
                        tableClassName="striped table-collapse"
                        customClassName="overflow-auto simulation-table"
                        pageCurrent={((rocketId ? pageCurrent : params?.page) || PAGINATION_DEFAULT.page) - 1}
                        pageCount={dataThrustCalculation?.pagination?.total_page}
                        totalItem={dataThrustCalculation?.pagination?.total}
                        pageSize={pageSize}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Row>

      {toggle && <ModalThrustCalculation toggle={toggle} setToggle={setToggle} onSubmit={onSubmit} defaultValues={defaultValues} />}
    </Container>
  );
};

export default ThrustCalculationList;
