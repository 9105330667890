import React, { useEffect, useState } from "react";
import { Form, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useTranslation } from "react-i18next";
import FormInput from "../Form/FormInput";
import { useForm } from "react-hook-form";
import { useSetRecoilState } from "recoil";
import { alertState } from "src/recoil/atoms/global";
import { editEngineComponent, getEngineComponentDetail } from "src/services/engine-component";
import APP_ROUTES from "../../../routes/routes";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { PATTERN_GRAVITY_OF_CENTER, PATTERN_NEGATIVE_NUMBER, PATTERN_NUMBER } from "src/constant/common";

const FORM_NAME = {
  NAME: "name",
  CENTERGRAVITY: "center_of_gravity",
  ISP: "isp",
  LENGTH: "length",
  THRUST: "thrust",
  NOZZLE_EXIT: "nozzle_exit_diameter",
  MASS: "mass",
  MEMO: "memo",
};

const ModalEditEngineComponent = ({ modelId, onSuccess, label }) => {
  const { t } = useTranslation("engine-component", "common");
  const [toggle, setToggle] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const showAlert = useSetRecoilState(alertState);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({});

  useEffect(() => {
    const fetchModelDetails = async () => {
      if (toggle && modelId) {
        const modelDetails = await getEngineComponentDetail(modelId);
        if (modelDetails) {
          reset({
            [FORM_NAME.NAME]: `${modelDetails.name ?? ""}`,
            [FORM_NAME.CENTERGRAVITY]: modelDetails.center_of_gravity ?? "",
            [FORM_NAME.ISP]: modelDetails.isp ?? "",
            [FORM_NAME.LENGTH]: modelDetails.length ?? "",
            [FORM_NAME.THRUST]: modelDetails.thrust ?? "",
            [FORM_NAME.NOZZLE_EXIT]: modelDetails.nozzle_exit_diameter ?? "",
            [FORM_NAME.MASS]: modelDetails.mass ?? "",
            [FORM_NAME.MEMO]: modelDetails.memo ?? "",
          });
        }
      }
    };

    fetchModelDetails();
  }, [toggle, modelId, reset]);

  const handleSubmitForm = async (modelId, data) => {
    const inputData = {
      name: data.name.trim(),
      stage: data.stage,
      center_of_gravity: data.center_of_gravity,
      isp: data.isp,
      length: data.length,
      thrust: data.thrust,
      nozzle_exit_diameter: data.nozzle_exit_diameter,
      mass: data.mass,
      memo: data.memo,
    };

    setIsSubmit(true);
    try {
      await editEngineComponent({ id: modelId, data: inputData });

      setToggle(false);
      onSuccess();
      showAlert({
        status: true,
        message: t("new.edit_success"),
        type: "success",
      });
      if (!label) {
        navigate(`${APP_ROUTES.ENGINE_COMPONENT_LIST}`);
      }
      setIsSubmit(false);
    } catch (error) {
      showAlert({
        status: true,
        message: t("new.error_checking"),
        type: "error",
      });
      setIsSubmit(false);
    }
  };

  return (
    <>
      <div>
        {label ? (
          <div
            className="btn-link"
            role="button"
            onClick={() => {
              reset();
              setToggle(true);
            }}
          >
            {label}
          </div>
        ) : (
          <>
            <button
              onClick={() => {
                reset();
                setToggle(true);
              }}
              data-tooltip-id={`edit-${modelId}`}
              className="btn btn-blank border-0"
              data-tooltip-content={t("list.table.edit")}
              onFocus={(e) => {
                // immediately blurs after focus
                e.target.blur();
              }}
            >
              <i className="bi bi-pencil f-s-20 text-dark" />
            </button>
            <Tooltip id={`edit-${modelId}`} className="text-break-space" />
          </>
        )}
      </div>

      <Modal
        centered
        isOpen={toggle}
        toggle={() => {
          setToggle(false);
        }}
        size="xl"
        backdrop="static"
        style={{ maxWidth: "880px", width: "85%" }}
      >
        <ModalHeader toggle={() => setToggle(false)}>{t("new.title")}</ModalHeader>
        <ModalBody className="p-b-0">
          <Form className="p-x-15">
            <div className="row">
              <div className="row">
                <div className="col-md-6">
                  <FormInput
                    register={register}
                    label={t("new.name")}
                    name={FORM_NAME.NAME}
                    placeholder={t("new.name_placeholder")}
                    errors={errors?.[FORM_NAME.NAME]?.message}
                    requiredMessage={t("new.name_required")}
                    required
                    maxLength={255}
                    maxLengthMessage={t("new.name_maxlength", { field: t("new.name") })}
                    disabled
                  />
                </div>
                <div className="col-md-6">
                  <FormInput
                    register={register}
                    label={t("new.center_of_gravity")}
                    name={FORM_NAME.CENTERGRAVITY}
                    placeholder={t("new.center_of_gravity_placeholder")}
                    errors={errors?.[FORM_NAME.CENTERGRAVITY]?.message}
                    requiredMessage={t("new.center_of_gravity_required")}
                    required
                    pattern={{
                      value: PATTERN_GRAVITY_OF_CENTER,
                      message: t(`new.number_validate`),
                    }}
                    step="1"
                    maxLength={255}
                    maxLengthMessage={t("new.name_maxlength", { field: t("new.center_of_gravity") })}
                    disabled
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <FormInput
                    register={register}
                    label={t("new.isp")}
                    name={FORM_NAME.ISP}
                    placeholder={t("new.isp_placeholder")}
                    errors={errors?.[FORM_NAME.ISP]?.message}
                    requiredMessage={t("new.isp_required")}
                    required
                    pattern={{
                      value: PATTERN_NUMBER,
                      message: t(`new.number_validate`),
                    }}
                    step="1"
                    maxLength={255}
                    maxLengthMessage={t("new.name_maxlength", { field: t("new.isp") })}
                    disabled
                    unit="s"
                  />
                </div>
                <div className="col-md-6">
                  <FormInput
                    register={register}
                    label={t("new.length")}
                    name={FORM_NAME.LENGTH}
                    placeholder={t("new.length_placeholder")}
                    errors={errors?.[FORM_NAME.LENGTH]?.message}
                    requiredMessage={t("new.length_required")}
                    required
                    pattern={{
                      value: PATTERN_NUMBER,
                      message: t(`new.number_validate`),
                    }}
                    step="any"
                    maxLength={255}
                    maxLengthMessage={t("new.name_maxlength", { field: t("new.length") })}
                    disabled
                    unit="m"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <FormInput
                    register={register}
                    label={t("new.thrust")}
                    name={FORM_NAME.THRUST}
                    placeholder={t("new.thrust_placeholder")}
                    errors={errors?.[FORM_NAME.THRUST]?.message}
                    requiredMessage={t("new.thrust_required")}
                    required
                    pattern={{
                      value: PATTERN_NUMBER,
                      message: t(`new.number_validate`),
                    }}
                    step="1"
                    maxLength={255}
                    maxLengthMessage={t("new.name_maxlength", { field: t("new.thrust") })}
                    disabled
                    unit="N"
                  />
                </div>

                <div className="col-md-6">
                  <FormInput
                    register={register}
                    label={t("new.nozzle_exit_diameter")}
                    name={FORM_NAME.NOZZLE_EXIT}
                    placeholder={t("new.nozzle_exit_diameter_placeholder")}
                    errors={errors?.[FORM_NAME.NOZZLE_EXIT]?.message}
                    requiredMessage={t("new.nozzle_exit_diameter_required")}
                    required
                    maxLength={255}
                    maxLengthMessage={t("new.name_maxlength", { field: t("new.nozzle_exit_diameter") })}
                    pattern={{
                      value: PATTERN_NEGATIVE_NUMBER,
                      message: t(`new.field_number_validate`, { field: t("new.nozzle_exit_diameter") }),
                    }}
                    valueGreaterThanZero
                    disabled
                    unit="m"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <FormInput
                    register={register}
                    label={t("new.mass")}
                    name={FORM_NAME.MASS}
                    placeholder={t("new.mass_placeholder")}
                    errors={errors?.[FORM_NAME.MASS]?.message}
                    requiredMessage={t("new.mass_required")}
                    required
                    pattern={{
                      value: PATTERN_NUMBER,
                      message: t(`new.number_validate`),
                    }}
                    step="any"
                    maxLength={255}
                    maxLengthMessage={t("new.name_maxlength", { field: t("new.mass") })}
                    disabled
                    unit="kg"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <FormInput
                    register={register}
                    label={t("new.memo")}
                    name={FORM_NAME.MEMO}
                    placeholder={t("new.memo_placeholder")}
                    errors={errors?.[FORM_NAME.MEMO]?.message}
                  />
                </div>
              </div>
            </div>
          </Form>
        </ModalBody>
        <ModalFooter className="justify-content-center p-t-10 p-b-20">
          <button
            className="btn btn-sm btn-secondary m-x-10 width-by-px-120"
            onClick={() => {
              setToggle(false);
              document.body.click();
            }}
          >
            {t("new.cancel")}
          </button>
          <button
            className="btn btn-sm btn-primary m-x-10 width-by-px-120"
            disabled={isSubmit}
            onClick={handleSubmit((data) => handleSubmitForm(modelId, data))}
          >
            {t("new.register")}
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ModalEditEngineComponent;
