import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ThrustCalculationList from "src/components/ThrustCalculation/list";
import { PAGINATION_DEFAULT } from "src/constant/common";
import { useMutation } from "src/Hooks/useReactQuery";
import { bulkThrustCalculation } from "src/services/rocket-model";
import { handleScrollTo } from "src/utils/common";

const EngineDetail = ({ id, stage, getLogs }) => {
  const location = useLocation();

  const bulkThrustCalculationMutation = useMutation({
    mutationFn: ({ data }) => bulkThrustCalculation({ id, data }),
  });

  useEffect(() => {
    if (location.hash) {
      const elementId = location.hash.substring(1);
      if (elementId) {
        handleScrollTo(0, 25, elementId);
      }
    }
  }, []);

  return (
    <ThrustCalculationList
      rocketId={id}
      pageSize={PAGINATION_DEFAULT.smallPageSize}
      bulkThrustCalculationMutation={bulkThrustCalculationMutation}
      stage={stage}
      getLogs={getLogs}
    />
  );
};

export default EngineDetail;
